import Button from '@sportnet/ui/lib/Button';
import FormField from '@sportnet/ui/lib/FormField';
import FormFieldRedux from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import Message from '@sportnet/ui/lib/Message';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/lib/Modal';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, ConfigProps, Field, reduxForm, submit } from 'redux-form';
import { withTheme } from 'styled-components';
import HobbyApi from '../../api/HobbyApi';
import config from '../../config';
import { CustomThunkDispatch } from '../../configureStore';
import Loading from '../../containers/Loading';
import { SportGround } from '../../library/SportGround';
import { Team } from '../../library/Tournament';
import { ITheme } from '../../theme/theme';
import GeoCoding from '../../utilities/geoCoding';
import required from '../../utilities/required';
import __ from '../../utilities/__';
import { Actions } from '../Events/styledComponents';

interface OwnProps {
  sportGrounds: SportGround[];
  setSportGroundQuery: (i: string) => void;
  sportGroundsAreFetching: boolean;
  loadSportGrounds: () => void;
  isSubmitting: boolean;
  teams: Team[];
  homeTeam: { value: string } | null;
  awayTeam: { value: string } | null;
}

const BLANK_SPORT_GROUND = {
  name: '',
  geo: { lat: 0, lng: 0 },
  street: '',
  city: '',
  number: '',
  zip: '',
  country: '',
};

const EventForm: React.FC<OwnProps & {
  dispatch: CustomThunkDispatch;
} & ConfigProps<{}, {}> & {
    theme: ITheme;
  }> = ({
  sportGrounds,
  form,
  loadSportGrounds,
  sportGroundsAreFetching,
  dispatch,
  isSubmitting,
  teams,
  homeTeam,
  awayTeam,
}) => {
  const [newSportGroundQuery, setNewSportGroundQuery] = React.useState(
    BLANK_SPORT_GROUND,
  );
  const [sportGroundModalOpened, setSportGroundModalOpened] = React.useState(
    false,
  );
  const [geoLocationIsFetching, setGeoLocationIsFetching] = React.useState(
    false,
  );
  const [sportGroundIsSubmitting, setSportGroundIsSubmitting] = React.useState(
    false,
  );

  const toggleModal = () => {
    setSportGroundModalOpened(!sportGroundModalOpened);
  };

  const translateAddress = async (address: string) => {
    setSportGroundModalOpened(true);
    setGeoLocationIsFetching(true);
    try {
      const { results } = await GeoCoding.fromAddress(address);
      const {
        formatted_address,
        geometry: {
          location: { lat, lng },
        },
        address_components,
      } = results[0];

      const city = address_components.find(c =>
        c.types.includes('administrative_area_level_2'),
      );
      const streetNumber = address_components.find(c =>
        c.types.includes('street_number'),
      );
      const street = address_components.find(c => {
        return c.types.includes('route') || c.types.includes('locality');
      });
      const country = address_components.find(c => c.types.includes('country'));
      const zip = address_components.find(c => c.types.includes('postal_code'));

      setNewSportGroundQuery({
        name: formatted_address,
        geo: { lat, lng },
        street: street ? street.long_name : '',
        number: streetNumber ? streetNumber.long_name : '',
        city: city ? city.long_name : '',
        country: country ? country.long_name : '',
        zip: zip ? zip.long_name : '',
      });
    } catch (e) {
      // console.error(e);
      alert(__('Adresu sa nepodarilo nájsť'));
      setSportGroundModalOpened(false);
    } finally {
      setGeoLocationIsFetching(false);
    }
  };

  const createSportGround = async () => {
    const {
      street,
      number: streetNumber,
      city,
      country,
      zip,
      geo,
    } = newSportGroundQuery;
    try {
      setSportGroundIsSubmitting(true);
      const res = await HobbyApi.createSportGround(
        config.APP_SPACE,
        {},
        {
          sport: 'futbal',
          street,
          number: streetNumber,
          city,
          country,
          zip,
          geo,
        },
      );
      dispatch(
        change(form, 'sportGround', { label: res.name, value: res._id }),
      );
      setNewSportGroundQuery(BLANK_SPORT_GROUND);
      setSportGroundModalOpened(false);
      loadSportGrounds();
    } catch (e) {
      // console.error(e);
      alert(__('Uloženie sa nepodarilo.'));
    } finally {
      setSportGroundIsSubmitting(false);
    }
  };

  return (
    <form>
      <Segment
        raised
        header={
          <SegmentHeader size="xs">{__('Základné informácie')}</SegmentHeader>
        }
      >
        <Row>
          <Col xs={12} s={6}>
            <Field
              component={FormFieldRedux}
              name="startDate"
              type="dateTime"
              required
              validate={[required]}
              label={__('Dátum a čas konania')}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              component={FormFieldRedux}
              name="sportGround"
              type="theselect"
              loading={sportGroundsAreFetching}
              createNewOption={translateAddress}
              required
              validate={[required]}
              label={__('Miesto konania')}
              options={sportGrounds.map(o => ({
                label: o.name,
                value: o._id,
              }))}
              placeholder={__('Vyberte zo zoznamu alebo zadajte novú adresu')}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              component={FormFieldRedux}
              name="homeTeam"
              type="theselect"
              required
              validate={[required]}
              label={__('Domáci tím')}
              options={teams
                .map(o => ({
                  label: o.name,
                  value: o._id,
                }))
                .filter(i => !awayTeam || i.value !== awayTeam.value)}
              placeholder={__('Vyberte zo zoznamu')}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              component={FormFieldRedux}
              name="awayTeam"
              type="theselect"
              required
              validate={[required]}
              label={__('Hosťujúci tím')}
              options={teams
                .map(o => ({
                  label: o.name,
                  value: o._id,
                }))
                .filter(i => !homeTeam || i.value !== homeTeam.value)}
              placeholder={__('Vyberte zo zoznamu')}
            />
          </Col>
        </Row>
      </Segment>
      <Actions>
        <Button
          primary
          type="button"
          loading={isSubmitting}
          onClick={() => {
            dispatch(submit(form));
          }}
        >
          {__('Uložiť')}
        </Button>
      </Actions>
      <Modal
        size="xs"
        isOpen={sportGroundModalOpened}
        handleClose={toggleModal}
      >
        {geoLocationIsFetching ? (
          <Loading />
        ) : (
          <>
            <ModalContent>
              <Row>
                <Col xs={12}>
                  <FormField
                    label={__('Je toto správna adresa?')}
                    readOnly
                    value={newSportGroundQuery.name}
                  />
                </Col>
                <Col xs={12}>
                  <Message warning>
                    {__(
                      'V prípade, že adresa nie je správna, skúste vyhľadávanie spresniť (napr. zadaním mesta za názov a číslo ulice, ak ste tak už neurobili), prípadne zadať v jednoduchšom tvare.',
                    )}
                  </Message>
                </Col>
              </Row>
            </ModalContent>
            <ModalActions>
              <div>&nbsp;</div>
              <div>
                <Button onClick={toggleModal}>{__('Zrušiť')}</Button>
                &nbsp;
                <Button
                  loading={sportGroundIsSubmitting}
                  primary
                  onClick={createSportGround}
                >
                  {__('Potvrdiť')}
                </Button>
              </div>
            </ModalActions>
          </>
        )}
      </Modal>
    </form>
  );
};

export default compose<any>(
  withTheme,
  reduxForm<{}, OwnProps>({}),
  connect(),
)(EventForm);
