import { Loader } from '@sportnet/ui/lib/Loader';
import { darken, em, rem } from 'polished';
import * as React from 'react';
import { compose } from 'redux';
import styled, { css, withTheme } from 'styled-components';
import { ITheme } from '../../theme/theme';

const StyledLoader = styled(Loader)<{ loading: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 0.2s, opacity 0.2s;
  transform-origin: left top;
  ${({ loading }) => {
    if (loading) {
      return css`
        opacity: 1;
        transform: scale(1) translate(-50%, -50%);
      `;
    }
    return css`
      opacity: 0;
      transform: scale(0) translate(-50%, -50%);
    `;
  }};
`;

type ButtonProps = {
  success?: boolean;
  danger?: boolean;
  block?: boolean;
  minWidth?: number;
  disabled: boolean;
};

const Button = styled.button<ButtonProps>`
  margin: ${rem(2)};
  position: relative;
  padding: ${rem(7)} ${rem(15)};
  color: ${({ theme, success, danger, disabled }) => {
    if (disabled) {
      return '#fff';
    } else if (success) {
      return theme.color.success;
    } else if (danger) {
      return theme.color.danger;
    }
    return theme.color.primary;
  }};
  background: ${({ theme, success, danger, disabled }) => {
    if (disabled) {
      return theme.inactiveColor;
    } else if (success) {
      return '#E8F5EC';
    } else if (danger) {
      return '#F6E7E7';
    }
    return '#ebf2ff';
  }};
  border: 0;
  border-radius: ${rem(4)};
  font-size: ${em(14)};
  font-weight: 500;
  min-height: ${rem(38)};
  ${({ minWidth }) => {
    if (minWidth) {
      return css`
        min-width: ${rem(minWidth)};
      `;
    }
    return css``;
  }}
  ${({ block }) => {
    if (block) {
      return css`
        width: 100%;
        display: flex;
        justify-content: center;
      `;
    }
    return css``;
  }}
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  :focus {
    outline: none;
  }
  :hover {
    background: ${({ theme, disabled, success, danger }) => {
      if (disabled) {
        return theme.inactiveColor;
      } else if (success) {
        return darken(0.05, '#E8F5EC');
      } else if (danger) {
        return darken(0.05, '#F6E7E7');
      }
      return darken(0.05, '#ebf2ff');
    }};
  }
`;

const Content = styled.div<{ isLoading: boolean; block: boolean }>`
  transition: opacity 0.2s;
  opacity: ${({ isLoading }) => (isLoading ? 0.25 : 1)};
  display: flex;
  align-items: center;
  width: 100%;
  ${({ block }) => {
    if (block) {
      return css`
        width: 100%;
        display: flex;
        justify-content: center;
      `;
    }
    return css``;
  }}
`;

interface OwnProps {
  loading?: boolean;
  success?: boolean;
  danger?: boolean;
  block?: boolean;
  minWidth?: number;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

const CustomButton: React.FC<{ theme: ITheme } & OwnProps &
  React.ButtonHTMLAttributes<{}>> = ({
  children,
  loading,
  success,
  danger,
  block,
  minWidth,
  onClick,
  disabled,
  theme,
}) => {
  let loaderColor = theme.color.primary;
  if (disabled) {
    loaderColor = '#fff';
  } else if (danger) {
    loaderColor = theme.color.danger;
  } else if (success) {
    loaderColor = theme.color.success;
  }
  return (
    <Button
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick && !disabled) {
          onClick(e);
        }
      }}
      success={success}
      danger={danger}
      block={block}
      minWidth={minWidth}
      disabled={!!disabled}
    >
      <Content block={!!block} isLoading={!!loading}>
        {children}
      </Content>
      <StyledLoader size="l" loading={!!loading} color={loaderColor} />
    </Button>
  );
};

export default compose<React.FC<OwnProps>>(withTheme)(CustomButton);
