import {
  getListParameters,
  initialize,
  ListParameters,
  setParams,
} from 'redux-list/lib/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import {
  CustomThunkDispatch,
  ExtraArgumentType,
  RootState,
} from '../../configureStore';
import {
  CodelistItems,
  IAuthorizationResponse,
  IFriendsList,
  Writeable,
} from '../../library/App';
import normalizeEntities from '../../utilities/normalizeEntities';
import { entitiesSelector } from './selectors';

const create = actionCreatorFactory('APP');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const setAccessToken = create<string>('SET_ACCESS_TOKEN');
export const setAuthUser = create<IAuthorizationResponse>('SET_AUTH_USER');

export const initializeOrSetListParams = createAsync<
  {
    listName: string;
    params: ListParameters;
  },
  any
>('INITIALIZE_OR_SET_LIST_PARAMS', async (parameters, dispatch, getState) => {
  const reduxListParams = getListParameters(parameters.listName)(getState());
  if (Object.keys(reduxListParams).length === 0) {
    return dispatch(
      initialize({
        listName: parameters.listName,
        initialParams: parameters.params,
      }),
    );
  }
  return dispatch(
    setParams({
      listName: parameters.listName,
      parameters: parameters.params,
    }),
  );
});

export const loadCodelist = createAsync<
  {
    codelist: string;
  },
  CodelistItems
>('GET_CODELIST', async ({ codelist }, dispatch, getState, { CoreApi }) => {
  const response = await CoreApi.getCodelist(codelist);
  const { entities } = normalizeEntities('codelists', [
    { _id: codelist, items: response.codelist },
  ]);
  dispatch(replaceEntities(entities));
  return response.codelist!; // should be required
});

interface IEntity {
  [key: string]: any;
}

interface IEntities {
  [key: string]: IEntity;
}

export const updateEntitiesUtility = (
  oldEntities: IEntities,
  entitiesToUpdate: IEntities,
): IEntities => {
  const entities = entitiesToUpdate as Writeable<typeof entitiesToUpdate>;
  return Object.keys(entities).reduce(
    (acc: Partial<typeof entities>, entityName: keyof typeof entities) => {
      if (entityName in oldEntities) {
        acc[entityName] = Object.keys(entities[entityName]).reduce(
          (innerAcc: { [key: string]: any }, entityId: string) => {
            if (entityId in oldEntities[entityName]) {
              innerAcc[entityId] = {
                ...oldEntities[entityName][entityId],
                ...entities[entityName][entityId],
              };
            } else {
              innerAcc[entityId] = entities[entityName][entityId];
            }
            return innerAcc;
          },
          {},
        );
      } else {
        acc[entityName] = entities[entityName];
      }
      return acc;
    },
    {},
  );
};

export const updateEntities = (
  entitiesToUpdate: Partial<RootState['entities']>,
) => {
  const entities = entitiesToUpdate as Writeable<typeof entitiesToUpdate>;
  return (dispatch: CustomThunkDispatch, getState: () => RootState) => {
    const oldEntities = entitiesSelector(getState());
    const nextEntities = updateEntitiesUtility(oldEntities, entities);
    return dispatch({
      type: 'UPDATE_ENTITIES',
      payload: {
        result: {
          entities: nextEntities,
        },
      },
    });
  };
};

export const replaceEntities = (entities: Partial<RootState['entities']>) => {
  return {
    type: 'REPLACE_ENTITIES',
    payload: {
      result: {
        entities,
      },
    },
  };
};

export const getFriendsList = createAsync<void, IFriendsList>(
  'GET_FRIENDS_LIST',
  async (parameters, dispatch, getState, { HobbyApi }) => {
    const response = await HobbyApi.getMeFriendsList();
    return response; // should be required
  },
);
