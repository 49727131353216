import { Theme } from '@sportnet/ui/lib/Themes/styled-components';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { getProp } from 'sportnet-utilities';
import styled, { css, withTheme } from 'styled-components';
import { ProtocolEvent, Team } from '../../library/Event';
import __ from '../../utilities/__';
import Icons from '../Icons';

const Wrapper = styled.div`
  text-align: center;
  color: #333;
`;

const Events = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &:after {
    content: '';
    height: calc(100% - ${rem(70)});
    width: ${rem(1)};
    background: ${({ theme }) => theme.separatorColor};
    position: absolute;
    top: calc(0% + ${rem(35)});
    left: calc(0% + ${rem(32)});
    ${mb('s')} {
      left: calc(50% - ${rem(1)});
    }
  }
`;
const EventItem = styled.div<{ align: string; last?: boolean }>`
  display: flex;
  align-items: center;
  ${({ last }) => {
    if (last) {
      return css`
        ${Minute} {
          &:after {
            height: 0;
          }
        }
      `;
    }
    return css``;
  }}
  ${({ align }) => {
    if (align === 'left') {
      return css`
        flex-direction: row-reverse;
        justify-content: flex-end;
        ${mb('s')} {
          flex-direction: row;
        }
      `;
    }
    return css``;
  }}
`;
const EventItemPart = styled.div`
  width: calc(100% - ${rem(32.5)});
  padding: ${rem(5)} 0;
  ${mb('s')} {
    width: calc(50% - ${rem(32.5)});
  }
`;
const Spacer = styled(EventItemPart)`
  display: none;
  ${mb('s')} {
    display: block;
  }
`;
const Minute = styled.div`
  text-align: center;
  padding: ${rem(10)};
  border: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  border-width: 0;
  background: white;
  font-weight: 500;
  color: #333;
  border-radius: 100%;
  height: ${rem(35)};
  width: ${rem(35)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: ${rem(10)} ${rem(15)};
  font-size: ${rem(12)};
  z-index: 1;
`;
const MinuteMask = styled.div<{ align: string }>`
  /* position: absolute;
  left: ${({ align }) => (align === 'left' ? rem(-1) : rem(17.5))};
  width: ${rem(35 / 2)};
  height: 120%;
  background: white;
  transform: translate(-${rem(1)}, 0); */
`;
const EventItemInfo = styled(EventItemPart)``;
const EventIcon = styled.div<{ align: string }>`
  display: flex;
  align-items: center;
  ${({ align }) => {
    if (align === 'left') {
      return css`
        flex-direction: row;
        justify-content: flex-start;
        ${Player} {
          align-items: flex-start;
          text-align: left;
        }
        ${mb('s')} {
          justify-content: flex-start;
          flex-direction: row-reverse;
          ${Player} {
            text-align: right;
          }
        }
      `;
    }
    return css``;
  }}
`;
const Player = styled.div`
  padding: 0 ${rem(15)};
  text-align: left;
`;
const PlayerName = styled.div`
  font-size: ${rem(13)};
  font-weight: 500;
`;
const EventType = styled.div<{ mobile?: boolean }>`
  font-size: ${rem(12)};
  color: ${({ mobile }) => (mobile ? 'inherit' : '#aaa')};
  ${({ mobile }) => `${mb('s')} {
    display: ${mobile ? 'none' : 'block'};
  }`};
`;
const IconWrapper = styled.div`
  width: ${rem(20)};
  display: flex;
`;

interface OwnProps {
  teams: Team[];
  protocol?: { events: ProtocolEvent[] };
  timer?: {
    [key: string]: {
      start: {
        date: string;
        seconds?: number;
      };
      end?: {
        date: string;
        seconds?: number;
      };
    };
  };
  eventTypes: Array<{
    _id: string;
    label: string;
    eventType: string;
  }>;
}

type Props = OwnProps & Theme;

class TimeLine extends React.PureComponent<Props> {
  renderEventDetail = (event: any, currentTeam: Team, align: string) => {
    return (
      <EventItemInfo>
        <EventIcon align={align}>
          <IconWrapper>
            <Icons size={20} name={event.eventType} />
          </IconWrapper>
          <Player>
            {!!event.player && <PlayerName>{event.player.name}</PlayerName>}
            {event.eventType === 'timeout' && (
              <PlayerName>{__('Timeout')}</PlayerName>
            )}
            <EventType mobile>{currentTeam.name}</EventType>
            {!!event.replacement && (
              <EventType>{`Striedajúci hráč: ${event.replacement.name}`}</EventType>
            )}
            {!!event.assist && (
              <EventType>{`Asistencia: ${event.assist.name}`}</EventType>
            )}
            {!!event.type &&
            getProp(
              this.props.eventTypes.find(i => i._id === event.type) || {},
              ['label'],
            ) ? (
              <EventType>
                {this.props.eventTypes.find(i => i._id === event.type)!.label}
              </EventType>
            ) : null}
            {!!event.reason && <EventType>{event.reason}</EventType>}
          </Player>
        </EventIcon>
      </EventItemInfo>
    );
  };

  getTeam = (teamSide: string) =>
    this.props.teams.find(
      i => getProp(i, ['additionalProperties', 'homeaway'], '') === teamSide,
    );

  getTeamsIds = () => {
    const homeTeam = this.getTeam('home');
    const homeTeamId = homeTeam ? homeTeam._id : this.props.teams[0]._id;
    const awayTeam = this.getTeam('away');
    const awayTeamId = awayTeam ? awayTeam._id : this.props.teams[1]._id;

    return { homeTeamId, awayTeamId };
  };

  getEventTime = (event: any) => {
    const minutes = `${Math.floor(event.eventTime / 60)}`;
    const seconds = String(event.eventTime % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  renderEventItem = (teams: Team[], event: any) => {
    const currentTeam = teams.find(i => i._id === event.team)!;
    const { homeTeamId } = this.getTeamsIds();

    return (
      <EventItem align={event.team === homeTeamId ? 'left' : 'right'}>
        {event.team === homeTeamId ? (
          this.renderEventDetail(event, currentTeam, 'left')
        ) : (
          <Spacer />
        )}
        <Minute>
          <MinuteMask align={event.team === homeTeamId ? 'left' : 'right'} />
          <div style={{ position: 'relative' }}>{this.getEventTime(event)}</div>
        </Minute>
        {event.team === homeTeamId ? (
          <Spacer />
        ) : (
          this.renderEventDetail(event, currentTeam, 'right')
        )}
      </EventItem>
    );
  };
  render() {
    const { teams, protocol } = this.props;

    let events = (protocol || { events: [] }).events
      .filter(event => event.eventTime && event.type !== 'goal_shootout')
      .map(event => {
        const timeParts = (event.eventTime || '').split(':');
        const seconds = Number(timeParts[0]) * 60 + Number(timeParts[1]);
        return {
          ...event,
          eventTime: seconds,
        };
      });

    events = events.sort((a, b) => {
      if (a.eventTime > b.eventTime) {
        return 1;
      } else if (a.eventTime < b.eventTime) {
        return -1;
      }
      return 0;
    });

    return (
      <Wrapper>
        <Events>
          {events.map((event, idx) => (
            <div key={`event_${idx}`}>{this.renderEventItem(teams, event)}</div>
          ))}
        </Events>
      </Wrapper>
    );
  }
}

export default withTheme(TimeLine);
