import { ContentLoader } from '@sportnet/ui/lib/Loader';
import Message from '@sportnet/ui/lib/Message';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { format } from 'date-fns';
import { em, rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import styled from 'styled-components';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import HobbyApi from '../../api/HobbyApi';
import AdminLayoutContext from '../../components/AdminLayout/context';
import Icons, { IIconName } from '../../components/Icons';
import { Content } from '../../components/Layout';
import config from '../../config';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import { authUserProfileSelector } from '../../containers/App/selectors';
import { loadTournamentInvitationById } from '../../containers/Tournaments/actions';
import { tournamentByIdSelector } from '../../containers/Tournaments/selectors';
import { ITheme } from '../../theme/theme';
import useWindowSize from '../../utilities/resizeHook';
import __ from '../../utilities/__';
import TournamentTeams from '../TournamentDetail/tournamentTeams';
import TournamentInvitationStatusChange from './changeStatus';

const Text = styled.div`
  font-size: ${em(13)};
  line-height: ${em(22)};
  text-align: justify;
  white-space: pre-wrap;
`;

const InfoBlocks = styled.div`
  flex-wrap: wrap;
  display: flex;
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 600px) {
    width: 50%;
  }
`;
const IconWrapper = styled.div`
  padding: ${rem(10)};
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${em(14)};
  justify-content: center;
  margin-top: ${rem(-3)};
`;
const InfoWrapperHeader = styled.div`
  color: ${({ theme }) => theme.color.fadedText};
  font-size: ${em(14)};
`;

const mapDispatchToProps = {
  loadTournament: thunkToAction(loadTournamentInvitationById.action),
};

type Props = RouteComponentProps<
  { tournamentId: string; invitationId: string },
  {}
>;

const mapStateToProps = (state: RootState, props: Props) => ({
  tournament: tournamentByIdSelector(props.params.tournamentId)(state),
  authUser: authUserProfileSelector(state),
});

const TournamentInvitation: React.FC<
  Props & {
    dispatch: CustomThunkDispatch;
  } & typeof mapDispatchToProps &
    ReturnType<typeof mapStateToProps> & { theme: ITheme }
> = ({
  tournament,
  params: { tournamentId, invitationId },
  loadTournament,
  theme,
  router: { push },
  authUser,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);

  const [isSubmittingTeam, setIsSubmittingTeam] = React.useState(false);

  const [createdTeam] = React.useState<null | {
    _id: null | string;
    name: string;
  }>(null);

  const [selectedTeamId] = React.useState<string | null>(null);

  const [teamIdProcessing, setTeamIdProcessing] = React.useState<string | null>(
    null,
  );

  const [invitationModalOpened, setInvitationModalOpened] = React.useState(
    false,
  );

  const resizeHook = useWindowSize();

  const [mobileLayout, setMobileLayout] = React.useState(
    window.innerWidth < 600,
  );

  React.useEffect(() => {
    if (resizeHook.innerWidth < 600) {
      setMobileLayout(true);
    } else {
      setMobileLayout(false);
    }
  }, [resizeHook.innerWidth]);

  useAsyncData(async () => {
    loadTournamentData();
  }, [tournamentId, invitationId]);

  const loadTournamentData = async () => {
    setIsFetching(true);
    try {
      await loadTournament({ tournamentId, invitationId });
    } catch (e) {
      push('/admin/tournaments/me');
    } finally {
      setIsFetching(false);
    }
  };

  const changeTeamAssignment = async (id: string) => {
    try {
      setTeamIdProcessing(id);
      const body: {
        status: 'ACCEPTED' | 'UNASSIGNED';
        team?: { _id?: string; name: string };
        note?: string;
      } = { status: selectedTeamId ? 'UNASSIGNED' : 'ACCEPTED' };
      const selectedTeam = (tournament.teams || []).find(t => t._id === id);
      if (selectedTeam) {
        body.team = {
          _id: selectedTeam._id,
          name: selectedTeam.name,
        };
      }
      await HobbyApi.updateTournamentInvitationStatus(
        config.APP_SPACE,
        tournament._id,
        invitationId,
        {},
        body,
      );
      push(`/admin/tournament/${tournamentId}`);
    } catch (e) {
      if (e.details.name === 'TOURNAMENT_GENDER_RESTRICTION_NOT_MET') {
        alert(__('Nespĺňate obmedzenie pre pohlavie.'));
      } else {
        alert(__('Vyskytla sa neočakávaná chyba'));
      }
    } finally {
      setTeamIdProcessing(null);
    }
  };

  const createTournamentTeam = async (data: { name: string }) => {
    setIsSubmittingTeam(true);
    try {
      await HobbyApi.createTournamentTeamByInvitation(
        config.APP_SPACE,
        tournament._id,
        invitationId,
        {},
        data,
      );
      loadTournamentData();
    } catch (e) {
      alert(__('Tím sa nepodarilo uložiť'));
    } finally {
      setIsSubmittingTeam(false);
    }
  };

  const createInfoBlock = (
    iconName: IIconName,
    value: string,
    header?: string,
  ) => (
    <InfoBlock>
      <IconWrapper>
        <Icons size={20} name={iconName} color="#ccc" />
      </IconWrapper>
      <InfoWrapper>
        {header && <InfoWrapperHeader>{header}</InfoWrapperHeader>}
        <div>{value}</div>
      </InfoWrapper>
    </InfoBlock>
  );

  const { setHeaderComponents, headerComponents } = React.useContext(
    AdminLayoutContext,
  );
  const componentHeader = __('Turnaje');
  const componentSubHeader = __('Pozvánka do turnaja');

  if (
    headerComponents.header !== componentHeader ||
    headerComponents.subHeader !== componentSubHeader
  ) {
    setHeaderComponents(componentHeader, componentSubHeader);
  }

  if (!tournament || isFetching || !authUser) {
    return <ContentLoader theme={theme} />;
  }

  return (
    <Content>
      {tournament.createdBy && (
        <Message primary>
          {tournament.createdBy.name} {tournament.createdBy.surname}{' '}
          {__('vás pozýva do tohto turnaja')}
        </Message>
      )}
      <Segment
        raised
        header={
          <SegmentHeader withSeparator size="xs">
            {__('Popis')}
          </SegmentHeader>
        }
      >
        <Text>{tournament.description}</Text>
      </Segment>
      <Segment
        raised
        header={
          <SegmentHeader withSeparator size="xs">
            {__('Informácie')}
          </SegmentHeader>
        }
      >
        <InfoBlocks>
          {createInfoBlock(
            'calendar',
            `${format(new Date(tournament.dateFrom), 'DD.MM.YYYY')} - ${format(
              new Date(tournament.dateTo),
              'DD.MM.YYYY',
            )}`,
            __('Predpokladané trvanie'),
          )}
          {createInfoBlock(
            'place',
            tournament.sportGround.name,
            __('Primárne miesto konania'),
          )}
        </InfoBlocks>
      </Segment>
      <TournamentTeams
        createdTeam={createdTeam}
        onTeamCreationSubmit={createTournamentTeam}
        teams={tournament.teams || []}
        selectedTeamId={selectedTeamId}
        setSelectedTeamId={changeTeamAssignment}
        isCreator={false}
        isSubmitting={isSubmittingTeam}
        teamIdProcessing={teamIdProcessing}
        mobileLayout={mobileLayout}
        toggleInvitationModal={() => {
          setInvitationModalOpened(!invitationModalOpened);
        }}
      />
      <TournamentInvitationStatusChange
        tournament={tournament}
        reloadTournament={() => {
          push(`/admin/tournament/${tournament._id}`);
        }}
        invitationId={invitationId}
      />
    </Content>
  );
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TournamentInvitation);
