import * as React from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import AdminLayoutContext from '../../components/AdminLayout/context';
import { Content } from '../../components/Layout';
import Tabber from '../../components/Tabber';
import __ from '../../utilities/__';

const Events: React.FC<WithRouterProps> = ({
  location: { pathname },
  children,
}) => {
  const {
    setHeaderComponents,
    headerComponents,
    setMessageComponents,
    messageComponents,
  } = React.useContext(AdminLayoutContext);
  const componentHeader = __('Zápasy');
  const componentSubHeader = __('Zoznam zápasov');

  const messageTitle = __('Čo je to zápas?');
  const messageContent = __(
    'Zápas je jednorazová aktivita kedy si môžete zaznamenať priebeh stretnutia, góly, asistencie, karty a výsledok zápasu ostane prístupný pre každého, kto sa zápasu zúčastnil. Nezískate ale dlhodobú štatistiku napríklad o strelených góloch, či počte víťažstiev - tá je dostupná v prípade, že sa rozhodnete vytvoriť turnaj.',
  );

  if (
    headerComponents.header !== componentHeader ||
    headerComponents.subHeader !== componentSubHeader
  ) {
    setHeaderComponents(componentHeader, componentSubHeader);
  }
  if (
    messageComponents.title !== messageTitle ||
    messageComponents.content !== messageContent
  ) {
    setMessageComponents(messageTitle, messageContent);
  }

  return (
    <div>
      <Tabber
        pathname={pathname}
        items={[
          { label: __('Účastník'), url: '/admin/events/me' },
          { label: __('Organizátor'), url: '/admin/events/admin' },
        ]}
      />
      <Content>{children}</Content>
    </div>
  );
};

export default withRouter(Events);
