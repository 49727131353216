import { em } from 'polished';
import styled, { css } from 'styled-components';

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${em(10)};
`;

export const YearPicker = styled.div`
  display: flex;
  align-items: center;
  width: ${em(120)};
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CalendarItem = styled.div<{ active?: boolean; disabled: boolean }>`
  flex-basis: 25%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${em(15)};
  border-radius: ${em(10)};
  background-color: ${({ active, theme, disabled }) => {
    if (disabled) {
      return `#fff`;
    } else if (active) {
      return `${theme.color.primary} !important`;
    }
    return '#fff';
  }};
  ${({ active, disabled, theme }) => {
    if (disabled) {
      return css`
        color: ${theme.color.fadedText};
        & > ${EventsCount} {
          color: ${theme.color.fadedText};
        }
      `;
    } else if (active) {
      return css`
        color: #fff;
        & > ${EventsCount} {
          color: #fff;
        }
      `;
    }
    return css``;
  }}
  transition: 0.1s linear all;
  ${({ disabled }) => {
    if (!disabled) {
      return css`
        cursor: pointer;
        :hover {
          background-color: #eee;
        }
      `;
    }
    return css`
      cursor: not-allowed;
    `;
  }}
`;

export const Month = styled.div``;

export const EventsCount = styled.div`
  color: #aaaaaa;
  font-size: ${em(11)};
`;

export const Arrow = styled.div<{ right?: boolean; left?: boolean }>`
  ${({ right, left }) => {
    if (left) {
      return css`
        transform: rotate(90deg);
      `;
    } else if (right) {
      return css`
        transform: rotate(-90deg);
      `;
    }
    return css``;
  }}
  width: ${em(24)};
  cursor: pointer;
`;
