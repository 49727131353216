import {
  getTheme as getDefaultTheme,
  Theme,
} from '@sportnet/ui/lib/Themes/baseTheme';

const sizes = {
  xl: 1100,
  l: 992,
  m: 768,
  s: 480,
  xs: 320,
};

const customTheme = {
  backgroundColor: 'rgb(247, 247, 247)',
  secondaryColor: '#05CAD1',
  sizes,
  app: {
    maxWidth: 1200,
    padding: 15,
    bgColor: 'rgb(247, 247, 247)',
  },
};

export interface ITheme extends Theme {
  backgroundColor: string;
  secondaryColor: string;
  app: {
    maxWidth: number;
    padding: number;
    bgColor: string;
    [key: string]: any;
  };
}

type RecursivePartial<T> = { [P in keyof T]?: RecursivePartial<T[P]> };

export const getTheme = (override?: RecursivePartial<Theme>): ITheme => {
  return {
    ...getDefaultTheme(override || {}),
    ...customTheme,
  };
};

export const theme = getTheme({
  color: {
    // primary: '#3F56A1',
  },
});
export default theme;
