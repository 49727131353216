import { format } from 'date-fns';
import connecToQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getListTotal, isCommiting } from 'redux-list';
import { useAsyncData } from 'ssr-service';
import { withTheme } from 'styled-components';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import BasicTable from '../../../components/BasicTable';
import NoItemsFround from '../../../components/NoItemsFound';
import config from '../../../config';
import { RootState } from '../../../configureStore';
import { initializeOrSetListParams } from '../../../containers/App/actions';
import Loading from '../../../containers/Loading';
import { loadMeTournamentsList } from '../../../containers/Tournaments/actions';
import { meTournamentsSelector } from '../../../containers/Tournaments/selectors';
import { Tournament } from '../../../library/Tournament';
import { ITheme } from '../../../theme/theme';
import __ from '../../../utilities/__';
import { Dot, DotLabel } from '../../Events/styledComponents';

const mapDispatchToProps = {
  loadTournaments: thunkToAction(loadMeTournamentsList),
  initializeList: thunkToAction(initializeOrSetListParams.action),
};

const mapStateToProps = (state: RootState) => ({
  tournaments: meTournamentsSelector(config.ME_TOURNAMENTS_LIST_NAME)(state),
  isFetching: isCommiting(config.ME_TOURNAMENTS_LIST_NAME)(state),
  total: getListTotal(config.ME_TOURNAMENTS_LIST_NAME)(state),
});

const MeEvents: React.FC<
  typeof mapDispatchToProps &
    QueryHocInterface &
    ReturnType<typeof mapStateToProps> & {
      theme: ITheme;
    } & RouteComponentProps<{}, {}>
> = ({
  theme,
  loadTournaments,
  initializeList,
  query,
  tournaments,
  isFetching,
  total,
  setParameter,
  router: { push },
}) => {
  useAsyncData(async () => {
    await initializeList({
      listName: config.ME_TOURNAMENTS_LIST_NAME,
      params: query,
    });
    await loadTournaments();
  }, [query]);

  const getStatus = (i: Tournament) => {
    let label = '';
    let color = '';
    if (i.played) {
      label = __('Prebieha');
      color = theme.color.success;
    } else if (i.invited) {
      label = __('Nová pozvánka');
      color = theme.color.warning;
    }
    return (
      <DotLabel>
        <Dot color={color} />
        <div style={{ fontWeight: 200, color }}>{label}</div>
      </DotLabel>
    );
  };

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <>
          {tournaments.length > 0 ? (
            <BasicTable
              rowKey="_id"
              rows={tournaments}
              onClickRow={(i: Tournament) => {
                push(`/admin/tournament/${i._id}`);
              }}
              renderRow={i => [
                getStatus(i),
                i.name,
                `${format(new Date(i.dateFrom), 'DD.MM.YYYY')} - ${format(
                  new Date(i.dateTo),
                  'DD.MM.YYYY',
                )}`,
              ]}
              columns={[
                { header: __('Stav') },
                { header: __('Názov') },
                { header: __('Predpokladané trvanie') },
              ]}
              colsCount={3}
              withPaginator
              offset={Number(query.offset)}
              limit={config.DEFAULT_LIST_LIMIT}
              total={total || 0}
              onChangeOffset={newOffset => {
                setParameter({ offset: newOffset });
              }}
            />
          ) : (
            <NoItemsFround label={__('Nenašli sa žiadne turnaje')} />
          )}
        </>
      )}
    </>
  );
};

export default compose(
  connecToQueryHoc({
    parameters: { offset: { type: QueryHocTypes.Number, defaultValue: 0 } },
  }),
  withRouter,
  withTheme,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(MeEvents);
