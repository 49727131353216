import Button from '@sportnet/ui/lib/Button';
import FormFieldRedux from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/lib/Modal';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  change,
  ConfigProps,
  Field,
  getFormMeta,
  getFormSyncErrors,
  reduxForm,
  submit,
} from 'redux-form';
import styled, { withTheme } from 'styled-components';
import HobbyApi from '../../api/HobbyApi';
import config from '../../config';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import { SportGround } from '../../library/SportGround';
import { ITheme } from '../../theme/theme';
import required from '../../utilities/required';
import __ from '../../utilities/__';
import SportGroundCreationForm from '../Event/sportGroundForm';

const SPORT_GROUND_CREATION_FORM_NAME = 'SPORT_GROUND_CREATION_FORM';

interface OwnProps {
  sportGrounds: SportGround[];
  setSportGroundQuery: (i: string) => void;
  sportGroundsAreFetching: boolean;
  loadSportGrounds: () => void;
  isPublic: boolean;
  isSubmitting: boolean;
}

const CreationDiv = styled.div<{ omitOffset: boolean }>`
  margin-top: ${({ omitOffset }) => (omitOffset ? 0 : `-${rem(15)}`)};
  font-size: ${rem(12)};
  text-decoration: underline;
  color: ${({ theme }) => theme.color.primary};
  margin-bottom: ${rem(10)};
  cursor: pointer;
`;

const LEVELS = [__('Výborná úroveň'), __('Stredná úroveň'), __('Slabá úroveň')];

const mapStateToProps = (state: RootState, props: { form: string }) => {
  return {
    meta: getFormMeta(props.form)(state),
    errors: getFormSyncErrors(props.form)(state),
  };
};

const TournamentForm: React.FC<OwnProps & {
  dispatch: CustomThunkDispatch;
} & ConfigProps<{}, {}> & {
    theme: ITheme;
  } & ReturnType<typeof mapStateToProps>> = ({
  setSportGroundQuery,
  sportGrounds,
  form,
  meta,
  errors,
  loadSportGrounds,
  sportGroundsAreFetching,
  dispatch,
  isPublic,
}) => {
  const [sportGroundIsSubmitting, setSportGroundIsSubmitting] = React.useState(
    false,
  );
  const [
    sportGroundCreationModalOpened,
    setSportGroundCreationModalOpened,
  ] = React.useState(false);

  const toggleSportGroundCreation = () => {
    setSportGroundCreationModalOpened(!sportGroundCreationModalOpened);
  };

  const createSportGround = async (values: {
    address: {
      street: string;
      number: string;
      city: string;
      country: string;
      zip: string;
      geo: { lat: number; lng: number };
    };
  }) => {
    const {
      street,
      number: streetNumber,
      city,
      country,
      zip,
      geo,
    } = values.address;
    try {
      setSportGroundIsSubmitting(true);
      const res = await HobbyApi.createSportGround(
        config.APP_SPACE,
        {},
        {
          sport: 'futbal',
          street,
          number: streetNumber,
          city,
          country,
          zip,
          geo,
        },
      );
      dispatch(
        change(form, 'sportGround', { label: res.name, value: res._id }),
      );
      setSportGroundCreationModalOpened(false);
      loadSportGrounds();
    } catch (e) {
      alert(__('Uloženie sa nepodarilo.'));
    } finally {
      setSportGroundIsSubmitting(false);
    }
  };

  return (
    <form>
      <Segment
        raised
        header={
          <SegmentHeader size="xs">{__('Základné informácie')}</SegmentHeader>
        }
      >
        <Row>
          <Col xs={12}>
            <Field
              component={FormFieldRedux}
              name="name"
              required
              validate={[required]}
              label={__('Názov turnaja')}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              component={FormFieldRedux}
              name="dateFrom"
              type="date"
              required
              validate={[required]}
              label={__('Predpokladaný začiatok')}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              component={FormFieldRedux}
              name="dateTo"
              type="date"
              required
              validate={[required]}
              label={__('Predpokladaný koniec')}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              component={FormFieldRedux}
              name="sportGround"
              type="theselect"
              loading={sportGroundsAreFetching}
              required
              validate={[required]}
              label={__('Primárne miesto konania')}
              options={sportGrounds.map(o => ({
                label: o.name,
                value: o._id,
              }))}
            />
            <CreationDiv
              onClick={toggleSportGroundCreation}
              omitOffset={
                (errors as any).sportGround && (meta as any).sportGround
              }
            >
              {__('Vytvoriť nové miesto konania')}
            </CreationDiv>
            <Modal
              isOpen={sportGroundCreationModalOpened}
              handleClose={toggleSportGroundCreation}
            >
              <ModalContent>
                <SportGroundCreationForm
                  form={SPORT_GROUND_CREATION_FORM_NAME}
                  onSubmit={createSportGround}
                />
              </ModalContent>
              <ModalActions>
                <div>&nbsp;</div>
                <div>
                  <Button onClick={toggleSportGroundCreation}>
                    {__('Zrušiť')}
                  </Button>
                  &nbsp;
                  <Button
                    loading={sportGroundIsSubmitting}
                    primary
                    onClick={() => {
                      dispatch(submit(SPORT_GROUND_CREATION_FORM_NAME));
                    }}
                  >
                    {__('Potvrdiť')}
                  </Button>
                </div>
              </ModalActions>
            </Modal>
          </Col>
          <Col xs={12} s={6}>
            <Field
              component={FormFieldRedux}
              name="level"
              type="theselect"
              required
              validate={[required]}
              label={__('Úroveň skúseností hráčov')}
              // placeholder={__('Vyberte zo zoznamu')}
              options={LEVELS.map(o => ({
                label: o,
                value: o,
              }))}
            />
          </Col>
        </Row>
      </Segment>
      <Segment
        raised
        header={<SegmentHeader size="xs">{__('Obmedzenia')}</SegmentHeader>}
      >
        <Row>
          <Col xs={12} s={6}>
            <Field
              component={FormFieldRedux}
              name="rules.gender"
              type="theselect"
              label={__('Pohlavie')}
              options={[
                { label: __('Muži'), value: 'M' },
                { label: __('Ženy'), value: 'F' },
              ]}
              placeholder={__('Bez obmedzenia')}
            />
          </Col>
        </Row>
      </Segment>
      <Segment
        raised
        header={<SegmentHeader size="xs">{__('Prihlasovanie')}</SegmentHeader>}
      >
        <Row>
          <Col xs={12}>
            <Field
              component={FormFieldRedux}
              name="public"
              type="checkbox"
              label={__('Verejný turnaj')}
            />
          </Col>
        </Row>
        {isPublic && (
          <Row>
            <Col xs={12}>
              <Field
                component={FormFieldRedux}
                name="registrationDueDate"
                type="dateTime"
                label={__('Koniec prihlasovania')}
              />
            </Col>
          </Row>
        )}
      </Segment>
    </form>
  );
};

export default compose<any>(
  withTheme,
  reduxForm<{}, OwnProps>({}),
  connect(mapStateToProps),
)(TournamentForm);
