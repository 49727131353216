import Button from '@sportnet/ui/lib/Button';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import Widgets from 'content/lib/view';
import { format } from 'date-fns';
import { em, rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import styled from 'styled-components';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import HobbyApi from '../../api/HobbyApi';
import config from '../../config';
import { loadEventById } from '../../containers/Events/actions';
import { Event } from '../../library/Event';
import __ from '../../utilities/__';
import { MOBILE_MENU_BREAKPOINT } from '../AdminLayout/styledComponents';
import CustomButton from '../CustomButton';
import { createInfoBlock, InfoBlocks } from '../InfoBlock';
import TimeLine from './TimeLine';

const Text = styled.div`
  font-size: ${em(13)};
  line-height: ${em(22)};
  text-align: justify;
  white-space: pre-wrap;
`;

const ScoreBoard = styled.div`
  border-radius: ${rem(5)};
  margin-bottom: ${rem(10)};
  box-shadow: 0 ${rem(1)} ${rem(5)} 0 rgba(0, 0, 0, 0.1);
`;

const ScoreBoardTeam = styled.div`
  padding: 0 ${rem(10)};
  font-size: ${rem(15)};
  font-weight: 200;
`;

const ScoreBoardGoals = styled.div`
  width: ${rem(40)};
  height: ${rem(40)};
  text-align: center;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScoreBoardResult = styled.div<{ reverse?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${rem(5)};
  width: 100%;
  justify-content: space-between;
  background: ${({ reverse, theme }) =>
    reverse ? '#fff' : theme.color.primary};
  color: ${({ reverse, theme }) => (reverse ? theme.color.primary : '#fff')};
  @media screen and (min-width: ${MOBILE_MENU_BREAKPOINT}px) {
    width: 50%;
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
    justify-content: flex-end;
  }
`;

const ScoreBoardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${em(18)};
  color: #fff;
  @media screen and (max-width: ${MOBILE_MENU_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const ActionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;
const Action = styled.div``;

interface OwnProps {
  event: Event;
  deleteEvent: () => void;
  isDeleting: boolean;
  isPlayer?: boolean;
  isCreator?: boolean;
}

const mapDispatchToProps = {
  getEvent: thunkToAction(loadEventById.action),
};

const EventHeader: React.FC<OwnProps &
  RouteComponentProps<{}, {}> &
  typeof mapDispatchToProps> = ({
  event,
  isPlayer,
  isCreator,
  getEvent,
  router: { push },
  deleteEvent,
  isDeleting,
}) => {
  const [isRemovingPlayer, setIsRemovingPlayer] = React.useState<string | null>(
    null,
  );

  const freeCapacity = event.capacity - (event.players || []).length;

  const getGoalsByTeams = () => {
    const teams = event.teams || [];
    let homeTeamGoals = 0;
    let awayTeamGoals = 0;
    if (event.protocol) {
      homeTeamGoals = (event.protocol.events || []).reduce((acc, e) => {
        if (
          (teams[0] &&
            e.team === teams[0]._id &&
            e.eventType === 'goal' &&
            e.phase !== 'shootout' &&
            e.type !== 'dropped') ||
          (teams[1] &&
            e.team === teams[1]._id &&
            e.eventType === 'goal' &&
            e.type === 'dropped')
        ) {
          return acc + 1;
        }
        return acc;
      }, homeTeamGoals);
      awayTeamGoals = (event.protocol.events || []).reduce((acc, e) => {
        if (
          (teams[1] &&
            e.team === teams[1]._id &&
            e.eventType === 'goal' &&
            e.phase !== 'shootout' &&
            e.type !== 'dropped') ||
          (teams[0] &&
            e.team === teams[0]._id &&
            e.eventType === 'goal' &&
            e.type === 'dropped')
        ) {
          return acc + 1;
        }
        return acc;
      }, awayTeamGoals);
    }
    return { homeTeamGoals, awayTeamGoals };
  };

  const getGenderLabel = (gender?: string) => {
    if (!gender) {
      return __('Bez obmedzenia');
    } else if (gender === 'F') {
      return __('Ženy');
    } else if (gender === 'M') {
      return __('Muži');
    }
    return gender;
  };

  const removePlayer = async (userId: string) => {
    if (window.confirm(__('Skutočne si prajete odstrániť hráča?'))) {
      setIsRemovingPlayer(userId);
      try {
        await HobbyApi.removeEventPlayer(config.APP_SPACE, event._id, userId);
        await getEvent({ eventId: event._id });
      } catch (e) {
        alert(__('Hráča nebolo možné odstrániť'));
      } finally {
        setIsRemovingPlayer(null);
      }
    }
  };

  const { homeTeamGoals: htGoals, awayTeamGoals: atGoals } = getGoalsByTeams();

  return (
    <>
      {!event.closed && (
        <Segment
          raised
          header={
            <SegmentHeader withSeparator size="s">
              {__('Popis')}
            </SegmentHeader>
          }
        >
          {typeof event.description === 'string' ? (
            <Text>{event.description}</Text>
          ) : (
            <Widgets items={((event.description || {}) as any).widgets || []} />
          )}
        </Segment>
      )}
      {event.closed && (event.teams || []).length > 0 && (
        <ScoreBoard>
          <ScoreBoardContent>
            <ScoreBoardResult>
              <ScoreBoardTeam>{(event.teams || [])[0].name}</ScoreBoardTeam>
              <ScoreBoardGoals>{htGoals}</ScoreBoardGoals>
            </ScoreBoardResult>
            <div />
            <ScoreBoardResult reverse>
              <ScoreBoardTeam>{(event.teams || [])[1].name}</ScoreBoardTeam>
              <ScoreBoardGoals>{atGoals}</ScoreBoardGoals>
            </ScoreBoardResult>
          </ScoreBoardContent>
        </ScoreBoard>
      )}
      <Segment
        raised
        header={
          <SegmentHeader withSeparator size="s">
            <ActionHeader>
              <Header>{__('Informácie')}</Header>
              <Action>
                {!event.closed && isCreator && (
                  <>
                    <Button
                      warning
                      onClick={() => {
                        push(`/admin/event/${event._id}/edit`);
                      }}
                    >
                      {__('Upraviť zápas')}
                    </Button>
                    &nbsp;
                    <Button danger onClick={deleteEvent} loading={isDeleting}>
                      {__('Zmazať zápas')}
                    </Button>
                  </>
                )}
              </Action>
            </ActionHeader>
          </SegmentHeader>
        }
      >
        <InfoBlocks>
          {createInfoBlock(
            'calendar',
            format(new Date(event.startDate), 'DD.MM.YYYY o HH:mm'),
            __('Dátum a čas stretnutia'),
          )}
          {createInfoBlock(
            'place',
            event.sportGround.name,
            __('Miesto stretnutia'),
          )}
          {!event.closed &&
            createInfoBlock(
              'users',
              `${String(freeCapacity)} ${__('z')} ${String(event.capacity)}`,
              __('Voľné miesta'),
            )}
          {!event.closed &&
            createInfoBlock('surface', event.surface, __('Povrch'))}
          {!event.closed && createInfoBlock('level', event.level, __('Úroveň'))}
          {!event.closed &&
            createInfoBlock(
              'gender',
              getGenderLabel(event.rules.gender),
              __('Pohlavie'),
            )}
        </InfoBlocks>
      </Segment>
      {event.closed && getProp(event, ['protocol', 'events'], []).length > 0 && (
        <Segment
          raised
          header={
            <SegmentHeader size="s" withSeparator>
              {__('Priebeh zápasu')}
            </SegmentHeader>
          }
        >
          <TimeLine
            teams={event.teams || []}
            protocol={{
              events:
                event.protocol && event.protocol.events
                  ? event.protocol.events
                  : [],
            }}
            eventTypes={[
              {
                _id: 'goal_game',
                label: 'Gól z hry',
                eventType: 'goal',
              },
              {
                _id: 'goal_standard',
                label: 'Gól zo štandardnej situácie',
                eventType: 'goal',
              },
              {
                _id: 'goal_penalty',
                label: 'Pokutový kop',
                eventType: 'goal',
              },
              {
                _id: 'failed_goal_penalty',
                label: 'Nepremenený pokutový kop',
                eventType: 'failed_goal',
              },
              {
                _id: 'dropped',
                label: 'Vlastný gól',
                eventType: 'goal',
              },
              {
                _id: 'goal_contumation',
                label: 'Kontumačný gól',
                eventType: 'goal',
              },
              {
                _id: 'goal_shootout',
                label: 'Pokutový kop - penáltový rozstrel',
                eventType: 'goal',
              },
              {
                _id: 'substitution',
                label: 'Striedanie',
                eventType: 'substitution',
              },
              {
                _id: 'yellow_card',
                label: 'Žltá karta',
                eventType: 'yellow_card',
              },
              {
                _id: 'second_yellow_card',
                label: 'Druhá žltá karta',
                eventType: 'second_yellow_card',
              },
              {
                _id: 'red_card',
                label: 'Červená karta',
                eventType: 'red_card',
              },
            ]}
          />
        </Segment>
      )}
      {!event.closed &&
        (!event.public || isPlayer || isCreator) &&
        event.players &&
        event.players.length > 0 && (
          <Segment
            raised
            header={
              <SegmentHeader withSeparator size="s">
                {__('Potvrdená účasť')}
              </SegmentHeader>
            }
          >
            <InfoBlocks>
              {event.players.map(player => {
                const nominatedPlayers = (event.nominations || []).reduce(
                  (acc, n) => [...acc, ...(n.athletes || [])],
                  [],
                );
                const isNominated = nominatedPlayers.find(
                  p => p.sportnetUser._id === player._id,
                );
                return createInfoBlock(
                  'player',
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>{`${player.name} ${player.surname}`}</div>
                    {isCreator && (
                      <CustomButton
                        danger
                        disabled={!!isNominated}
                        loading={isRemovingPlayer === player._id}
                        onClick={() => {
                          if (!isNominated) {
                            removePlayer(player._id);
                          }
                        }}
                      >
                        {__('Odstrániť')}
                      </CustomButton>
                    )}
                  </div>,
                );
              })}
            </InfoBlocks>
          </Segment>
        )}
    </>
  );
};

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
)(EventHeader) as any;
