import Input from '@sportnet/ui/lib/Input';
import { addSeconds, differenceInSeconds, format } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

const Panel = styled.div`
  margin-bottom: ${rem(10)};
  box-shadow: 0 ${rem(1)} ${rem(5)} 0 rgba(0, 0, 0, 0.1);
`;

interface OwnProps {
  onToggle: (state: boolean, value: number, forceUpdate?: boolean) => void;
  matchId?: string;
  initialData: {
    ongoing: boolean;
    startDate: string;
    seconds: number;
  };
}

type Props = OwnProps;

class ProtocolTimer extends React.PureComponent<Props> {
  state = {
    startDate: format(new Date()),
    temporaryDate: '',
    secondsToAdd: 0,
  };

  timerEvent: any = null;

  componentDidMount() {
    this.setTimer();
  }

  setTimer = (props = this.props) => {
    const {
      initialData: { seconds, startDate, ongoing },
    } = props;
    this.setState({
      secondsToAdd: seconds,
      startDate,
    });
    if (ongoing) {
      this.startTimer();
    }
  };

  startTimer = () => {
    clearInterval(this.timerEvent);
    this.timerEvent = 1;
    this.forceUpdate();
    this.timerEvent = setInterval(() => {
      const secondsToAdd = this.state.secondsToAdd + 1;
      this.setState({
        secondsToAdd,
      });
      this.props.onToggle(true, secondsToAdd);
    }, 1000);
  };

  pauseTimer = () => {
    this.props.onToggle(false, this.state.secondsToAdd);
    clearInterval(this.timerEvent);
    this.timerEvent = null;
    this.forceUpdate();
  };

  onChangeTime = (e: any) => {
    this.setState({
      temporaryDate: e.target.value,
    });
  };

  onBlurInput = () => {
    if (this.state.temporaryDate) {
      const split = this.state.temporaryDate.split(':');
      if (split && split.length === 2) {
        const seconds = Number(split[0]) * 60 + Number(split[1]);
        if (Number.isNaN(seconds)) {
          this.fallBackTime();
        } else {
          this.setState({
            secondsToAdd: seconds,
            temporaryDate: '',
          });
          this.props.onToggle(false, seconds, true);
        }
      } else {
        this.fallBackTime();
      }
    } else {
      this.fallBackTime();
    }
  };

  fallBackTime = () => {
    this.setState({
      temporaryDate: '',
    });
  };

  render() {
    const currentTime = addSeconds(
      this.state.startDate,
      this.state.secondsToAdd,
    );
    const diff = differenceInSeconds(currentTime, this.state.startDate);
    const minutes = String(Math.floor(diff / 60)).padStart(2, '0');
    const seconds = String(diff % 60).padStart(2, '0');

    return (
      <Panel>
        <Input
          value={
            this.state.temporaryDate
              ? this.state.temporaryDate
              : `${minutes}:${seconds}`
          }
          disabled={this.timerEvent}
          onBlur={this.onBlurInput}
          onChange={this.onChangeTime}
        >
          <input
            onBlur={this.onBlurInput}
            onFocus={(event: any) => {
              event.target.select();
            }}
            onChange={this.onChangeTime}
          />
          {this.timerEvent ? (
            <Input.Button
              type="button"
              danger
              icon="pause-circle"
              onClick={this.pauseTimer}
            />
          ) : (
            <Input.Button
              type="button"
              primary
              icon="play-circle"
              onClick={this.startTimer}
            />
          )}
        </Input>
      </Panel>
    );
  }
}

export default ProtocolTimer;
