import { ACCESS_TOKEN_COOKIE_NAME } from '@sportnet/ui/lib/Authorization';
import Cookies from 'js-cookie';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import CoreApi from '../../api/CoreApi';
import HobbyApi from '../../api/HobbyApi';
import { loadAuthUser } from './actions';

type OwnProps = {};

const mapDispatchToProps = {
  getAuthUser: thunkToAction(loadAuthUser.action),
};

type Props = OwnProps &
  typeof mapDispatchToProps &
  RouteComponentProps<void, void>;

const AuthLoader: React.FC<Props> = ({ children, getAuthUser }) => {
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) || '';
  if (accessToken) {
    CoreApi.setToken(accessToken);
    HobbyApi.setToken(accessToken);
  }

  useAsyncData(async () => {
    await getAuthUser();
  }, [getAuthUser]);
  return <>{children}</>;
};

export default connect(
  null,
  mapDispatchToProps,
)(AuthLoader);
