import Button from '@sportnet/ui/lib/Button';
import FormField from '@sportnet/ui/lib/FormField';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formatUserName } from 'sportnet-utilities';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import HobbyApi from '../../api/HobbyApi';
import BasicTable from '../../components/BasicTable';
import CustomButton from '../../components/CustomButton';
import config from '../../config';
import { loadEventById } from '../../containers/Events/actions';
import Loading from '../../containers/Loading';
import { Event, Team } from '../../library/Event';
import { Player } from '../../library/Tournament';
import __ from '../../utilities/__';

interface OwnProps {
  event: Event;
  team: Team | null;
}

const mapDispatchToProps = {
  getEvent: thunkToAction(loadEventById.action),
};

const TeamAssignment: React.FC<OwnProps & typeof mapDispatchToProps> = ({
  event,
  team,
  getEvent,
}) => {
  const [isFetching, setIsFetching] = React.useState(true);
  const [nominations, setNominations] = React.useState<
    Array<{ _id: string; name: string }>
  >([]);
  const [playerIdsSubmitting, setPlayerIdsSubmitting] = React.useState<
    string[]
  >([]);
  const [isNameChanging, setIsNameChanging] = React.useState(false);
  const [teamName, setTeamName] = React.useState('');

  useAsyncData(async () => {
    setIsFetching(true);
    await fetchTeamNomination();
    setIsFetching(false);
    if (team) {
      setTeamName(team.name);
    }
  }, [team]);

  const fetchTeamNomination = async () => {
    if (team) {
      try {
        const { athletes = [] } = await HobbyApi.getEventTeamNomination(
          config.APP_SPACE,
          event._id,
          team._id,
        );
        setNominations(athletes.map(a => a.sportnetUser));
      } catch (e) {
        //
      } finally {
        setIsFetching(false);
      }
    }
  };

  const addToTeam = async (user: Player) => {
    if (team) {
      let currentIdsSubmitting = [...playerIdsSubmitting, user._id];
      setPlayerIdsSubmitting(currentIdsSubmitting);
      try {
        const athletes = [
          ...nominations.map(n => ({ sportnetUser: n })),
          { sportnetUser: { _id: user._id, name: formatUserName(user) } },
        ];
        await HobbyApi.updateEventTeamNomination(
          config.APP_SPACE,
          event._id,
          team._id,
          {},
          {
            teamId: team._id,
            athletes,
          },
        );
        await fetchTeamNomination();
        await getEvent({ eventId: event._id });
      } catch (e) {
        alert(__('Hráča sa nepodarilo pridať do tímu.'));
      } finally {
        currentIdsSubmitting = [...playerIdsSubmitting].reduce((acc, i) => {
          if (i === user._id) {
            return acc;
          }
          return [...acc, i];
        }, []);
        setPlayerIdsSubmitting(currentIdsSubmitting);
      }
    }
  };

  const removeFromTeam = async (user: Player) => {
    if (team) {
      let currentIdsSubmitting = [...playerIdsSubmitting, user._id];
      setPlayerIdsSubmitting(currentIdsSubmitting);
      try {
        await HobbyApi.updateEventTeamNomination(
          config.APP_SPACE,
          event._id,
          team._id,
          {},
          {
            teamId: team._id,
            athletes: nominations
              .map(n => ({ sportnetUser: n }))
              .reduce((acc, a) => {
                if (a.sportnetUser._id === user._id) {
                  return acc;
                }
                return [...acc, a];
              }, []),
          },
        );
        await fetchTeamNomination();
        await getEvent({ eventId: event._id });
      } catch (e) {
        alert(__('Hráča sa nepodarilo odstrániť z tímu.'));
      } finally {
        currentIdsSubmitting = [...playerIdsSubmitting].reduce((acc, i) => {
          if (i === user._id) {
            return acc;
          }
          return [...acc, i];
        }, []);
        setPlayerIdsSubmitting(currentIdsSubmitting);
      }
    }
  };

  const changeTeamName = async () => {
    if (team && teamName.trim().length > 0) {
      setIsNameChanging(true);
      try {
        await HobbyApi.updateEventTeamNomination(
          config.APP_SPACE,
          event._id,
          team._id,
          {},
          {
            teamId: team._id,
            athletes: nominations.map(n => ({ sportnetUser: n })),
            name: teamName,
          },
        );
        await fetchTeamNomination();
        await getEvent({ eventId: event._id });
      } catch (e) {
        alert(__('Zmeny sa nepodarilo uložiť.'));
      } finally {
        setIsNameChanging(false);
      }
    }
  };

  if (isFetching) {
    return <Loading withoutWrapper />;
  }

  const nominated = (event.nominations || []).reduce((acc, n) => {
    if (team && n.teamId === team._id) {
      return acc;
    }
    return [...acc, ...(n.athletes || []).map(a => a.sportnetUser._id)];
  }, []);

  return (
    <Segment>
      <Segment
        raised
        header={
          <SegmentHeader size="xs" withSeparator>
            {__('Základné údaje')}
          </SegmentHeader>
        }
      >
        <Row>
          <Col xs={12}>
            <FormField
              label={__('Názov tímu')}
              value={teamName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTeamName(e.target.value)
              }
            />
          </Col>
          <Col xs={12}>
            <Button
              primary
              loading={isNameChanging}
              onClick={() => {
                changeTeamName();
              }}
            >
              {__('Uložiť')}
            </Button>
          </Col>
        </Row>
      </Segment>
      <Segment
        raised
        header={
          <SegmentHeader size="xs" withSeparator>
            {__('Správa hráčov')}
          </SegmentHeader>
        }
      >
        <BasicTable
          rows={(event.players || []).filter(p => !nominated.includes(p._id))}
          rowKey="_id"
          renderRow={i => {
            const inTeam = nominations.find(n => n._id === i._id);
            return [
              formatUserName(i),
              <div style={{ textAlign: 'right' }} key={i._id}>
                {inTeam ? (
                  <CustomButton
                    loading={playerIdsSubmitting.includes(i._id)}
                    disabled={playerIdsSubmitting.length > 0}
                    danger
                    onClick={() => {
                      removeFromTeam(i);
                    }}
                  >
                    {__('Odstrániť z tímu')}
                  </CustomButton>
                ) : (
                  <CustomButton
                    loading={playerIdsSubmitting.includes(i._id)}
                    disabled={playerIdsSubmitting.length > 0}
                    onClick={() => {
                      addToTeam(i);
                    }}
                  >
                    {__('Pridať do tímu')}
                  </CustomButton>
                )}
              </div>,
            ];
          }}
          compact
          raised={false}
          columns={[{ header: __('Meno a priezvisko') }, { header: '' }]}
        />
      </Segment>
    </Segment>
  );
};

export default compose(connect(null, mapDispatchToProps))(TeamAssignment);
