import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import * as React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Team } from '../../library/Event';
import required from '../../utilities/required';
import __ from '../../utilities/__';

type OwnProps = {
  teams?: Team[];
};

const InvitationForm: React.FC<OwnProps> = ({ teams }) => {
  return (
    <Row>
      {teams && teams.length > 0 && (
        <Col xs={12}>
          <Field
            name="teamId"
            component={FormField}
            type="theselectsimple"
            options={teams.map(t => ({
              label: t.name,
              value: t._id,
            }))}
            label={__('Tím')}
            validate={[required]}
            required
          />
        </Col>
      )}
      <Col xs={12}>
        <Field
          name="email"
          component={FormField}
          label={__('Emailová adresa')}
          validate={[required]}
          required
        />
      </Col>
    </Row>
  );
};

export default compose(reduxForm({}))(InvitationForm as any) as any;
