import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';

const Tab = styled.div<{ active?: boolean }>`
  padding: ${rem(10)};
  font-weight: ${({ active }) => (active ? '500' : '100')};
  height: ${rem(41)};
  border-bottom: ${rem(2)} solid
    ${({ active, theme }) => (active ? theme.color.primary : '#eee')};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${rem(10)};
  /* ${Tab}:first-child {
    padding-left: 0;
  } */
  ${Tab}:last-child {
    flex-grow: 1;
  }
`;

interface OwnProps {
  pathname: string;
  items: Array<{
    label: string;
    url: string;
  }>;
}

const Tabber: React.FC<OwnProps> = ({ items, pathname }) => {
  return (
    <Wrapper>
      {items.map(({ url, label }) => (
        <Link key={url} to={url}>
          <Tab active={pathname === url}>{label}</Tab>
        </Link>
      ))}
      <Tab />
    </Wrapper>
  );
};

export default Tabber;
