import { getListResults } from 'redux-list';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import { entitiesSelector } from '../../containers/App/selectors';

export const publicEventsSelector = (listName: string) =>
  createSelector(
    entitiesSelector,
    getListResults(listName),
    (entities, ids) => {
      return at(entities.events, ids || []);
    },
  );

export const publicTournamentsSelector = (listName: string) =>
  createSelector(
    entitiesSelector,
    getListResults(listName),
    (entities, ids) => {
      return at(entities.tournaments, ids || []);
    },
  );
