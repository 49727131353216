import * as React from 'react';

const AdminLayoutContext = React.createContext({
  headerComponents: {
    header: '',
    subHeader: '',
  },
  setHeaderComponents: (header: string, subHeader: string) => {
    //
  },
  messageComponents: {
    title: '',
    content: '',
  },
  setMessageComponents: (title: string, content: string) => {
    //
  },
});

export const AdminLayoutProvider = AdminLayoutContext.Provider;
export const AdminLayoutConsumer = AdminLayoutContext.Consumer;
export default AdminLayoutContext;
