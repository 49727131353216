import { createSelector } from 'reselect';
import { getProp } from 'sportnet-utilities';
import { RootState } from '../../configureStore';
import {
  CodelistItems,
  CodelistLabelByValue,
  ICodelistItemsByValue,
} from '../../library/App';
import { EntitiesState } from './reducer';

// Entities
export const entitiesSelector = (state: RootState) => {
  return state.entities;
};
export const entityTypeSelector = <T extends keyof EntitiesState>(type: T) =>
  createSelector(
    entitiesSelector,
    () => type,
    (entities, entityType) => entities[entityType],
  );

export const entitySelector = <T extends keyof EntitiesState, I extends string>(
  type: T,
  id: I,
) =>
  createSelector(
    entityTypeSelector(type),
    () => id,
    (entityById, entityId) =>
      entityId in entityById ? entityById[entityId] : null,
  );

// Codelist

export const codelistItemsSelector = (id: string) =>
  createSelector(
    entitySelector('codelists', id),
    codelist =>
      (getProp(codelist, ['items'], []) as CodelistItems).map(
        ({ label, value }) => ({
          value,
          label: label || value,
        }),
      ),
  );

export const codelistItemsByValueSelector = (id: string) =>
  createSelector(
    codelistItemsSelector(id),
    codelistItems => {
      return codelistItems.reduce(
        (acc, codelistItem) => {
          acc[codelistItem.value] = codelistItem;
          return acc;
        },
        {} as ICodelistItemsByValue,
      );
    },
  );

export const codelistLabelByValueSelector = (id: string) =>
  createSelector(
    codelistItemsSelector(id),
    codelistItems => {
      return codelistItems.reduce((acc: CodelistLabelByValue, codelistItem) => {
        acc[codelistItem.value] = codelistItem.label || codelistItem.value;
        return acc;
      }, {});
    },
  );

const applicationDomainSelector = (state: RootState) => state.application;

const authUserDomainSelector = createSelector(
  applicationDomainSelector,
  domain => domain.authUser,
);

export const accessTokenSelector = createSelector(
  authUserDomainSelector,
  domain => domain.accessToken,
);

export const authUserProfileSelector = createSelector(
  authUserDomainSelector,
  domain => domain.profile,
);

export const isUserSignedInSelector = createSelector(
  authUserDomainSelector,
  domain => !!domain.profile,
);

const userPreferencesDomainSelector = createSelector(
  applicationDomainSelector,
  domain => domain.userPreferences,
);

export const friendsListSelector = createSelector(
  userPreferencesDomainSelector,
  state => state.friends,
);
