import React from 'react';
import { Route, Router } from 'react-router';
import App from './containers/App';
import AuthLoader from './containers/AuthLoader';
import Authorization from './containers/Authorization';
import Event from './pages/Event';
import EventCopy from './pages/Event/copy';
import EventEdit from './pages/Event/edit';
import EventDetail from './pages/EventDetail';
import EventInvitation from './pages/EventInvitation';
import EventProtocol from './pages/EventProtocol';
import Events from './pages/Events';
import AdminEvents from './pages/Events/AdminEvents';
import MeEvents from './pages/Events/MeEvents';
import Home from './pages/Home';
import Tournament from './pages/Tournament';
import TournamentEdit from './pages/Tournament/edit';
import TournamentDetail from './pages/TournamentDetail';
import TournamentEvent from './pages/TournamentEvent';
import TournamentEventEdit from './pages/TournamentEvent/edit';
import TournamentEventDetail from './pages/TournamentEventDetail';
import TournamentEventProtocol from './pages/TournamentEventProtocol';
import TournamentInvitation from './pages/TournamentInvitation';
import Tournaments from './pages/Tournaments';
import AdminTournaments from './pages/Tournaments/AdminTournaments';
import MeTournaments from './pages/Tournaments/MeTournaments';
import TournamentTeam from './pages/TournamentTeam';

const Routes: React.SFC<any> = props => {
  return (
    <Router {...props}>
      <Route component={App}>
        <Route component={AuthLoader}>
          <Route path="/" component={Home} />
        </Route>
        <Route path="/admin" component={Authorization}>
          <Route path="/admin/events" component={Events}>
            <Route path="/admin/events/me" component={MeEvents} />
            <Route path="/admin/events/admin" component={AdminEvents} />
          </Route>
          <Route path="/admin/event" component={Event} />
          <Route path="/admin/event/:eventId" component={EventDetail} />
          <Route
            path="/admin/event/:eventId/protocol"
            component={EventProtocol}
          />
          <Route path="/admin/event/:eventId/edit" component={EventEdit} />
          <Route path="/admin/event/:eventId/copy" component={EventCopy} />
          <Route path="/admin/tournaments" component={Tournaments}>
            <Route path="/admin/tournaments/me" component={MeTournaments} />
            <Route
              path="/admin/tournaments/admin"
              component={AdminTournaments}
            />
          </Route>
          <Route path="/admin/tournament" component={Tournament} />
          <Route
            path="/admin/tournament/:tournamentId"
            component={TournamentDetail}
          />
          <Route
            path="/admin/tournament/:tournamentId/edit"
            component={TournamentEdit}
          />
          <Route
            path="/admin/tournament/:tournamentId/team/:teamId"
            component={TournamentTeam}
          />
          <Route
            path="/admin/tournament/:tournamentId/event"
            component={TournamentEvent}
          />
          <Route
            path="/admin/tournament/:tournamentId/event/:eventId"
            component={TournamentEventDetail}
          />
          <Route
            path="/admin/tournament/:tournamentId/event/:eventId/edit"
            component={TournamentEventEdit}
          />
          <Route
            path="/admin/tournament/:tournamentId/event/:eventId/protocol"
            component={TournamentEventProtocol}
          />
          <Route
            path="/admin/event/:eventId/invitation/:invitationId"
            component={EventInvitation}
          />
          <Route
            path="/admin/tournament/:tournamentId/invitation/:invitationId"
            component={TournamentInvitation}
          />
        </Route>
      </Route>
    </Router>
  );
};

export default Routes;
