import { rem } from 'polished';
import * as React from 'react';
import ReactDomServer from 'react-dom/server';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import styled, { withTheme } from 'styled-components';
import config from '../../config';
import { RootState } from '../../configureStore';
import { ITheme } from '../../theme/theme';
import PlaceTooltip from './placeTooltip';
import { publicEventsSelector, publicTournamentsSelector } from './selectors';

require('react-leaflet-markercluster/dist/styles.min.css');

const IconWrapper = styled.div`
  position: relative;
  text-align: center;
`;
const MarkerIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
`;
const MarkerText = styled.span`
  position: relative;
  color: white;
  font-weight: bold;
`;

const mapDispatchToProps = {};

type OwnProps = {
  activeDate: Date;
  resources: string[];
};

const mapStateToProps = (state: RootState, props: OwnProps) => {
  return {
    sportEvents: props.resources.includes('events')
      ? publicEventsSelector(config.PUBLIC_EVENTS_LIST_NAME)(state)
      : [],
    tournaments: props.resources.includes('tournaments')
      ? publicTournamentsSelector(config.PUBLIC_TOURNAMENTS_LIST_NAME)(state)
      : [],
  };
};

let LeafletElement: any;

const EventsMap: React.FC<OwnProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    theme: ITheme;
  } & RouteComponentProps<{}, {}>> = ({
  sportEvents,
  tournaments,
  theme,
  resources,
  router,
}) => {
  const createIcon = (imgSrc: string, text: string) => {
    const elem = (
      <IconWrapper>
        <MarkerIcon src={imgSrc} />
        <MarkerText>{text}</MarkerText>
      </IconWrapper>
    );
    return ReactDomServer.renderToString(elem);
  };

  const createPopup = (clusterItems: any[]) => (
    <PlaceTooltip selectedPlace={clusterItems} theme={theme} />
  );

  const [Leaflet, setLeaflet] = React.useState<any>(null);

  React.useEffect(() => {
    if (window) {
      const component = require('react-leaflet');
      const leafletComponent = require('leaflet');
      const clusterComponent = require('react-leaflet-markercluster');

      setLeaflet({
        Map: component.Map,
        Marker: component.Marker,
        Popup: component.Popup,
        LeafletMarker: leafletComponent.Marker,
        FeatureGroup: leafletComponent.FeatureGroup,
        TileLayer: component.TileLayer,
        Icon: leafletComponent.DivIcon,
        Cluster: clusterComponent.default,
      });
    }
  }, []);

  if (!Leaflet) {
    return null;
  }
  const EventMarker = new Leaflet.Icon({
    html: createIcon('./eventMarker.svg', '1'),
  });

  const TournamentMarker = new Leaflet.Icon({
    html: createIcon('./tournamentMarker.svg', '1'),
  });

  const markers = [...sportEvents, ...tournaments].map(
    i =>
      new Leaflet.LeafletMarker([...i.sportGround.geo.coordinates].reverse()),
  );
  const featureGroup = new Leaflet.FeatureGroup(markers as any);
  const bounds = featureGroup.getBounds();

  if (LeafletElement) {
    // LeafletElement.leafletElement.options.minZoom = 11;
    LeafletElement.leafletElement.setMaxBounds(bounds);
  }

  return (
    <div id="eventsMap" style={{ width: '100%', height: window.innerHeight }}>
      <Leaflet.Map
        ref={(i: any) => {
          LeafletElement = i;
        }}
        style={{ height: rem(window.innerHeight) }}
        center={[48.718663, 19.456982]}
        minZoom={8}
        {...(markers.length && { bounds })}
        zoom={8}
      >
        <Leaflet.TileLayer
          url="https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=ODPpnBZ9YFjgcQZMUd86"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          tileSize={512}
          zoomOffset={-1}
        />
        <Leaflet.Cluster
          iconCreateFunction={(cluster: any) => {
            return new Leaflet.Icon({
              html: createIcon('./mixedMarker.svg', cluster._childCount),
            });
          }}
          // spiderfyOnMaxZoom={false}
          showCoverageOnHover={false}
        >
          {sportEvents.map(i => (
            <Leaflet.Marker
              key={i._id}
              icon={EventMarker}
              position={[...i.sportGround.geo.coordinates].reverse()}
            >
              <Leaflet.Popup minWidth={200}>{createPopup([i])}</Leaflet.Popup>
            </Leaflet.Marker>
          ))}
          {tournaments.map(i => (
            <Leaflet.Marker
              key={i._id}
              icon={TournamentMarker}
              position={[...i.sportGround.geo.coordinates].reverse()}
            >
              <Leaflet.Popup minWidth={200}>{createPopup([i])}</Leaflet.Popup>
            </Leaflet.Marker>
          ))}
        </Leaflet.Cluster>
      </Leaflet.Map>
    </div>
  );
};

export default compose<React.FC<OwnProps>>(
  withRouter,
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
)(EventsMap);
