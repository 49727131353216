import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import HobbyApi from '../../api/HobbyApi';
import AdminLayoutContext from '../../components/AdminLayout/context';
import { Content } from '../../components/Layout';
import config from '../../config';
import { RootState } from '../../configureStore';
import { loadTournamentEventById } from '../../containers/Events/actions';
import { eventByIdSelector } from '../../containers/Events/selectors';
import Loading from '../../containers/Loading';
import Protocol from '../../containers/Protocol';
import __ from '../../utilities/__';

type RouteProps = RouteComponentProps<
  { eventId: string; tournamentId: string },
  {}
>;

const mapStateToProps = (state: RootState, props: RouteProps) => ({
  event: eventByIdSelector(props.params.eventId)(state),
});

const mapDispatchToProps = {
  loadEvent: thunkToAction(loadTournamentEventById.action),
};

const TournamentEventProtocol: React.FC<
  typeof mapDispatchToProps & RouteProps & ReturnType<typeof mapStateToProps>
> = ({ loadEvent, event, params: { eventId, tournamentId } }) => {
  const [isFetching, setIsFetching] = React.useState(false);
  useAsyncData(async () => {
    await loadEventData();
  }, [eventId]);

  const loadEventData = async () => {
    setIsFetching(true);
    await loadEvent({ eventId, tournamentId });
    setIsFetching(false);
  };

  const updateEvent = async (values: any) => {
    try {
      const res = await HobbyApi.updateTournamentEvent(
        config.APP_SPACE,
        tournamentId,
        eventId,
        {},
        {
          ...values,
          protocol: {
            events: values.protocol ? values.protocol.events || [] : [],
          },
        },
      );
      return res;
    } catch (e) {
      alert(__('Priebeh zápasu sa nepodarilo uložiť'));
    }
    return;
  };

  const { setHeaderComponents, headerComponents } = React.useContext(
    AdminLayoutContext,
  );
  const componentHeader = __('Turnaje');
  const componentSubHeader = __('Priebeh zápasu');

  if (
    headerComponents.header !== componentHeader ||
    headerComponents.subHeader !== componentSubHeader
  ) {
    setHeaderComponents(componentHeader, componentSubHeader);
  }

  if (isFetching) {
    return <Loading />;
  }

  return (
    <Content>
      {event && <Protocol updateEvent={updateEvent} event={event} />}
    </Content>
  );
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TournamentEventProtocol);
