import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formatUserName } from 'sportnet-utilities';
import BasicTable from '../../components/BasicTable';
import CustomButton from '../../components/CustomButton';
import { RootState } from '../../configureStore';
import { friendsListSelector } from '../../containers/App/selectors';
import { IFriend } from '../../library/App';
import __ from '../../utilities/__';

interface OwnProps {
  onInvite: (friend: IFriend) => void;
}

const mapStateToProps = (state: RootState) => ({
  friends: friendsListSelector(state),
});

const FriendsList: React.FC<OwnProps & ReturnType<typeof mapStateToProps>> = ({
  friends,
  onInvite,
}) => {
  return (
    <div style={{ overflowX: 'scroll', maxHeight: rem(172) }}>
      <BasicTable
        raised={false}
        compact
        rows={friends}
        renderRow={i => [
          formatUserName(i),
          <div key={i.sportnetId} style={{ textAlign: 'right' }}>
            <CustomButton
              onClick={() => {
                onInvite(i);
              }}
            >
              {__('Pozvať')}
            </CustomButton>
          </div>,
        ]}
        rowKey="sportnetId"
      />
    </div>
  );
};

export default compose(connect(mapStateToProps))(FriendsList);
