import Button from '@sportnet/ui/lib/Button';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/lib/Modal';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import styled from 'styled-components';
import HobbyApi from '../../api/HobbyApi';
import CustomButton from '../../components/CustomButton';
import Icons from '../../components/Icons';
import { Content } from '../../components/Layout';
import TournamentEventHeader from '../../components/TournamentEventHeader';
import config from '../../config';
import { CustomThunkDispatch } from '../../configureStore';
import { Event } from '../../library/Event';
import { Tournament } from '../../library/Tournament';
import useWindowSize from '../../utilities/resizeHook';
import __ from '../../utilities/__';
import TeamAssignment from './teamAssignment';

const Teams = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const TeamRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: ${rem(14)};
  padding: ${rem(5)} 0;
`;
const TeamWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface OwnProps {
  event: Event;
  tournament: Tournament;
  isCreator: boolean;
}

const TournamentEventDetail: React.FC<
  OwnProps & RouteComponentProps<{}, {}> & { dispatch: CustomThunkDispatch }
> = ({ event, tournament, isCreator, dispatch, router: { push } }) => {
  const resizeHook = useWindowSize();

  const [mobileLayout, setMobileLayout] = React.useState(
    window.innerWidth < 600,
  );

  const [activeTeamAssignment, setActiveTeamAssignment] = React.useState<
    null | string
  >(null);

  const [isDeleting, setIsDeleting] = React.useState(false);

  React.useEffect(() => {
    if (resizeHook.innerWidth < 600) {
      setMobileLayout(true);
    } else {
      setMobileLayout(false);
    }
  }, [resizeHook.innerWidth]);

  const toggleTeamAssignmentModal = (teamId?: string) => {
    setActiveTeamAssignment(teamId || null);
  };

  const deleteEvent = async () => {
    if (window.confirm(__('Skutočne si želáte zmazať zápas?'))) {
      setIsDeleting(true);
      try {
        await HobbyApi.deleteTournamentEvent(
          config.APP_SPACE,
          tournament._id,
          event._id,
        );
        push(`/admin/tournament/${tournament._id}`);
      } catch (e) {
        alert(__('Zápas sa nepodarilo odstrániť'));
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const team = (event.teams || []).find(i => i._id === activeTeamAssignment);

  return (
    <Content>
      <TournamentEventHeader event={event} isPlayer isCreator={isCreator} />
      {!event.closed && (
        <Segment
          raised
          header={
            <SegmentHeader size="xs" withSeparator>
              {__('Zoznam tímov')}
            </SegmentHeader>
          }
        >
          <Teams>
            {(event.teams || []).map(t => (
              <TeamRow key={t._id}>
                <TeamWrapper>
                  <>
                    <div style={{ padding: `${rem(5)} ${rem(10)}` }}>
                      <Icons size={20} color="#ccc" name="users" />
                    </div>
                    &nbsp;{t.name}
                  </>
                </TeamWrapper>
                {isCreator && !event.closed && (
                  <div style={{ textAlign: 'right' }}>
                    <CustomButton
                      onClick={() => {
                        toggleTeamAssignmentModal(t._id);
                      }}
                    >
                      {__('Spravovať hráčov')}
                    </CustomButton>
                  </div>
                )}
              </TeamRow>
            ))}
          </Teams>
        </Segment>
      )}
      <Modal
        isOpen={!!activeTeamAssignment}
        handleClose={() => {
          toggleTeamAssignmentModal();
        }}
      >
        <ModalContent>
          <TeamAssignment
            tournament={tournament}
            event={event}
            team={team || null}
          />
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button
              onClick={() => {
                toggleTeamAssignmentModal();
              }}
            >
              {__('Zavrieť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
      {isCreator && (
        <>
          {mobileLayout ? (
            <div>
              <Button
                success
                block
                onClick={() => {
                  push(
                    `/admin/tournament/${tournament._id}/event/${event._id}/protocol`,
                  );
                }}
              >
                {event.closed
                  ? __('Upraviť priebeh zápasu')
                  : __('Prejsť na priebeh zápasu')}
              </Button>
              <div style={{ height: rem(10) }} />
              <Button
                warning
                block
                onClick={() => {
                  push(
                    `/admin/tournament/${tournament._id}/event/${event._id}/edit`,
                  );
                }}
              >
                {__('Upraviť zápas')}
              </Button>
              {!event.closed && isCreator && (
                <>
                  <div style={{ height: rem(10) }} />
                  <Button
                    danger
                    block
                    onClick={deleteEvent}
                    loading={isDeleting}
                  >
                    {__('Zmazať zápas')}
                  </Button>
                </>
              )}
            </div>
          ) : (
            <div style={{ textAlign: 'right' }}>
              <Button
                success
                onClick={() => {
                  push(
                    `/admin/tournament/${tournament._id}/event/${event._id}/protocol`,
                  );
                }}
              >
                {event.closed
                  ? __('Upraviť priebeh zápasu')
                  : __('Prejsť na priebeh zápasu')}
              </Button>
              &nbsp;
              <Button
                warning
                onClick={() => {
                  push(
                    `/admin/tournament/${tournament._id}/event/${event._id}/edit`,
                  );
                }}
              >
                {__('Upraviť zápas')}
              </Button>
              {!event.closed && isCreator && (
                <>
                  &nbsp;
                  <Button danger onClick={deleteEvent} loading={isDeleting}>
                    {__('Zmazať zápas')}
                  </Button>
                </>
              )}
            </div>
          )}
        </>
      )}
    </Content>
  );
};

export default compose<React.FC<OwnProps>>(
  withRouter,
  connect(),
)(TournamentEventDetail);
