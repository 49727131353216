import { ACCESS_TOKEN_COOKIE_NAME } from '@sportnet/ui/lib/Authorization';
import SportnetIcon from '@sportnet/ui/lib/Icon';
import SportnetIcons from '@sportnet/ui/lib/Icon/icons';
import Message from '@sportnet/ui/lib/Message';
import * as cookies from 'js-cookie';
import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, withRouter, WithRouterProps } from 'react-router';
import { MorphReplace } from 'react-svg-morph';
import { compose } from 'redux';
import { formatUserName } from 'sportnet-utilities';
import { useAsyncData } from 'ssr-service';
import { withTheme } from 'styled-components';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import Icon from '../../components/Icons';
import { RootState } from '../../configureStore';
import { getFriendsList } from '../../containers/App/actions';
import { authUserProfileSelector } from '../../containers/App/selectors';
import { Box } from '../../pages/Home/styledComponents';
import { ITheme } from '../../theme/theme';
import __ from '../../utilities/__';
import AvatarImg from './avatar.svg';
import AdminLayoutContext, { AdminLayoutProvider } from './context';
import {
  ABSOLUTE_SIDEBAR_BREAKPOINT,
  Content,
  DesktopMenuTrigger,
  Header,
  HeaderWrapper,
  Menu,
  MenuItem,
  MenuItems,
  MobileMenu,
  MobileMenuItem,
  Sidebar,
  SidebarDimmer,
  SubHeader,
  Top,
  TopWrapper,
  User,
} from './styledComponents';

const Burger = () => SportnetIcons.burger('24', '#fff');
const Close = () => SportnetIcons.close('24', '#fff');

interface OwnProps {
  header?: string;
}

const mapDispatchToProps = {
  getFriends: thunkToAction(getFriendsList.action),
};

const mapStateToProps = (state: RootState) => ({
  userProfile: authUserProfileSelector(state),
});

const AdminLayout: React.FC<
  OwnProps & { theme: ITheme } & WithRouterProps &
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps
> = ({
  theme,
  location: { pathname },
  children,
  userProfile,
  router: { push },
  getFriends,
}) => {
  const [sidebarIsVisible, setSidebarVisibility] = React.useState(
    window.innerWidth >= ABSOLUTE_SIDEBAR_BREAKPOINT,
  );

  const [headerComponents, setHeaderComponents] = React.useState({
    header: '',
    subHeader: '',
  });

  const [messageComponents, setMessageComponents] = React.useState({
    title: '',
    content: '',
  });

  useAsyncData(async () => {
    getFriends();
  }, [getFriends]);

  const logout = () => {
    cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
    push('/');
  };

  const onClickMenuItem = () => {
    if (window.innerWidth <= ABSOLUTE_SIDEBAR_BREAKPOINT) {
      setSidebarVisibility(false);
    }
  };

  const { messageComponents: contextMessageComponents } = React.useContext(
    AdminLayoutContext,
  );

  React.useEffect(() => {
    setMessageComponents(contextMessageComponents);
  }, [contextMessageComponents]);

  return (
    <AdminLayoutProvider
      value={{
        setHeaderComponents: (header, subHeader) => {
          setHeaderComponents({ header, subHeader });
        },
        headerComponents,
        setMessageComponents: (title, content) => {
          setMessageComponents({ title, content });
        },
        messageComponents,
      }}
    >
      <div style={{ minHeight: '100vh' }}>
        <Helmet>
          <title>{headerComponents.subHeader}</title>
        </Helmet>
        <Content>
          <Sidebar visible={sidebarIsVisible}>
            <Menu>
              {userProfile && (
                <MenuItem isProfile>
                  <a
                    href={`https://my.sportnet.online/profile/personal-info`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <User>
                      <img
                        alt="profile_picture"
                        src={
                          userProfile.photo
                            ? userProfile.photo.public_url || AvatarImg
                            : AvatarImg
                        }
                      />
                      <div>{formatUserName(userProfile)}</div>
                    </User>
                  </a>
                </MenuItem>
              )}
              <MenuItems>
                <Link to="/">
                  <MenuItem>
                    <SportnetIcon
                      color={theme.color.base}
                      size={20}
                      name="home"
                    />
                    <div>{__('Hlavná stránka')}</div>
                  </MenuItem>
                </Link>
                <Link to="/admin/events/me" onClick={onClickMenuItem}>
                  <MenuItem active={pathname.includes('/admin/event')}>
                    <SportnetIcon
                      color={
                        pathname.includes('/admin/event')
                          ? theme.color.primary
                          : theme.color.base
                      }
                      size={20}
                      name="calendar"
                    />
                    <div>{__('Zápasy')}</div>
                  </MenuItem>
                </Link>
                <Link to="/admin/tournaments/me" onClick={onClickMenuItem}>
                  <MenuItem active={pathname.includes('/admin/tournament')}>
                    <Icon
                      color={
                        pathname.includes('/admin/tournament')
                          ? theme.color.primary
                          : theme.color.base
                      }
                      size={20}
                      name="trophy"
                    />
                    <div>{__('Turnaje')}</div>
                  </MenuItem>
                </Link>
                <MenuItem onClick={logout}>
                  <SportnetIcon
                    color={theme.color.base}
                    size={20}
                    name="power-off"
                  />
                  <div>{__('Odhlásiť sa')}</div>
                </MenuItem>
              </MenuItems>
            </Menu>
          </Sidebar>
          <SidebarDimmer
            onClick={() => {
              setSidebarVisibility(false);
            }}
            visible={sidebarIsVisible}
          />
          <TopWrapper sidebarIsVisible={sidebarIsVisible}>
            <Top>
              <HeaderWrapper>
                <Header>{headerComponents.header}</Header>
                <SubHeader>{headerComponents.subHeader}</SubHeader>
              </HeaderWrapper>
              <DesktopMenuTrigger>
                <Box
                  primary
                  clickable
                  onClick={() => {
                    setSidebarVisibility(!sidebarIsVisible);
                  }}
                >
                  <div>
                    <MorphReplace width={24} height={24}>
                      {sidebarIsVisible ? (
                        <Close key="close" />
                      ) : (
                        <Burger key="burger" />
                      )}
                    </MorphReplace>
                  </div>
                </Box>
              </DesktopMenuTrigger>
            </Top>
            {messageComponents.title &&
              (messageComponents.content &&
              cookies.get(`MESSAGE:${messageComponents.title}:DISABLED`) !==
                '1' ? (
                <div style={{ margin: `0 ${rem(10)}` }}>
                  <Message
                    title={messageComponents.title}
                    primary
                    onDispose={() => {
                      cookies.set(
                        `MESSAGE:${messageComponents.title}:DISABLED`,
                        '1',
                      );
                      setMessageComponents({ title: '', content: '' });
                    }}
                  >
                    {messageComponents.content}
                  </Message>
                </div>
              ) : null)}
            {children}
          </TopWrapper>
        </Content>
        <MobileMenu>
          <Link to="/">
            <MobileMenuItem>
              <SportnetIcon color={'#A9ACB3'} size={24} name="home" />
              <div>{__('Hl. stránka')}</div>
            </MobileMenuItem>
          </Link>
          <Link to="/admin/events/me">
            <MobileMenuItem active={pathname.includes('/admin/event')}>
              <SportnetIcon
                color={pathname.includes('/admin/event') ? '#fff' : '#A9ACB3'}
                size={24}
                name="calendar"
              />
              <div>{__('Zápasy')}</div>
            </MobileMenuItem>
          </Link>
          <Link to="/admin/tournaments/me">
            <MobileMenuItem active={pathname.includes('/admin/tournament')}>
              <Icon
                color={
                  pathname.includes('/admin/tournament') ? '#fff' : '#A9ACB3'
                }
                size={24}
                name="trophy"
              />
              <div>{__('Turnaje')}</div>
            </MobileMenuItem>
          </Link>
          <MobileMenuItem onClick={logout}>
            <SportnetIcon color={'#A9ACB3'} size={24} name="power-off" />
            <div>{__('Odhlásiť sa')}</div>
          </MobileMenuItem>
        </MobileMenu>
      </div>
    </AdminLayoutProvider>
  );
};

export default compose<React.FC<OwnProps>>(
  withTheme,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AdminLayout);
