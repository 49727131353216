import Paginator from '@sportnet/ui/lib/Paginator';
import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';
import useWindowSize from '../../utilities/resizeHook';

export const Table = styled.table`
  width: 100%;
  background: white;
  border-collapse: collapse;
`;

export const Tr = styled.tr<{ onClick?: () => void }>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
`;

export const Th = styled.th<{ compact?: boolean; width?: number }>`
  text-align: left;
  padding: ${({ compact }) => (compact ? `${rem(10)}` : rem(15))};
  color: #aaaaaa;
  font-size: ${rem(13)};
  ${({ width }) =>
    width &&
    css`
      width: ${rem(width)};
    `};
  font-weight: 300;
  border-bottom: ${rem(1)} solid #f1f1f1;
`;

export const Td = styled.td<{ compact?: boolean }>`
  text-align: left;
  padding: ${({ compact }) => (compact ? `${rem(5)} ${rem(10)}` : rem(15))};
  font-size: ${rem(14)};
  height: ${rem(52)};
`;

export const Thead = styled.thead``;
export const Tfoot = styled.tfoot`
  ${Td} {
    border-top: ${rem(1)} solid #f1f1f1;
  }
`;

export const Tbody = styled.tbody`
  ${Tr} {
    &:hover {
      background: #fafafa;
    }
  }
`;

export const TableWrapper = styled.div<{ raised: boolean }>`
  overflow-x: auto;
  ${({ raised }) =>
    raised &&
    css`
      box-shadow: 0 ${rem(1)} ${rem(5)} 0 rgba(0, 0, 0, 0.1);
    `};
`;

interface OwnProps {
  onClickRow?: (originalItem: any) => any;
  rows: any[];
  columns?: Array<{ header: string; width?: number }>;
  renderRow: (item: any, rowIdx: number) => React.ReactNode[];
  rowKey: string;
  compact?: boolean;
  colsCount?: number;
  withPaginator?: boolean;
  offset?: number;
  limit?: number;
  total?: number;
  onChangeOffset?: (newOffset: number) => void;
  raised?: boolean;
}

const BasicTable: React.FC<OwnProps> = ({
  columns,
  rows,
  rowKey,
  renderRow,
  onClickRow,
  withPaginator,
  offset,
  limit,
  total,
  onChangeOffset,
  colsCount,
  compact,
  raised = true,
}) => {
  const [maxTableWidth, setMaxTableWidth] = React.useState(
    window.innerWidth - 40,
  );

  const resizeHook = useWindowSize();

  React.useEffect(() => {
    if (window.innerWidth - 40 !== maxTableWidth) {
      setMaxTableWidth(window.innerWidth - 40);
    }
  }, [resizeHook.innerWidth, maxTableWidth]);
  return (
    <TableWrapper style={{ maxWidth: maxTableWidth }} raised={raised}>
      <Table>
        {columns && (
          <Thead>
            <Tr>
              {columns.map(column => (
                <Th
                  compact={!!compact}
                  align="left"
                  key={column.header}
                  {...(column.width && { width: column.width })}
                >
                  {column.header}
                </Th>
              ))}
            </Tr>
          </Thead>
        )}
        <Tbody>
          {rows.map((row, rowIdx) => (
            <Tr
              key={row[rowKey]}
              {...(onClickRow
                ? {
                    onClick: () => {
                      onClickRow(row);
                    },
                  }
                : {})}
            >
              {renderRow(row, rowIdx).map((item, idx) => (
                <Td compact={!!compact} key={idx}>
                  {item}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
        {withPaginator && (
          <Tfoot>
            <Tr>
              <Td colSpan={colsCount}>
                <Paginator
                  offset={Number(offset)}
                  limit={limit || 0}
                  total={total || 0}
                  onChangeOffset={onChangeOffset!}
                />
              </Td>
            </Tr>
          </Tfoot>
        )}
      </Table>
    </TableWrapper>
  );
};

export default BasicTable;
