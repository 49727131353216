import { commit, CommitError, getListParameters } from 'redux-list';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import {
  CustomThunkAction,
  ExtraArgumentType,
  RootState,
} from '../../configureStore';
import { NormalizedEntities, Pager } from '../../library/App';
import { Event } from '../../library/Event';
import normalizeEntities from '../../utilities/normalizeEntities';
import { replaceEntities } from '../App/actions';

const create = actionCreatorFactory('APP');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

interface EventsParameters {
  offset: number;
}

interface TournamentEventsParameters {
  offset: number;
  tournamentId: string;
}

export const loadMeEvents = createAsync<
  EventsParameters,
  Pick<NormalizedEntities<'events'>, 'results'> & Pager
>('GET_ME_EVENTS', async ({ offset }, dispatch, getState, { HobbyApi }) => {
  const response = await HobbyApi.getMeEvents(config.APP_SPACE, {
    limit: config.DEFAULT_LIST_LIMIT,
    offset,
  });
  const { entities, results } = normalizeEntities(
    'events',
    response.items || [],
  );
  dispatch(replaceEntities(entities));
  return {
    results,
    offset,
    total: response.total,
    limit: config.DEFAULT_LIST_LIMIT,
  };
});

export const loadMeEventsList = (): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(config.ME_EVENTS_LIST_NAME)(
      getState(),
    );
    return dispatch(
      commit.action({
        listName: config.ME_EVENTS_LIST_NAME,
        load: async () => {
          try {
            const { results, offset, total } = await dispatch(
              loadMeEvents.action(parameters as EventsParameters),
            );
            return {
              total,
              offset,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadAdminEvents = createAsync<
  EventsParameters,
  Pick<NormalizedEntities<'events'>, 'results'> & Pager
>('GET_ADMIN_EVENTS', async ({ offset }, dispatch, getState, { HobbyApi }) => {
  const response = await HobbyApi.getCreatedEvents(config.APP_SPACE, {
    limit: config.DEFAULT_LIST_LIMIT,
    offset,
  });
  const { entities, results } = normalizeEntities(
    'events',
    response.items || [],
  );
  dispatch(replaceEntities(entities));
  return {
    results,
    offset,
    total: response.total,
    limit: config.DEFAULT_LIST_LIMIT,
  };
});

export const loadAdminEventsList = (): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(config.ADMIN_EVENTS_LIST_NAME)(
      getState(),
    );
    return dispatch(
      commit.action({
        listName: config.ADMIN_EVENTS_LIST_NAME,
        load: async () => {
          try {
            const { results, offset, total } = await dispatch(
              loadAdminEvents.action(parameters as EventsParameters),
            );
            return {
              total,
              offset,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadEventById = createAsync<{ eventId: string }, Event>(
  'GET_EVENT',
  async ({ eventId }, dispatch, getState, { HobbyApi }) => {
    dispatch(replaceEntities({ events: {} }));
    const event = await HobbyApi.getEvent(config.APP_SPACE, eventId, {});
    const { entities } = normalizeEntities('events', [event]);
    dispatch(replaceEntities(entities));
    return event;
  },
);

export const loadEventInvitationById = createAsync<
  { eventId: string; invitationId: string },
  Event
>(
  'GET_EVENT_INVITATION',
  async ({ eventId, invitationId }, dispatch, getState, { HobbyApi }) => {
    dispatch(replaceEntities({ events: {} }));
    const event = await HobbyApi.getEventInvitation(
      config.APP_SPACE,
      eventId,
      invitationId,
    );
    const { entities } = normalizeEntities('events', [event]);
    dispatch(replaceEntities(entities));
    return event;
  },
);

export const loadTournamentEventById = createAsync<
  { eventId: string; tournamentId: string },
  Event
>(
  'GET_TOURNAMENT_EVENT',
  async ({ eventId, tournamentId }, dispatch, getState, { HobbyApi }) => {
    dispatch(replaceEntities({ events: {} }));
    const event = await HobbyApi.getTournamentEvent(
      config.APP_SPACE,
      tournamentId,
      eventId,
      {},
    );
    const { entities } = normalizeEntities('events', [event]);
    dispatch(replaceEntities(entities));
    return event;
  },
);

export const loadTournamentEvents = createAsync<
  TournamentEventsParameters,
  Pick<NormalizedEntities<'events'>, 'results'> & Pager
>(
  'GET_TOURNAMENT_EVENTS',
  async ({ offset, tournamentId }, dispatch, getState, { HobbyApi }) => {
    const response = await HobbyApi.getTournamentEvents(
      config.APP_SPACE,
      tournamentId,
      {
        limit: config.DEFAULT_LIST_LIMIT,
        offset,
      },
    );
    const { entities, results } = normalizeEntities(
      'events',
      response.items || [],
    );
    dispatch(replaceEntities(entities));
    return {
      results,
      offset,
      total: response.total,
      limit: config.DEFAULT_LIST_LIMIT,
    };
  },
);

export const loadTournamentEventsList = (): CustomThunkAction<
  Promise<void>
> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(config.TOURNAMENT_EVENTS_LIST_NAME)(
      getState(),
    );
    return dispatch(
      commit.action({
        listName: config.TOURNAMENT_EVENTS_LIST_NAME,
        load: async () => {
          try {
            const { results, offset, total } = await dispatch(
              loadTournamentEvents.action(
                parameters as TournamentEventsParameters,
              ),
            );
            return {
              total,
              offset,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};
