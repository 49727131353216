import * as React from 'react';
import styled, { withTheme } from 'styled-components';
import { ITheme } from '../../theme/theme';
import icons from './icons';

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

export type IIconName = keyof typeof icons;

export interface OwnProps {
  size?: string | number;
  color?: string;
  className?: string;
  name: IIconName;
}

const defaultProps = {
  size: 'm',
};

type Props = OwnProps & { theme: ITheme };

class Icons extends React.PureComponent<Props> {
  static defaultProps = defaultProps;
  sizeToPx = (size: string): number => {
    const sizeMapping = {
      xs: 8,
      s: 12,
      m: 16,
      l: 24,
      xl: 40,
      xxl: 110,
    };
    switch (size) {
      case 'xs':
        return sizeMapping.xs;
      case 's':
        return sizeMapping.s;
      case 'm':
        return sizeMapping.m;
      case 'l':
        return sizeMapping.l;
      case 'xl':
        return sizeMapping.xl;
      case 'xxl':
        return sizeMapping.xxl;
      default:
        return 16;
    }
  };

  /**
   * Method resolves deprecated names for icons
   */
  getName(nameFromProps: string) {
    switch (nameFromProps) {
      default:
        return nameFromProps;
    }
  }

  render() {
    const { size, color, className, name, ...restProps } = this.props;

    const iconSize = typeof size === 'string' ? this.sizeToPx(size) : size;
    const iconColor = color ? color : this.props.theme.textColor;
    return (
      <Wrapper className={`icon ${className}`} {...restProps}>
        {name in icons ? icons[name](String(iconSize), iconColor) : null}
      </Wrapper>
    );
  }
}

export default withTheme(Icons);
