import Button from '@sportnet/ui/lib/Button';
import Header from '@sportnet/ui/lib/Header';
import { em, rem } from 'polished';
import styled from '../../theme/styled-components';

export const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.app.bgColor};
`;

export const CenteredContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const MainContentWrapper = styled('main')`
  display: flex;
  flex-direction: column;
  margin: ${rem(10)} 0;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const RightContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Title = Header;

export const RightButton = styled(Button)`
  margin-left: auto;
  display: flex;
`;

export const MinWidthBox = styled.div<{ minWidth: number }>`
  min-width: ${({ minWidth }) => rem(minWidth)};
`;

export const Strong = styled.strong`
  margin: 0 ${em(3)};
`;

export const Content = styled.div`
  padding: 0 ${rem(10)};
`;
