// App related config
export default {
  APP_ID: 'hobby.public',
  APP_SPACE: 'dajmespolugol' as const,
  AUTH_CLIENT_ID: 'hobby',
  DATE_FORMAT: 'DD.MM.YYYY',
  DATE_TIME_FORMAT: 'DD.M.YYYY HH:mm',
  CREATED_DATE_FORMAT: 'dddd, DD.MM.YYYY',
  PUBLIC_EVENTS_LIST_NAME: 'PUBLIC_EVENTS_LIST',
  PUBLIC_TOURNAMENTS_LIST_NAME: 'PUBLIC_TOURNAMENTS_LIST',
  ME_EVENTS_LIST_NAME: 'ME_EVENTS_LIST',
  ADMIN_EVENTS_LIST_NAME: 'ADMIN_EVENTS_LIST',
  TOURNAMENT_EVENTS_LIST_NAME: 'TOURNAMENT_EVENTS_LIST',
  ME_TOURNAMENTS_LIST_NAME: 'ME_TOURNAMENTS_LIST',
  ADMIN_TOURNAMENTS_LIST_NAME: 'ADMIN_TOURNAMENTS_LIST',
  DEFAULT_LIST_LIMIT: 10,
};
