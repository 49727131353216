import { em, lighten, rem } from 'polished';
import styled from 'styled-components';

export const MOBILE_MENU_BREAKPOINT = 600;
export const SIDEBAR_WIDTH = 300;
export const ABSOLUTE_SIDEBAR_BREAKPOINT = 1000;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${rem(10)};
  height: ${rem(55)};
`;

export const HeaderWrapper = styled.div`
  border-left: ${rem(1)} solid #ddd;
  padding-left: ${rem(10)};
`;

export const Header = styled.div`
  font-size: ${rem(13)};
  letter-spacing: ${rem(2)};
  color: #bbb;
`;

export const SubHeader = styled.div`
  font-size: ${em(22)};
  font-weight: 400;
`;

export const MobileMenu = styled.div`
  @media screen and (min-width: ${MOBILE_MENU_BREAKPOINT}px) {
    display: none;
  }
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0.3125rem 1.25rem 0px;
  > * {
    flex-basis: 25%;
  }
`;

export const MobileMenuItem = styled.div<{ active?: boolean }>`
  text-align: center;
  padding: ${rem(8)};
  background: ${({ theme, active }) => (active ? theme.color.primary : '#fff')};
  color: ${({ active }) => (active ? '#fff' : '#666')};
  & > div {
    padding-top: ${rem(5)};
    font-size: ${em(13)};
    font-weight: ${({ active }) => (active ? 500 : 400)};
  }
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  @media screen and (max-width: ${MOBILE_MENU_BREAKPOINT}px) {
    margin-bottom: ${rem(65)};
  }
`;

export const Sidebar = styled.div<{ visible: boolean }>`
  @media screen and (max-width: ${MOBILE_MENU_BREAKPOINT}px) {
    display: none;
  }
  @media screen and (min-width: ${MOBILE_MENU_BREAKPOINT}px) and (max-width: ${ABSOLUTE_SIDEBAR_BREAKPOINT}px) {
    position: absolute;
    left: 0;
    top: 0;
  }
  z-index: 10;
  width: ${rem(SIDEBAR_WIDTH)};
  background: #fff;
  min-height: 100vh;
  border-right: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  transition: 0.2s linear all;
  margin-left: ${({ visible }) => (visible ? 0 : rem(-SIDEBAR_WIDTH))};
`;

export const SidebarDimmer = styled.div<{ visible?: boolean }>`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  pointer-events: none;
  @media screen and (min-width: ${MOBILE_MENU_BREAKPOINT}px) and (max-width: ${ABSOLUTE_SIDEBAR_BREAKPOINT}px) {
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  }
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out 0s;
`;

export const DesktopMenuTrigger = styled.div`
  @media screen and (max-width: ${MOBILE_MENU_BREAKPOINT}px) {
    display: none;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MenuItem = styled.div<{ active?: boolean; isProfile?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: ${rem(10)};
  &:hover {
    opacity: ${({ active, isProfile }) => (active || isProfile ? 1 : 0.6)};
  }
  & > div {
    padding-left: ${({ isProfile }) => (!isProfile ? rem(35) : 0)};
    font-weight: ${({ active }) => (active ? 600 : 200)};
    font-size: ${rem(14)};
    color: ${({ active, isProfile, theme }) =>
      active && !isProfile ? theme.color.primary : 'inherit'};
  }
  background: ${({ active, isProfile, theme }) =>
    active && !isProfile
      ? `${lighten(0.35, theme.color.primary)} !important`
      : '#fff'};
`;

export const MenuItems = styled.div`
  padding: ${rem(10)};
  display: flex;
  flex-direction: column;
  ${MenuItem} {
    cursor: pointer;
    padding: ${em(10)};
  }
`;

export const User = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: ${rem(10)} ${rem(10)} ${rem(10)} ${rem(10)};
  height: ${rem(55)};
  & > img {
    width: ${rem(40)};
    border-radius: 50%;
    margin-right: ${rem(25)};
  }
  & > div {
    font-weight: 400;
    font-size: ${em(22)};
  }
`;

export const TopWrapper = styled.div<{ sidebarIsVisible: boolean }>`
  flex: 1;
  padding-bottom: ${rem(10)};
`;
