import { rem, rgba } from 'polished';
import React from 'react';
import CookieBanner from 'react-cookie-banner';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { PageWrapper } from '../../components/Layout';
import { RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import { IThemeProps, withTheme } from '../../theme/styled-components';
import isBrowser from '../../utilities/isBrowser';
import __ from '../../utilities/__';

type OwnProps = {};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps &
  IThemeProps;

const App: React.FC<Props> = ({ children, location: { pathname } }) => {
  React.useEffect(() => {
    if (isBrowser()) {
      window.scroll({ left: 0, top: 0, behavior: 'smooth' });
    }
  }, [pathname]);

  return (
    <PageWrapper>
      <Helmet titleTemplate={`%s | Hobby futbal`}>
        <title>{__('Hlavná stránka')}</title>
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {children}
      <CookieBanner
        styles={{
          banner: {
            position: 'fixed',
            bottom: 0,
            backgroundColor: rgba(0, 0, 0, 0.8),
            fontSize: rem(14),
            height: 'auto',
            textAlign: 'left',
            padding: `${rem(10)} ${rem(15)}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          },
          button: {
            backgroundColor: '#fff',
            color: '#555',
            marginTop: 'auto',
            position: 'relative',
            right: 0,
            fontWeight: 'bold',
            margin: `${rem(5)} 0`,
          },
          message: {
            lineHeight: rem(20),
            margin: `${rem(5)} 0`,
          },
        }}
        message={__(
          'Tieto internetové stránky používajú k poskytovaniu služieb a analýze návštevnosti súbory cookie.',
        )}
        cookie="user-has-accepted-cookies"
        buttonMessage={__('Rozumiem')}
        dismissOnScroll={false}
      />
    </PageWrapper>
  );
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTheme,
)(App);
