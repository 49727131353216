import Button from '@sportnet/ui/lib/Button';
import Header from '@sportnet/ui/lib/Header';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/lib/Modal';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { destroy, submit } from 'redux-form';
import styled from 'styled-components';
import HobbyApi from '../../api/HobbyApi';
import CustomButton from '../../components/CustomButton';
import Icons from '../../components/Icons';
import config from '../../config';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import { Event } from '../../library/Event';
import useWindowSize from '../../utilities/resizeHook';
import __ from '../../utilities/__';
import FriendsList from './FriendsList';
import InvitationForm from './invitationForm';
import TeamAssignment from './teamAssignment';

const INVITATION_FORM_NAME = 'INVITATION_FORM';

const Teams = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const TeamRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: ${rem(14)};
  padding: ${rem(5)} 0;
`;
const TeamWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface OwnProps {
  event: Event;
  isCreator: boolean;
}

const mapStateToProps = (state: RootState) => ({});

const EventDetail: React.FC<ReturnType<typeof mapStateToProps> &
  OwnProps &
  RouteComponentProps<{}, {}> & { dispatch: CustomThunkDispatch }> = ({
  event,
  isCreator,
  dispatch,
  router: { push },
}) => {
  const resizeHook = useWindowSize();

  const [mobileLayout, setMobileLayout] = React.useState(
    window.innerWidth < 600,
  );

  const [invitationModalOpened, setInvitationModalOpened] = React.useState(
    false,
  );
  const [isInviting, setIsInviting] = React.useState(false);

  const [activeTeamAssignment, setActiveTeamAssignment] = React.useState<
    null | string
  >(null);

  React.useEffect(() => {
    if (resizeHook.innerWidth < 600) {
      setMobileLayout(true);
    } else {
      setMobileLayout(false);
    }
  }, [resizeHook.innerWidth]);

  const toggleInvitationModal = () => {
    setInvitationModalOpened(!invitationModalOpened);
  };

  const toggleTeamAssignmentModal = (teamId?: string) => {
    setActiveTeamAssignment(teamId || null);
  };

  const submitInvitationForm = async (values: { email: string }) => {
    setIsInviting(true);
    try {
      await HobbyApi.createEventInvitation(
        config.APP_SPACE,
        event._id,
        {},
        {
          email: values.email,
        },
      );
      toggleInvitationModal();
      dispatch(destroy(INVITATION_FORM_NAME));
    } catch (e) {
      if (e.details && e.details.name === 'PLAYER_ALREADY_INVITED') {
        alert(__('Hráč už bol do zápasu pozvaný'));
      } else {
        alert(__('Nastala neznáma chyba. Pozvánka nemohla byť odoslaná'));
      }
    } finally {
      setIsInviting(false);
    }
  };

  const onSubmitInvitationForm = (values: { email: string }) => {
    submitInvitationForm(values);
  };

  const team = (event.teams || []).find(i => i._id === activeTeamAssignment);

  return (
    <>
      {!event.closed && (
        <Segment
          raised
          header={
            <SegmentHeader size="s" withSeparator>
              {__('Zoznam tímov')}
            </SegmentHeader>
          }
        >
          <Teams>
            {(event.teams || []).map(t => (
              <TeamRow key={t._id}>
                <TeamWrapper>
                  <>
                    <div style={{ padding: `${rem(5)} ${rem(10)}` }}>
                      <Icons size={20} color="#ccc" name="users" />
                    </div>
                    &nbsp;{t.name}
                  </>
                </TeamWrapper>
                {isCreator &&
                  !event.closed &&
                  (event.players || []).length > 0 && (
                    <div style={{ textAlign: 'right' }}>
                      <CustomButton
                        onClick={() => {
                          toggleTeamAssignmentModal(t._id);
                        }}
                      >
                        {__('Spravovať hráčov')}
                      </CustomButton>
                    </div>
                  )}
              </TeamRow>
            ))}
          </Teams>
        </Segment>
      )}
      <Modal
        isOpen={!!activeTeamAssignment}
        handleClose={() => {
          toggleTeamAssignmentModal();
        }}
      >
        <ModalContent>
          <TeamAssignment event={event} team={team || null} />
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button
              onClick={() => {
                toggleTeamAssignmentModal();
              }}
            >
              {__('Zavrieť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
      {isCreator && (
        <>
          {mobileLayout ? (
            <div>
              {!event.closed && (
                <>
                  <Button primary block onClick={toggleInvitationModal}>
                    {__('Pozvať hráča')}
                  </Button>
                  <div style={{ height: rem(10) }} />
                </>
              )}
              <Button
                success
                block
                onClick={() => {
                  push(`/admin/event/${event._id}/protocol`);
                }}
              >
                {event.closed
                  ? __('Upraviť priebeh zápasu')
                  : __('Prejsť na priebeh zápasu')}
              </Button>
            </div>
          ) : (
            <div style={{ textAlign: 'right' }}>
              {!event.closed && (
                <>
                  <Button primary onClick={toggleInvitationModal}>
                    {__('Pozvať hráča')}
                  </Button>
                  &nbsp;
                </>
              )}
              <Button
                success
                onClick={() => {
                  push(`/admin/event/${event._id}/protocol`);
                }}
              >
                {event.closed
                  ? __('Upraviť priebeh zápasu')
                  : __('Prejsť na priebeh zápasu')}
              </Button>
            </div>
          )}
          <Modal
            size="xs"
            isOpen={invitationModalOpened}
            handleClose={toggleInvitationModal}
          >
            <Segment loading={isInviting}>
              <ModalContent>
                <Header size="xs">{__('Pozvať hráča')}</Header>
                <InvitationForm
                  form={INVITATION_FORM_NAME}
                  onSubmit={onSubmitInvitationForm}
                />
                <FriendsList
                  onInvite={i => {
                    submitInvitationForm(i);
                  }}
                />
              </ModalContent>
              <ModalActions>
                <div>&nbsp;</div>
                <div>
                  <Button onClick={toggleInvitationModal}>
                    {__('Zavrieť')}
                  </Button>
                  &nbsp;
                  <Button
                    primary
                    onClick={() => {
                      dispatch(submit(INVITATION_FORM_NAME));
                    }}
                  >
                    {__('Odoslať pozvánku')}
                  </Button>
                </div>
              </ModalActions>
            </Segment>
          </Modal>
        </>
      )}
    </>
  );
};

export default compose<React.FC<OwnProps>>(
  withRouter,
  connect(mapStateToProps),
)(EventDetail);
