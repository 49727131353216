import { createGlobalStyle } from 'styled-components';

export const global = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Barlow:300,400,500,600,700&display=swap&subset=latin-ext');
  #modal-root > div > div {
    z-index: 1000;
  }
  .leaflet-popup-content {
    margin: 0;
  }
  html, body, #root {
    margin: 0;
    padding: 0;
    min-height: 100%;
    width: 100%;
  }
  html {
    font-size: 100%;
    color: #555555;
  }
  * {
    box-sizing: border-box;
    font-family: 'Barlow', sans-serif !important;
  }
  label {
    font-weight: 200 !important;
    font-size: 14px !important;
    /* color: #aaa !important; */
    /* padding-bottom: 5px; */
}
  a {
    color: inherit;
    text-decoration: none;
  }
  button.gm-ui-hover-effect {
    display: none !important;
  }
  .gm-style-iw-d {
    max-width: 300px !important;
}
.gm-style-iw.gm-style-iw-c {
    max-width: 320px !important;
}
::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
`;
