import AuthorizationComponent from '@sportnet/ui/lib/Authorization';
import React, { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CoreApi from '../../api/CoreApi';
import HobbyApi from '../../api/HobbyApi';
import AdminLayout from '../../components/AdminLayout';
import { AdminLayoutConsumer } from '../../components/AdminLayout/context';
import config from '../../config';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { IAuthorizationResponse, RouteProps } from '../../library/App';
import { setAuthUser } from '../App/actions';

type OwnProps = {};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
  setAuthResponse: setAuthUser,
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReduxConnectProps &
  RouteProps;

const Authorization: React.FC<Props> = ({
  setAuthResponse,
  children,
  location: { pathname },
}) => {
  const handleAuthorized = useCallback(
    (response: IAuthorizationResponse) => {
      setAuthResponse(response);
      CoreApi.setToken(response.accessToken);
      HobbyApi.setToken(response.accessToken);
    },
    [setAuthResponse],
  );

  const handleLogout = useCallback(() => {
    window.location.href = '/events';
  }, []);

  return (
    <AuthorizationComponent
      appId={config.AUTH_CLIENT_ID}
      onAuthorized={handleAuthorized}
      scope="profile,messages,profile_picture"
      basePath={pathname}
      onAfterLogout={handleLogout}
    >
      <AdminLayoutConsumer>
        {props => {
          props.messageComponents = {
            title: '',
            content: '',
          };
          return <AdminLayout>{children}</AdminLayout>;
        }}
      </AdminLayoutConsumer>
    </AuthorizationComponent>
  );
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  memo,
)(Authorization);
