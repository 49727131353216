import * as React from 'react';
import { formatUserName } from 'sportnet-utilities';
import { useAsyncData } from 'ssr-service';
import HobbyApi from '../../api/HobbyApi';
import BasicTable from '../../components/BasicTable';
import CustomButton from '../../components/CustomButton';
import config from '../../config';
import Loading from '../../containers/Loading';
import { Event, Team } from '../../library/Event';
import { Player, Tournament } from '../../library/Tournament';
import __ from '../../utilities/__';

interface OwnProps {
  event: Event;
  tournament: Tournament;
  team: Team | null;
}

const TeamAssignment: React.FC<OwnProps> = ({ event, tournament, team }) => {
  const [isFetching, setIsFetching] = React.useState(true);
  const [nominations, setNominations] = React.useState<
    Array<{ _id: string; name: string }>
  >([]);
  const [playerIdsSubmitting, setPlayerIdsSubmitting] = React.useState<
    string[]
  >([]);

  useAsyncData(async () => {
    await fetchTeamNomination();
  }, [team]);

  const fetchTeamNomination = async () => {
    if (team) {
      setIsFetching(true);
      try {
        const {
          athletes = [],
        } = await HobbyApi.getTournamentEventTeamNomination(
          config.APP_SPACE,
          tournament._id,
          event._id,
          team._id,
        );
        setNominations(athletes.map(a => a.sportnetUser));
      } catch (e) {
        //
      } finally {
        setIsFetching(false);
      }
    }
  };

  const addToTeam = async (user: Player) => {
    if (team) {
      let currentIdsSubmitting = [...playerIdsSubmitting, user._id];
      setPlayerIdsSubmitting(currentIdsSubmitting);
      try {
        const athletes = [
          ...nominations.map(n => ({ sportnetUser: n })),
          { sportnetUser: { _id: user._id, name: formatUserName(user) } },
        ];
        await HobbyApi.updateTournamentEventTeamNomination(
          config.APP_SPACE,
          tournament._id,
          event._id,
          team._id,
          {},
          {
            teamId: team._id,
            athletes,
          },
        );
        await fetchTeamNomination();
      } catch (e) {
        alert(__('Hráča sa nepodarilo pridať do tímu.'));
      } finally {
        currentIdsSubmitting = [...playerIdsSubmitting].reduce((acc, i) => {
          if (i === user._id) {
            return acc;
          }
          return [...acc, i];
        }, []);
        setPlayerIdsSubmitting(currentIdsSubmitting);
      }
    }
  };

  const removeFromTeam = async (user: Player) => {
    if (team) {
      let currentIdsSubmitting = [...playerIdsSubmitting, user._id];
      setPlayerIdsSubmitting(currentIdsSubmitting);
      try {
        await HobbyApi.updateTournamentEventTeamNomination(
          config.APP_SPACE,
          tournament._id,
          event._id,
          team._id,
          {},
          {
            teamId: team._id,
            athletes: nominations
              .map(n => ({ sportnetUser: n }))
              .reduce((acc, a) => {
                if (a.sportnetUser._id === user._id) {
                  return acc;
                }
                return [...acc, a];
              }, []),
          },
        );
        await fetchTeamNomination();
      } catch (e) {
        alert(__('Hráča sa nepodarilo odstrániť z tímu.'));
      } finally {
        currentIdsSubmitting = [...playerIdsSubmitting].reduce((acc, i) => {
          if (i === user._id) {
            return acc;
          }
          return [...acc, i];
        }, []);
        setPlayerIdsSubmitting(currentIdsSubmitting);
      }
    }
  };

  if (isFetching) {
    return <Loading withoutWrapper />;
  }

  const teamData = (tournament.teams || []).find(
    t => !!team && t._id === team._id,
  );

  return (
    <BasicTable
      rows={teamData ? teamData.players || [] : []}
      rowKey="_id"
      renderRow={i => {
        const inTeam = nominations.find(n => n._id === i._id);
        return [
          formatUserName(i),
          <div style={{ textAlign: 'right' }} key={i._id}>
            {inTeam ? (
              <CustomButton
                loading={playerIdsSubmitting.includes(i._id)}
                disabled={playerIdsSubmitting.length > 0}
                danger
                onClick={() => {
                  removeFromTeam(i);
                }}
              >
                {__('Odstrániť z tímu')}
              </CustomButton>
            ) : (
              <CustomButton
                loading={playerIdsSubmitting.includes(i._id)}
                disabled={playerIdsSubmitting.length > 0}
                onClick={() => {
                  addToTeam(i);
                }}
              >
                {__('Pridať do tímu')}
              </CustomButton>
            )}
          </div>,
        ];
      }}
      compact
      raised={false}
      columns={[{ header: __('Meno a priezvisko') }, { header: '' }]}
    />
  );
};

export default TeamAssignment;
