import { commit, CommitError, getListParameters } from 'redux-list';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import {
  CustomThunkAction,
  ExtraArgumentType,
  RootState,
} from '../../configureStore';
import { NormalizedEntities, Pager } from '../../library/App';
import { Tournament } from '../../library/Tournament';
import normalizeEntities from '../../utilities/normalizeEntities';
import { replaceEntities } from '../App/actions';

const create = actionCreatorFactory('APP');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

interface TournamentsParameters {
  offset: number;
}

export const loadMeTournaments = createAsync<
  TournamentsParameters,
  Pick<NormalizedEntities<'tournaments'>, 'results'> & Pager
>(
  'GET_ME_TOURNAMENTS',
  async ({ offset }, dispatch, getState, { HobbyApi }) => {
    const response = await HobbyApi.getMeTournaments(config.APP_SPACE, {
      limit: config.DEFAULT_LIST_LIMIT,
      offset,
    });
    const { entities, results } = normalizeEntities(
      'tournaments',
      response.items || [],
    );
    dispatch(replaceEntities(entities));
    return {
      results,
      offset,
      total: response.total,
      limit: config.DEFAULT_LIST_LIMIT,
    };
  },
);

export const loadMeTournamentsList = (): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(config.ME_TOURNAMENTS_LIST_NAME)(
      getState(),
    );
    return dispatch(
      commit.action({
        listName: config.ME_TOURNAMENTS_LIST_NAME,
        load: async () => {
          try {
            const { results, offset, total } = await dispatch(
              loadMeTournaments.action(parameters as TournamentsParameters),
            );
            return {
              total,
              offset,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadAdminTournaments = createAsync<
  TournamentsParameters,
  Pick<NormalizedEntities<'tournaments'>, 'results'> & Pager
>(
  'GET_ADMIN_TOURNAMENTS',
  async ({ offset }, dispatch, getState, { HobbyApi }) => {
    const response = await HobbyApi.getCreatedTournaments(config.APP_SPACE, {
      limit: config.DEFAULT_LIST_LIMIT,
      offset,
    });
    const { entities, results } = normalizeEntities(
      'tournaments',
      response.items || [],
    );
    dispatch(replaceEntities(entities));
    return {
      results,
      offset,
      total: response.total,
      limit: config.DEFAULT_LIST_LIMIT,
    };
  },
);

export const loadAdminTournamentsList = (): CustomThunkAction<
  Promise<void>
> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(config.ADMIN_TOURNAMENTS_LIST_NAME)(
      getState(),
    );
    return dispatch(
      commit.action({
        listName: config.ADMIN_TOURNAMENTS_LIST_NAME,
        load: async () => {
          try {
            const { results, offset, total } = await dispatch(
              loadAdminTournaments.action(parameters as TournamentsParameters),
            );
            return {
              total,
              offset,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadTournamentById = createAsync<
  { tournamentId: string },
  Tournament
>(
  'GET_TOURNAMENT_BY_ID',
  async ({ tournamentId }, dispatch, getState, { HobbyApi }) => {
    dispatch(replaceEntities({ tournaments: {} }));
    const tournament = await HobbyApi.getTournament(
      config.APP_SPACE,
      tournamentId,
      {},
    );
    const { entities } = normalizeEntities('tournaments', [tournament]);
    dispatch(replaceEntities(entities));
    return tournament;
  },
);

export const loadTournamentInvitationById = createAsync<
  { tournamentId: string; invitationId: string },
  Tournament
>(
  'GET_TOURNAMENT_INVITATION_BY_ID',
  async ({ tournamentId, invitationId }, dispatch, getState, { HobbyApi }) => {
    dispatch(replaceEntities({ tournaments: {} }));
    const tournament = await HobbyApi.getTournamentInvitation(
      config.APP_SPACE,
      tournamentId,
      invitationId,
      {},
    );
    const { entities } = normalizeEntities('tournaments', [tournament]);
    dispatch(replaceEntities(entities));
    return tournament;
  },
);
