import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import * as React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import required from '../../utilities/required';
import __ from '../../utilities/__';

const TeamCreationForm: React.FC<{}> = () => {
  return (
    <Row>
      <Col xs={12}>
        <Field
          name="name"
          component={FormField}
          label={__('Názov tímu')}
          required
          validate={required}
        />
      </Col>
    </Row>
  );
};

export default compose(reduxForm({}))(TeamCreationForm);
