import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import * as React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Athlete } from '../../../../library/Event';
import required from '../../../../utilities/required';
import __ from '../../../../utilities/__';

interface OwnProps {
  players: Athlete[];
}

const isValidTime = (value: string | number) => {
  if (typeof value === 'number') {
    return null;
  }
  if (
    value &&
    !value.match(/^[0-9][0-9]:[0-5][0-9]$/g) &&
    !value.match(/^[0-9][0-9][0-9]:[0-5][0-9]$/g)
  ) {
    return __('Zadajte čas vo formáte 00:00');
  }
  return null;
};

const SubstitutionActionForm: React.FC<OwnProps> = ({ players }) => {
  return (
    <Row>
      <Col xs={12}>
        <Field
          component={FormField}
          placeholder={__('Zadajte čas vo formáte 00:00')}
          name="eventTime"
          label={__('Čas')}
          validate={[isValidTime, required]}
          required
          format={(time: number | string) => {
            if (typeof time === 'number') {
              const minutes = String(Math.floor((time || 0) / 60)).padStart(
                2,
                '0',
              );
              const seconds = String((time || 0) % 60).padStart(2, '0');
              return `${minutes}:${seconds}`;
            }
            return time;
          }}
          parse={(value: string) => {
            const isInvalid = isValidTime(value);
            if (isInvalid) {
              return value;
            }
            const arr = value.split(':');
            let seconds = Number(arr[0]) * 60 + Number(arr[1]);
            if (Number.isNaN(seconds)) {
              seconds = 0;
            }
            return seconds;
          }}
        />
      </Col>
      <Col xs={12}>
        <Field
          component={FormField}
          name="player"
          label={__('Striedaný hráč')}
          disabled
          format={(i: { name: string }) => i && i.name}
        />
      </Col>
      <Col xs={12}>
        <Field
          component={FormField}
          name="replacement"
          label={__('Striedajúci hráč')}
          type="select"
          format={(i: { _id: string; name: string } | string) => {
            if (typeof i === 'undefined') {
              return '';
            } else if (typeof i === 'string') {
              return i;
            }
            return i._id;
          }}
          parse={(i: string) => {
            const user = players.find(p => p.sportnetUser._id === i);
            if (user) {
              return user.sportnetUser;
            }
            return '';
          }}
        >
          <option hidden value="" />
          {players.map(p => (
            <option key={p.sportnetUser._id} value={p.sportnetUser._id}>
              {p.sportnetUser.name}
            </option>
          ))}
        </Field>
      </Col>
    </Row>
  );
};

export default compose<any>(reduxForm<{}, OwnProps>({}))(
  SubstitutionActionForm,
);
