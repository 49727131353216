const GOOGLE_API = `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_API_KEY}&language=sk`;

async function handleUrl(url: string) {
  const response = await fetch(url).catch(() =>
    Promise.reject(new Error('Error fetching data')),
  );

  const json = await response.json().catch(() => {
    console.log('Error parsing server response');
    return Promise.reject(new Error('Error parsing server response'));
  });

  if (json.status === 'OK') {
    return json;
  }
  console.log(`Server returned status code ${json.status}`, true);
  return Promise.reject(
    new Error(`Server returned status code ${json.status}`),
  );
}

export default {
  async fromLatLng(lat: number, lng: number) {
    if (!lat || !lng) {
      console.log('Provided coordinates are invalid', true);
      return Promise.reject(new Error('Provided coordinates are invalid'));
    }

    const latLng = `${lat},${lng}`;
    const url = `${GOOGLE_API}&latlng=${encodeURI(latLng)}`;

    return handleUrl(url);
  },

  async fromAddress(
    address: string,
  ): Promise<{
    results: Array<{
      formatted_address: string;
      geometry: {
        location: { lat: number; lng: number };
      };
      address_components: Array<{ types: string[]; long_name: string }>;
    }>;
  }> {
    if (!address) {
      console.log('Provided address is invalid', true);
      return Promise.reject(new Error('Provided address is invalid'));
    }

    const url = `${GOOGLE_API}&address=${encodeURI(address)}`;

    return handleUrl(url);
  },
};
