import * as React from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import AdminLayoutContext from '../../components/AdminLayout/context';
import { Content } from '../../components/Layout';
import Tabber from '../../components/Tabber';
import __ from '../../utilities/__';

const Tournaments: React.FC<WithRouterProps> = ({
  location: { pathname },
  children,
}) => {
  const {
    setHeaderComponents,
    headerComponents,
    messageComponents,
    setMessageComponents,
  } = React.useContext(AdminLayoutContext);
  const componentHeader = __('Turnaje');
  const componentSubHeader = __('Zoznam turnajov');

  const messageTitle = __('Čo je to turnaj?');
  const messageContent = __(
    'Turnaj je vhodné založiť v prípade, že plánujete organizovať zápasy medzi sebou pravidelnejšie. Hrať sa môžu na viacerých miestach a medzi viacerými tímami. Počas trvania turnaja sa automaticky generuje štatistika hráčov a výsledková tabuľka tímov.',
  );

  if (
    headerComponents.header !== componentHeader ||
    headerComponents.subHeader !== componentSubHeader
  ) {
    setHeaderComponents(componentHeader, componentSubHeader);
  }
  if (
    messageComponents.title !== messageTitle ||
    messageComponents.content !== messageContent
  ) {
    setMessageComponents(messageTitle, messageContent);
  }

  return (
    <div>
      <Tabber
        pathname={pathname}
        items={[
          { label: __('Účastník'), url: '/admin/tournaments/me' },
          { label: __('Organizátor'), url: '/admin/tournaments/admin' },
        ]}
      />
      <Content>{children}</Content>
    </div>
  );
};

export default withRouter(Tournaments);
