import { ACCESS_TOKEN_COOKIE_NAME } from '@sportnet/ui/lib/Authorization';
import Button from '@sportnet/ui/lib/Button';
import { ContentLoader } from '@sportnet/ui/lib/Loader';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import Editor from 'content/lib/editor';
import defaultWidgets from 'content/lib/editor/defaultWidgets';
import { format } from 'date-fns';
import * as Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { change, formValueSelector, submit } from 'redux-form';
import { useAsyncData } from 'ssr-service';
import { withTheme } from 'styled-components';
import HobbyApi from '../../api/HobbyApi';
import AdminLayoutContext from '../../components/AdminLayout/context';
import { Content } from '../../components/Layout';
import config from '../../config';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import { SportGround } from '../../library/SportGround';
import { Tournament } from '../../library/Tournament';
import { ITheme } from '../../theme/theme';
import __ from '../../utilities/__';
import { Actions } from '../Events/styledComponents';
import Form from './form';

const FORM_NAME = 'TOURNAMENT_FORM';

const availableWidgets = defaultWidgets.filter((i: any) =>
  ['text', 'table', 'hr', 'photo', 'video', 'photogallery'].includes(i.type),
);

const mapStateToProps = (state: RootState) => {
  const selector = formValueSelector(FORM_NAME);
  return {
    isPublic: selector(state, 'public') || false,
    widgets: selector(state, 'description.widgets'),
  };
};

const TournamentEdit: React.FC<{ dispatch: CustomThunkDispatch } & ReturnType<
  typeof mapStateToProps
> & {
    theme: ITheme;
  } & RouteComponentProps<{ tournamentId: string }, {}>> = ({
  isPublic,
  router: { push },
  params: { tournamentId },
  theme,
  dispatch,
  widgets,
}) => {
  const [sportGrounds, setSportGrounds] = React.useState<SportGround[]>([]);
  const [sportGroundQuery, setSportGroundQuery] = React.useState('');
  const [sportGroundsAreFetching, setSportGroundFetchingState] = React.useState(
    false,
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState<Tournament | null>(
    null,
  );

  useAsyncData(async () => {
    await loadSportGrounds();
    await loadInitialValues();
  }, [sportGroundQuery]);

  const loadInitialValues = async () => {
    try {
      const res = await HobbyApi.getTournament('dajmespolugol', tournamentId);
      setInitialValues(res);
    } catch (e) {
      alert(__('Nepodarilo sa získať údaje o turnaji'));
    }
  };

  const loadSportGrounds = async () => {
    const params: { q?: string } = {};
    if (sportGroundQuery) {
      params.q = sportGroundQuery;
    }
    setSportGroundFetchingState(true);
    try {
      const { items = [] } = await HobbyApi.getSportGrounds(
        config.APP_SPACE,
        params,
      );
      setSportGrounds(items);
    } catch (e) {
      //
    } finally {
      setSportGroundFetchingState(false);
    }
  };

  const submitForm = async (values: {
    dateFrom: string;
    dateTo: string;
    name: string;
    level: { value: string };
    description: {
      widgets: any[];
    };
    registrationDueDate?: string;
    sportGround: { value: string };
    public?: boolean;
    rules?: {
      gender?: { value: string };
    };
  }) => {
    setIsSubmitting(true);
    const rules: {
      sport_sector: string;
      gender?: string;
    } = { sport_sector: 'futbal' };
    if (values.rules && values.rules.gender) {
      rules.gender = values.rules.gender.value;
    }
    const data: {
      name: string;
      dateFrom: string;
      dateTo: string;
      level: string;
      description: {
        widgets: any[];
      };
      registrationDueDate?: string;
      sportGround: {
        _id: string;
      };
      public?: boolean;
      rules: {
        sport_sector: string;
        gender?: string;
      };
    } = {
      name: values.name,
      description: values.description,
      dateFrom: format(new Date(values.dateFrom), 'YYYY-MM-DD'),
      dateTo: format(new Date(values.dateTo), 'YYYY-MM-DD'),
      sportGround: { _id: values.sportGround.value },
      rules,
      level: values.level.value,
      public: values.public || false,
      registrationDueDate: values.registrationDueDate
        ? format(new Date(values.registrationDueDate), 'YYYY-MM-DD')
        : undefined,
    };
    try {
      await HobbyApi.updateTournament(config.APP_SPACE, tournamentId, {}, data);
      push(`/admin/tournament/${tournamentId}`);
    } catch (e) {
      // console.error(e);
      alert(__('Turnaj sa nepodarilo uložiť'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const transformValues = (values: Tournament) => {
    return {
      ...values,
      level: { label: values.level, value: values.level },
      sportGround: {
        label: values.sportGround.name,
        value: values.sportGround._id,
      },
      rules: {
        ...(values.rules || {}),
        ...(values.rules &&
          values.rules.gender &&
          values.rules.gender === 'M' && {
            gender: {
              label: __('Muži'),
              value: 'M',
            },
          }),
        ...(values.rules &&
          values.rules.gender &&
          values.rules.gender === 'F' && {
            gender: {
              label: __('Ženy'),
              value: 'F',
            },
          }),
      },
    };
  };

  const { setHeaderComponents, headerComponents } = React.useContext(
    AdminLayoutContext,
  );
  const componentHeader = __('Turnaje');
  const componentSubHeader = __('Úprava turnaja');

  if (
    headerComponents.header !== componentHeader ||
    headerComponents.subHeader !== componentSubHeader
  ) {
    setHeaderComponents(componentHeader, componentSubHeader);
  }

  if (!initialValues) {
    return <ContentLoader theme={theme} />;
  }

  return (
    <Content>
      <Form
        form={FORM_NAME}
        onSubmit={submitForm}
        isSubmitting={isSubmitting}
        sportGrounds={sportGrounds}
        setSportGroundQuery={setSportGroundQuery}
        sportGroundsAreFetching={sportGroundsAreFetching}
        loadSportGrounds={loadSportGrounds}
        isPublic={isPublic}
        initialValues={transformValues(initialValues)}
      />
      <Segment raised header={<SegmentHeader>{__('Popis')}</SegmentHeader>}>
        {widgets && (
          <Editor
            onChange={(e: any) =>
              dispatch(change(FORM_NAME, 'description.widgets', e))
            }
            availableWidgets={availableWidgets}
            initialValue={widgets || []}
            contentDivider="default"
            appId="hobby"
            token={Cookies.get(ACCESS_TOKEN_COOKIE_NAME) || ''}
          />
        )}
      </Segment>
      <Actions>
        <Button
          primary
          type="button"
          loading={isSubmitting}
          onClick={() => {
            dispatch(submit(FORM_NAME));
          }}
        >
          {__('Uložiť')}
        </Button>
      </Actions>
    </Content>
  );
};

export default compose(connect(mapStateToProps), withTheme)(TournamentEdit);
