import LoaderDefault from '@sportnet/ui/lib/Loader';
import React from 'react';
import EntryAnimation from '../../components/EntryAnimation';
import { CenteredContentWrapper, PageWrapper } from '../../components/Layout';

const Loading: React.FC<{ withoutWrapper?: boolean }> = ({
  withoutWrapper,
}) => {
  const content = (
    <CenteredContentWrapper>
      <EntryAnimation key={'loading'}>
        <LoaderDefault size="xl" />
      </EntryAnimation>
    </CenteredContentWrapper>
  );
  if (withoutWrapper) {
    return content;
  }
  return <PageWrapper>{content}</PageWrapper>;
};

export default Loading;
