import { format } from 'date-fns';
import { em, rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import Icon from '../../components/Icons';
import { Event } from '../../library/Event';
import { Tournament } from '../../library/Tournament';
import { ITheme } from '../../theme/theme';

const Wrapper = styled.div`
  padding: ${rem(10)};
  width: ${rem(280)};
  a {
    color: inherit;
  }
`;
const PlaceTooltipHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${em(30)};
`;
const PlaceName = styled.div`
  font-weight: 400;
  font-size: ${em(20)};
`;
const PlaceTooltipContent = styled.div``;
const PlaceTooltipItems = styled.div`
  max-height: ${rem(165)};
  overflow-y: scroll;
`;
const PlaceTooltipItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${em(15)};
  border: ${em(1)} solid #f1f1f1;
  margin-bottom: ${em(5)};
  cursor: pointer;
  :hover {
    background: #fafafa;
  }
  & > div {
    width: 50%;
    display: flex;
    align-items: center;
  }
`;

interface OwnProps {
  selectedPlace: Array<Tournament | Event>;
  theme: ITheme;
}

const PlaceTooltip: React.FC<OwnProps> = ({ selectedPlace, theme }) => {
  return (
    <Wrapper>
      <PlaceTooltipHeader>
        <PlaceName>{selectedPlace[0].sportGround.name}</PlaceName>
      </PlaceTooltipHeader>
      <PlaceTooltipContent>
        <PlaceTooltipItems>
          {selectedPlace.map(p => {
            // event
            if ('startDate' in p) {
              return (
                <Link to={`/admin/event/${p._id}`}>
                  <PlaceTooltipItem
                    className="tooltip-item"
                    id={p._id}
                    data-type="event"
                    key={p._id}
                  >
                    <div>
                      <Icon
                        color={theme.color.fadedText}
                        name="calendar"
                        size="l"
                        theme={theme}
                      />
                      <div style={{ paddingLeft: em(15) }}>
                        {format(new Date(p.startDate), 'DD.MM.YYYY')}
                      </div>
                    </div>
                    <div>
                      <Icon
                        color={theme.color.fadedText}
                        name="clock"
                        size="l"
                        theme={theme}
                      />
                      <div style={{ paddingLeft: em(15) }}>
                        {format(new Date(p.startDate), 'HH:mm')}
                      </div>
                    </div>
                  </PlaceTooltipItem>
                </Link>
              );
            }
            // turnaj
            return (
              <Link key={p._id} to={`/admin/tournament/${p._id}`}>
                <PlaceTooltipItem
                  className="tooltip-item"
                  id={p._id}
                  data-type="tournament"
                >
                  <div style={{ width: '100%' }}>
                    <Icon
                      color={theme.color.fadedText}
                      name="trophy"
                      size="l"
                      theme={theme}
                    />
                    <div style={{ paddingLeft: em(15) }}>
                      <span style={{ color: theme.color.fadedText }}>
                        {p.name}
                      </span>
                      <br />
                      <span>
                        {format(new Date(p.dateFrom), 'DD.MM.YYYY')} -{' '}
                        {format(new Date(p.dateTo), 'DD.MM.YYYY')}
                      </span>
                    </div>
                  </div>
                </PlaceTooltipItem>
              </Link>
            );
          })}
        </PlaceTooltipItems>
      </PlaceTooltipContent>
    </Wrapper>
  );
};

export default PlaceTooltip;
