import Icon from '@sportnet/ui/lib/Icon';
import { Loader } from '@sportnet/ui/lib/Loader';
import { getMonth, getYear } from 'date-fns';
import * as React from 'react';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import { ITheme } from '../../theme/theme';
import {
  Arrow,
  CalendarItem,
  EventsCount,
  Month,
  Top,
  Wrapper,
  YearPicker,
} from './styledComponents';

const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MÁJ',
  'JÚN',
  'JÚL',
  'AUG',
  'SEP',
  'OKT',
  'NOV',
  'DEC',
];

type OwnProps = {
  closeAction: () => void;
  changeDateAction: (month: number, year: number) => void;
  activeDate: Date;
  isFetching: boolean;
  countByMonthAndYear: { [key: string]: number };
};

const Calendar: React.FC<{ theme: ITheme } & OwnProps> = ({
  theme,
  closeAction,
  changeDateAction,
  activeDate,
  isFetching,
  countByMonthAndYear,
}) => {
  const [yearPickerValue, setYearPickerValue] = React.useState(
    getYear(new Date()),
  );
  const [prevFetchingState, setPrevFetchingState] = React.useState(false);

  React.useEffect(() => {
    if (isFetching !== prevFetchingState && !isFetching) {
      closeAction();
    }
    setPrevFetchingState(isFetching);
  }, [isFetching, prevFetchingState, closeAction]);

  return (
    <>
      <Top>
        <YearPicker>
          <Arrow
            left
            onClick={() => {
              setYearPickerValue(yearPickerValue - 1);
            }}
          >
            <Icon size="l" name="arrow-down" color={theme.color.base} />
          </Arrow>
          {yearPickerValue}
          <Arrow
            right
            onClick={() => {
              setYearPickerValue(yearPickerValue + 1);
            }}
          >
            <Icon size="l" name="arrow-down" color={theme.color.base} />
          </Arrow>
        </YearPicker>
        <div style={{ cursor: 'pointer' }} onClick={closeAction}>
          <Icon name="close" size="l" />
        </div>
      </Top>
      <Wrapper>
        {months.map((m, mIdx) => {
          const isActive =
            getMonth(activeDate) === mIdx &&
            getYear(activeDate) === yearPickerValue;
          const count =
            countByMonthAndYear[
              `${yearPickerValue}-${String(mIdx + 1).padStart(2, '0')}`
            ] || 0;
          return (
            <CalendarItem
              key={m}
              disabled={count === 0}
              active={isActive}
              onClick={() => {
                if (count > 0) {
                  changeDateAction(mIdx, yearPickerValue);
                }
              }}
            >
              {isActive && isFetching ? (
                <Loader theme={theme} color="#fff" size="l" />
              ) : (
                <>
                  <Month>{m}</Month>
                  <EventsCount>{count}</EventsCount>
                </>
              )}
            </CalendarItem>
          );
        })}
      </Wrapper>
    </>
  );
};

export default compose(withTheme)(Calendar);
