import __ from './__';

export const customRequired = (errorMessage: string) => (
  value: string,
): string | null => {
  if (value) {
    return null;
  }
  return errorMessage;
};

export default customRequired(__('Pole je povinné'));
