import styled from '@sportnet/ui/lib/Themes/styled-components';
import { em, rem } from 'polished';
import * as React from 'react';
import { css } from 'styled-components';
import Icons, { IIconName } from '../Icons';

export const InfoBlocks = styled.div`
  flex-wrap: wrap;
  display: flex;
`;

const InfoBlock = styled.div<{ fullWidth: boolean; withBorder: boolean }>`
  display: flex;
  align-items: center;
  min-height: ${rem(44)};
  width: 100%;
  ${({ withBorder }) =>
    withBorder &&
    css`
      border-bottom: ${rem(1)} solid #eee;
    `}
  @media screen and (min-width: 600px) {
    ${({ fullWidth }) =>
      !fullWidth &&
      css`
        width: 50%;
      `}
  }
`;
const IconWrapper = styled.div`
  padding: ${rem(10)};
  display: flex;
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${em(14)};
  justify-content: center;
  flex: 1;
`;
const InfoWrapperHeader = styled.div`
  color: ${({ theme }) => theme.color.fadedText};
  font-size: ${em(14)};
`;

export const createInfoBlock = (
  iconName: IIconName | null,
  value: React.ReactNode,
  header?: string,
  fullWidth?: boolean,
  withBorder?: boolean,
) => (
  <InfoBlock fullWidth={!!fullWidth} withBorder={false}>
    {iconName && (
      <IconWrapper>
        <Icons size={20} name={iconName} color="#ccc" />
      </IconWrapper>
    )}
    <InfoWrapper>
      {header && <InfoWrapperHeader>{header}</InfoWrapperHeader>}
      <div style={{ width: '100%' }}>{value}</div>
    </InfoWrapper>
  </InfoBlock>
);
