import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import AdminLayoutContext from '../../components/AdminLayout/context';
import { RootState } from '../../configureStore';
import { authUserProfileSelector } from '../../containers/App/selectors';
import { loadTournamentEventById } from '../../containers/Events/actions';
import { eventByIdSelector } from '../../containers/Events/selectors';
import Loading from '../../containers/Loading';
import { loadTournamentById } from '../../containers/Tournaments/actions';
import { tournamentByIdSelector } from '../../containers/Tournaments/selectors';
import { ITheme } from '../../theme/theme';
import __ from '../../utilities/__';
import Detail from './detail';

const mapDispatchToProps = {
  loadEvent: thunkToAction(loadTournamentEventById.action),
  loadTournament: thunkToAction(loadTournamentById.action),
};

type Props = { theme: ITheme } & typeof mapDispatchToProps &
  RouteComponentProps<{ eventId: string; tournamentId: string }, {}>;

const mapStateToProps = (state: RootState, props: Props) => ({
  event: eventByIdSelector(props.params.eventId)(state),
  tournament: tournamentByIdSelector(props.params.tournamentId)(state),
  authUser: authUserProfileSelector(state),
});

const EventDetail: React.FC<ReturnType<typeof mapStateToProps> & Props> = ({
  router: { push },
  authUser,
  params: { eventId, tournamentId },
  loadEvent,
  loadTournament,
  tournament,
  event,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);

  useAsyncData(async () => {
    await loadEventData();
  }, [eventId]);

  const loadEventData = async () => {
    setIsFetching(true);
    try {
      await loadEvent({ eventId, tournamentId });
      await loadTournament({ tournamentId });
    } catch (e) {
      if (authUser) {
        push('/admin/events/me');
      } else {
        push('/');
      }
    } finally {
      setIsFetching(false);
    }
  };

  const { setHeaderComponents, headerComponents } = React.useContext(
    AdminLayoutContext,
  );
  const componentHeader = __('Turnaje');
  const componentSubHeader = __('Detail zápasu');

  if (
    headerComponents.header !== componentHeader ||
    headerComponents.subHeader !== componentSubHeader
  ) {
    setHeaderComponents(componentHeader, componentSubHeader);
  }

  if (!event || isFetching || !authUser) {
    return <Loading />;
  }
  const isCreator = event.createdBy && event.createdBy._id === authUser._id;
  return (
    <Detail tournament={tournament} event={event} isCreator={!!isCreator} />
  );
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EventDetail);
