import { em, rem } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Box = styled('div')<{ primary?: boolean; clickable?: boolean }>`
  background: ${({ primary, theme }) =>
    primary ? theme.color.primary : '#fff'};
  height: ${rem(54)};
  padding: ${em(15)};
  ${({ clickable }) => {
    if (clickable) {
      return css`
        transition: 0.2s all linear;
        cursor: pointer;
      `;
    }
    return css``;
  }}
  display: flex;
  border-radius: ${em(40)};
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 ${em(20)} 0;
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  display: flex;
  width: auto;
  justify-content: space-between;
  z-index: 1000;
`;

export const RightTop = styled(AbsoluteWrapper)`
  top: 0;
  right: 0;
  margin: ${em(10)} ${em(10)} 0 0;
`;

export const LeftBottom = styled(AbsoluteWrapper)`
  bottom: 0;
  left: 0;
  ${Box} {
    margin: 0 0 ${em(10)} ${em(10)};
  }
`;

export const RightBottom = styled(AbsoluteWrapper)`
  bottom: 0;
  right: 0;
  ${Box} {
    margin: 0 ${em(10)} ${em(10)} 0;
  }
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

type RadioProps = {
  color: string;
  checked: boolean;
  disabled?: boolean;
};

export const Radio = styled.div<RadioProps>`
  width: ${rem(16)};
  height: ${rem(16)};
  border-radius: 50%;
  background: white;
  position: relative;
  border: ${({ color, disabled, theme }) =>
    `2px solid ${disabled ? theme.color.fadedText : color}`};
  ${({ checked, disabled, color }) => {
    if (checked && !disabled) {
      return css`
        &::after {
          content: '';
          position: absolute;
          background: ${color};
          width: ${rem(10)};
          height: ${rem(10)};
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      `;
    }
    return css``;
  }}
`;
export const LegendLabel = styled.div`
  font-size: ${rem(13)};
  font-weight: 200;
  padding-left: ${rem(10)};
`;
