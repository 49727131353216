/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class HobbyApi
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class HobbyApi {
  protected baseUrl: string = '';
  protected token: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setToken(token: string) {
    this.token = token;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      return response.json();
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   *
   * @method
   * @name HobbyApi#getSportGrounds
   */
  getSportGrounds(
    appSpace: 'dajmespolugol',
    parameters: {
      q?: string;
    } = {},
  ): Promise<{
    items?: Array<{
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    }>;
  }> {
    let path = '/admin/{appSpace}/sportgrounds';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#createSportGround
   */
  createSportGround(
    appSpace: 'dajmespolugol',
    parameters: {} = {},
    body: {
      sport: string;

      street: string;

      number?: string;

      zip: string;

      city: string;

      country: string;

      geo: {
        lat: number;

        lng: number;
      };
    },
  ): Promise<{
    _id: string;

    name: string;

    sportGroundType: string;

    additionalData?: {};

    geo: {
      coordinates: Array<number>;
    };
  }> {
    let path = '/admin/{appSpace}/sportgrounds';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getCreatedEvents
   */
  getCreatedEvents(
    appSpace: 'dajmespolugol',
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number | null;

    items: Array<{
      _id: string;

      startDate: string;

      sportGround: {
        _id: string;

        name: string;

        sportGroundType: string;

        additionalData?: {};

        geo: {
          coordinates: Array<number>;
        };
      };

      rules?: {
        gender?: string;

        sport_sector?: string;
      };

      resultsTable?: {
        results: Array<{
          team: {
            _id: string;

            name: string;
          };

          stats: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };
        }>;

        players: Array<{
          _id: string;

          teamId: string;

          name: string;

          stats: {
            match_starts: number;

            match_appearances: number;

            goals: number;

            assists: number;

            yellow_cards: number;

            red_cards: number;

            substitutions: number;

            own_goals: number;

            minutes: number;
          };
        }>;
      };

      invited?: boolean;

      played?: boolean;

      closed?: boolean;

      invitations?: Array<string>;

      teams?: Array<{
        _id: string;

        name: string;

        additionalProperties: {
          homeaway: 'home' | 'away';
        };
      }>;

      players?: Array<{
        _id: string;
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/events';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 20;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#createEvent
   */
  createEvent(
    appSpace: 'dajmespolugol',
    parameters: {} = {},
    body: {
      startDate: string;

      surface: string;

      level: string;

      description: {
        widgets: Array<{}>;
      };

      registrationDueDate?: string;

      sportGround: {};

      public?: boolean;

      rules: {
        sport_sector: string;

        gender?: string;
      };

      capacity: number;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    tournamentId: string | null;

    surface: string;

    public?: boolean;

    matchId: string;

    season: {
      _id: string;

      name: string;
    };

    level: string;

    capacity: number;

    description: {
      widgets: Array<{}>;
    };

    registrationDueDate: string;

    players?: Array<{
      _id: string;

      name: string;

      surname: string;
    }>;

    teams: Array<{
      _id: string;

      name: string;

      displayName: string;

      gender?: string;

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    startDate: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    nominations: Array<{
      teamId: string;

      athletes: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;

      crew: {};

      closed?: boolean;
    }>;

    protocol?: {
      events?: Array<{
        type?: string;

        eventType: string;

        team: string;

        eventTime?: string;

        reason?: string;

        player?: {
          _id: string;

          name: string;
        };

        replacement?: {
          _id: string;

          name: string;
        };

        assist?: {
          _id: string;

          name: string;
        };
      }>;
    };

    rules: {
      gender?: string;

      sport_sector: string;
    };

    closed?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };
      }>;

      players: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts: number;

          match_appearances: number;

          goals: number;

          assists: number;

          yellow_cards: number;

          red_cards: number;

          substitutions: number;

          own_goals: number;

          minutes: number;
        };
      }>;
    };
  }> {
    let path = '/admin/{appSpace}/events';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getEvent
   */
  getEvent(
    appSpace: 'dajmespolugol',
    eventId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    tournamentId: string | null;

    surface: string;

    public?: boolean;

    matchId: string;

    season: {
      _id: string;

      name: string;
    };

    level: string;

    capacity: number;

    description: {
      widgets: Array<{}>;
    };

    registrationDueDate: string;

    players?: Array<{
      _id: string;

      name: string;

      surname: string;
    }>;

    teams: Array<{
      _id: string;

      name: string;

      displayName: string;

      gender?: string;

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    startDate: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    nominations: Array<{
      teamId: string;

      athletes: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;

      crew: {};

      closed?: boolean;
    }>;

    protocol?: {
      events?: Array<{
        type?: string;

        eventType: string;

        team: string;

        eventTime?: string;

        reason?: string;

        player?: {
          _id: string;

          name: string;
        };

        replacement?: {
          _id: string;

          name: string;
        };

        assist?: {
          _id: string;

          name: string;
        };
      }>;
    };

    rules: {
      gender?: string;

      sport_sector: string;
    };

    closed?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };
      }>;

      players: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts: number;

          match_appearances: number;

          goals: number;

          assists: number;

          yellow_cards: number;

          red_cards: number;

          substitutions: number;

          own_goals: number;

          minutes: number;
        };
      }>;
    };
  }> {
    let path = '/admin/{appSpace}/events/{eventId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#updateEvent
   */
  updateEvent(
    appSpace: 'dajmespolugol',
    eventId: string,
    parameters: {} = {},
    body: {
      startDate?: string;

      sportGround?: {
        _id: string;
      };

      public?: boolean;

      protocol?: {
        events?: Array<{
          type?: string;

          eventType: string;

          team: string;

          eventTime?: string;

          reason?: string;

          player?: {
            _id: string;

            name: string;
          };

          replacement?: {
            _id: string;

            name: string;
          };

          assist?: {
            _id: string;

            name: string;
          };
        }>;
      };

      closed?: boolean;

      rules?: {
        sport_sector: string;

        gender?: string;
      };

      capacity?: number;

      registrationDueDate?: string;

      surface?: string;

      level?: string;

      description?: {
        widgets: Array<{}>;
      };
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    tournamentId: string | null;

    surface: string;

    public?: boolean;

    matchId: string;

    season: {
      _id: string;

      name: string;
    };

    level: string;

    capacity: number;

    description: {
      widgets: Array<{}>;
    };

    registrationDueDate: string;

    players?: Array<{
      _id: string;

      name: string;

      surname: string;
    }>;

    teams: Array<{
      _id: string;

      name: string;

      displayName: string;

      gender?: string;

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    startDate: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    nominations: Array<{
      teamId: string;

      athletes: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;

      crew: {};

      closed?: boolean;
    }>;

    protocol?: {
      events?: Array<{
        type?: string;

        eventType: string;

        team: string;

        eventTime?: string;

        reason?: string;

        player?: {
          _id: string;

          name: string;
        };

        replacement?: {
          _id: string;

          name: string;
        };

        assist?: {
          _id: string;

          name: string;
        };
      }>;
    };

    rules: {
      gender?: string;

      sport_sector: string;
    };

    closed?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };
      }>;

      players: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts: number;

          match_appearances: number;

          goals: number;

          assists: number;

          yellow_cards: number;

          red_cards: number;

          substitutions: number;

          own_goals: number;

          minutes: number;
        };
      }>;
    };
  }> {
    let path = '/admin/{appSpace}/events/{eventId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#deleteEvent
   */
  deleteEvent(
    appSpace: 'dajmespolugol',
    eventId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path = '/admin/{appSpace}/events/{eventId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#copyEvent
   */
  copyEvent(
    appSpace: 'dajmespolugol',
    eventId: string,
    parameters: {} = {},
    body: {
      startDate?: string;

      sportGround?: {
        _id: string;
      };

      public?: boolean;

      protocol?: {
        events?: Array<{
          type?: string;

          eventType: string;

          team: string;

          eventTime?: string;

          reason?: string;

          player?: {
            _id: string;

            name: string;
          };

          replacement?: {
            _id: string;

            name: string;
          };

          assist?: {
            _id: string;

            name: string;
          };
        }>;
      };

      closed?: boolean;

      rules?: {
        sport_sector: string;

        gender?: string;
      };

      capacity?: number;

      registrationDueDate?: string;

      surface?: string;

      level?: string;

      description?: {
        widgets: Array<{}>;
      };
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    tournamentId: string | null;

    surface: string;

    public?: boolean;

    matchId: string;

    season: {
      _id: string;

      name: string;
    };

    level: string;

    capacity: number;

    description: {
      widgets: Array<{}>;
    };

    registrationDueDate: string;

    players?: Array<{
      _id: string;

      name: string;

      surname: string;
    }>;

    teams: Array<{
      _id: string;

      name: string;

      displayName: string;

      gender?: string;

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    startDate: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    nominations: Array<{
      teamId: string;

      athletes: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;

      crew: {};

      closed?: boolean;
    }>;

    protocol?: {
      events?: Array<{
        type?: string;

        eventType: string;

        team: string;

        eventTime?: string;

        reason?: string;

        player?: {
          _id: string;

          name: string;
        };

        replacement?: {
          _id: string;

          name: string;
        };

        assist?: {
          _id: string;

          name: string;
        };
      }>;
    };

    rules: {
      gender?: string;

      sport_sector: string;
    };

    closed?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };
      }>;

      players: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts: number;

          match_appearances: number;

          goals: number;

          assists: number;

          yellow_cards: number;

          red_cards: number;

          substitutions: number;

          own_goals: number;

          minutes: number;
        };
      }>;
    };
  }> {
    let path = '/admin/{appSpace}/events/{eventId}/copy';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#joinPublicEvent
   */
  joinPublicEvent(
    appSpace: 'dajmespolugol',
    eventId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    tournamentId: string | null;

    surface: string;

    public?: boolean;

    matchId: string;

    season: {
      _id: string;

      name: string;
    };

    level: string;

    capacity: number;

    description: {
      widgets: Array<{}>;
    };

    registrationDueDate: string;

    players?: Array<{
      _id: string;

      name: string;

      surname: string;
    }>;

    teams: Array<{
      _id: string;

      name: string;

      displayName: string;

      gender?: string;

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    startDate: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    nominations: Array<{
      teamId: string;

      athletes: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;

      crew: {};

      closed?: boolean;
    }>;

    protocol?: {
      events?: Array<{
        type?: string;

        eventType: string;

        team: string;

        eventTime?: string;

        reason?: string;

        player?: {
          _id: string;

          name: string;
        };

        replacement?: {
          _id: string;

          name: string;
        };

        assist?: {
          _id: string;

          name: string;
        };
      }>;
    };

    rules: {
      gender?: string;

      sport_sector: string;
    };

    closed?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };
      }>;

      players: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts: number;

          match_appearances: number;

          goals: number;

          assists: number;

          yellow_cards: number;

          red_cards: number;

          substitutions: number;

          own_goals: number;

          minutes: number;
        };
      }>;
    };
  }> {
    let path = '/admin/{appSpace}/events/{eventId}/join';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#createEventInvitation
   */
  createEventInvitation(
    appSpace: 'dajmespolugol',
    eventId: string,
    parameters: {} = {},
    body: {
      email: string;
    },
  ): Promise<{
    message: string;
  }> {
    let path = '/admin/{appSpace}/events/{eventId}/invitation';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getEventInvitation
   */
  getEventInvitation(
    appSpace: 'dajmespolugol',
    eventId: string,
    invitationId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    tournamentId: string | null;

    surface: string;

    public?: boolean;

    matchId: string;

    season: {
      _id: string;

      name: string;
    };

    level: string;

    capacity: number;

    description: {
      widgets: Array<{}>;
    };

    registrationDueDate: string;

    players?: Array<{
      _id: string;

      name: string;

      surname: string;
    }>;

    teams: Array<{
      _id: string;

      name: string;

      displayName: string;

      gender?: string;

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    startDate: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    nominations: Array<{
      teamId: string;

      athletes: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;

      crew: {};

      closed?: boolean;
    }>;

    protocol?: {
      events?: Array<{
        type?: string;

        eventType: string;

        team: string;

        eventTime?: string;

        reason?: string;

        player?: {
          _id: string;

          name: string;
        };

        replacement?: {
          _id: string;

          name: string;
        };

        assist?: {
          _id: string;

          name: string;
        };
      }>;
    };

    rules: {
      gender?: string;

      sport_sector: string;
    };

    closed?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };
      }>;

      players: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts: number;

          match_appearances: number;

          goals: number;

          assists: number;

          yellow_cards: number;

          red_cards: number;

          substitutions: number;

          own_goals: number;

          minutes: number;
        };
      }>;
    };
  }> {
    let path = '/admin/{appSpace}/events/{eventId}/invitation/{invitationId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{eventId}', eventId.toString());

    path = path.replace('{invitationId}', invitationId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#processEventInvitation
   */
  processEventInvitation(
    appSpace: 'dajmespolugol',
    eventId: string,
    invitationId: string,
    parameters: {} = {},
  ): Promise<{
    message?: string;
  }> {
    let path = '/admin/{appSpace}/events/{eventId}/invitation/{invitationId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{eventId}', eventId.toString());

    path = path.replace('{invitationId}', invitationId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#updateEventInvitationStatus
   */
  updateEventInvitationStatus(
    appSpace: 'dajmespolugol',
    eventId: string,
    invitationId: string,
    parameters: {} = {},
    body: {},
  ): Promise<{
    message: string;
  }> {
    let path = '/admin/{appSpace}/events/{eventId}/invitation/{invitationId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{eventId}', eventId.toString());

    path = path.replace('{invitationId}', invitationId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getEventTeamNomination
   */
  getEventTeamNomination(
    appSpace: 'dajmespolugol',
    eventId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    teamId: string;

    name?: string;

    closed?: boolean;

    athletes: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };
    }>;
  }> {
    let path = '/admin/{appSpace}/events/{eventId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{eventId}', eventId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#updateEventTeamNomination
   */
  updateEventTeamNomination(
    appSpace: 'dajmespolugol',
    eventId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      teamId: string;

      name?: string;

      closed?: boolean;

      athletes: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;
    },
  ): Promise<{
    teamId: string;

    name?: string;

    closed?: boolean;

    athletes: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };
    }>;
  }> {
    let path = '/admin/{appSpace}/events/{eventId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{eventId}', eventId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getMeEvents
   */
  getMeEvents(
    appSpace: 'dajmespolugol',
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number | null;

    items: Array<{
      _id: string;

      startDate: string;

      sportGround: {
        _id: string;

        name: string;

        sportGroundType: string;

        additionalData?: {};

        geo: {
          coordinates: Array<number>;
        };
      };

      rules?: {
        gender?: string;

        sport_sector?: string;
      };

      resultsTable?: {
        results: Array<{
          team: {
            _id: string;

            name: string;
          };

          stats: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };
        }>;

        players: Array<{
          _id: string;

          teamId: string;

          name: string;

          stats: {
            match_starts: number;

            match_appearances: number;

            goals: number;

            assists: number;

            yellow_cards: number;

            red_cards: number;

            substitutions: number;

            own_goals: number;

            minutes: number;
          };
        }>;
      };

      invited?: boolean;

      played?: boolean;

      closed?: boolean;

      invitations?: Array<string>;

      teams?: Array<{
        _id: string;

        name: string;

        additionalProperties: {
          homeaway: 'home' | 'away';
        };
      }>;

      players?: Array<{
        _id: string;
      }>;
    }>;
  }> {
    let path = '/me/{appSpace}/events';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 20;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getPublicEvents
   */
  getPublicEvents(
    appSpace: 'dajmespolugol',
    parameters: {
      dateFrom?: string;
      dateTo?: string;
    } = {},
  ): Promise<{
    items?: Array<{
      _id: string;

      startDate: string;

      sportGround: {
        _id: string;

        name: string;

        sportGroundType: string;

        additionalData?: {};

        geo: {
          coordinates: Array<number>;
        };
      };

      rules?: {
        gender?: string;

        sport_sector?: string;
      };

      resultsTable?: {
        results: Array<{
          team: {
            _id: string;

            name: string;
          };

          stats: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };
        }>;

        players: Array<{
          _id: string;

          teamId: string;

          name: string;

          stats: {
            match_starts: number;

            match_appearances: number;

            goals: number;

            assists: number;

            yellow_cards: number;

            red_cards: number;

            substitutions: number;

            own_goals: number;

            minutes: number;
          };
        }>;
      };

      invited?: boolean;

      played?: boolean;

      closed?: boolean;

      invitations?: Array<string>;

      teams?: Array<{
        _id: string;

        name: string;

        additionalProperties: {
          homeaway: 'home' | 'away';
        };
      }>;

      players?: Array<{
        _id: string;
      }>;
    }>;
  }> {
    let path = '/public/{appSpace}/events';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getTotalEventsCount
   */
  getTotalEventsCount(
    appSpace: 'dajmespolugol',
    parameters: {} = {},
  ): Promise<{
    events: Array<{
      _id: {
        month: number;

        year: number;
      };

      count: number;
    }>;
  }> {
    let path = '/public/{appSpace}/events/count';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getMeTournaments
   */
  getMeTournaments(
    appSpace: 'dajmespolugol',
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number | null;

    items: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sportGround: {
        _id: string;

        name: string;

        sportGroundType: string;

        additionalData?: {};

        geo: {
          coordinates: Array<number>;
        };
      };

      rules: {
        gender?: string;

        sport_sector: string;
      };

      public?: boolean;

      invited?: boolean;

      played?: boolean;

      invitations?: Array<string>;

      teams?: Array<{
        players?: Array<{
          _id?: string;
        }>;
      }>;
    }>;
  }> {
    let path = '/me/{appSpace}/tournaments';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 20;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#joinPublicTournament
   */
  joinPublicTournament(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    parameters: {} = {},
    body: {
      status: 'ACCEPTED';

      team: {
        _id?: string;

        name: string;
      };
    },
  ): Promise<{
    _id: string;

    name: string;

    dateFrom: string;

    dateTo: string;

    description: {
      widgets: Array<{}>;
    };

    level: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    rules: {
      gender?: string;

      sport_sector: string;
    };

    public?: boolean;

    registrationDueDate?: string;
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}/join';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getMeTournamentInvitations
   */
  getMeTournamentInvitations(
    appSpace: 'dajmespolugol',
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number | null;

    items: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sportGround: {
        _id: string;

        name: string;

        sportGroundType: string;

        additionalData?: {};

        geo: {
          coordinates: Array<number>;
        };
      };

      rules: {
        gender?: string;

        sport_sector: string;
      };

      public?: boolean;

      invited?: boolean;

      played?: boolean;

      invitations?: Array<string>;

      teams?: Array<{
        players?: Array<{
          _id?: string;
        }>;
      }>;
    }>;
  }> {
    let path = '/me/{appSpace}/tournament-invitations';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 20;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getPublicTournaments
   */
  getPublicTournaments(
    appSpace: 'dajmespolugol',
    parameters: {
      dateFrom?: string;
      dateTo?: string;
    } = {},
  ): Promise<{
    items?: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sportGround: {
        _id: string;

        name: string;

        sportGroundType: string;

        additionalData?: {};

        geo: {
          coordinates: Array<number>;
        };
      };

      rules: {
        gender?: string;

        sport_sector: string;
      };

      public?: boolean;

      invited?: boolean;

      played?: boolean;

      invitations?: Array<string>;

      teams?: Array<{
        players?: Array<{
          _id?: string;
        }>;
      }>;
    }>;
  }> {
    let path = '/public/{appSpace}/tournaments';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getTotalTournamentsCount
   */
  getTotalTournamentsCount(
    appSpace: 'dajmespolugol',
    parameters: {} = {},
  ): Promise<{
    tournaments: Array<{
      _id: {
        startMonth: number;

        startYear: number;

        endMonth: number;

        endYear: number;
      };

      count: number;
    }>;
  }> {
    let path = '/public/{appSpace}/tournaments/count';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getCreatedTournaments
   */
  getCreatedTournaments(
    appSpace: 'dajmespolugol',
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number | null;

    items: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sportGround: {
        _id: string;

        name: string;

        sportGroundType: string;

        additionalData?: {};

        geo: {
          coordinates: Array<number>;
        };
      };

      rules: {
        gender?: string;

        sport_sector: string;
      };

      public?: boolean;

      invited?: boolean;

      played?: boolean;

      invitations?: Array<string>;

      teams?: Array<{
        players?: Array<{
          _id?: string;
        }>;
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/tournaments';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 20;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#createTournament
   */
  createTournament(
    appSpace: 'dajmespolugol',
    parameters: {} = {},
    body: {
      name: string;

      dateFrom: string;

      dateTo: string;

      description: {
        widgets: Array<{}>;
      };

      level: string;

      sportGround: {};

      rules: {
        gender?: string;

        bornDateFrom?: string;

        bornDateTo?: string;

        sport_sector: string;
      };

      public?: boolean;

      registrationDueDate?: string;
    },
  ): Promise<{
    _id: string;

    name: string;

    dateFrom: string;

    dateTo: string;

    description: {
      widgets: Array<{}>;
    };

    level: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    rules: {
      gender?: string;

      sport_sector: string;
    };

    public?: boolean;

    registrationDueDate?: string;
  }> {
    let path = '/admin/{appSpace}/tournaments';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getTournament
   */
  getTournament(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    dateFrom: string;

    dateTo: string;

    description: {
      widgets: Array<{}>;
    };

    level: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    rules: {
      gender?: string;

      sport_sector: string;
    };

    public?: boolean;

    registrationDueDate?: string;
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#updateTournament
   */
  updateTournament(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    parameters: {} = {},
    body: {
      name: string;

      dateFrom: string;

      dateTo: string;

      description: {
        widgets: Array<{}>;
      };

      level: string;

      sportGround: {};

      rules: {
        gender?: string;

        bornDateFrom?: string;

        bornDateTo?: string;

        sport_sector: string;
      };

      public?: boolean;

      registrationDueDate?: string;
    },
  ): Promise<{
    _id: string;

    name: string;

    dateFrom: string;

    dateTo: string;

    description: {
      widgets: Array<{}>;
    };

    level: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    rules: {
      gender?: string;

      sport_sector: string;
    };

    public?: boolean;

    registrationDueDate?: string;
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#deleteTournament
   */
  deleteTournament(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#createTournamentTeam
   */
  createTournamentTeam(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    parameters: {} = {},
    body: {
      name: string;
    },
  ): Promise<{
    _id: string;

    name: string;

    players?: Array<{
      _id: string;

      name: string;

      surname: string;
    }>;
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getTournamentTeam
   */
  getTournamentTeam(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    players?: Array<{
      _id: string;

      name: string;

      surname: string;
    }>;
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#updateTournamentTeam
   */
  updateTournamentTeam(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      name: string;

      administeredBy?: {
        _id: string;

        name: string;

        surname: string;
      };
    },
  ): Promise<{
    _id: string;

    name: string;

    players?: Array<{
      _id: string;

      name: string;

      surname: string;
    }>;
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#removeTeamFromTournament
   */
  removeTeamFromTournament(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#removePlayerFromTournamentTeam
   */
  removePlayerFromTournamentTeam(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    teamId: string,
    sportnetId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path =
      '/admin/{appSpace}/tournaments/{tournamentId}/teams/{teamId}/players/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#createTournamentInvitation
   */
  createTournamentInvitation(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    parameters: {} = {},
    body: {
      email: string;

      teamId: string;
    },
  ): Promise<{
    message: string;
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}/invitation';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getTournamentInvitation
   */
  getTournamentInvitation(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    invitationId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    dateFrom: string;

    dateTo: string;

    description: {
      widgets: Array<{}>;
    };

    level: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    rules: {
      gender?: string;

      sport_sector: string;
    };

    public?: boolean;

    registrationDueDate?: string;
  }> {
    let path =
      '/admin/{appSpace}/tournaments/{tournamentId}/invitation/{invitationId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    path = path.replace('{invitationId}', invitationId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#updateTournamentInvitationStatus
   */
  updateTournamentInvitationStatus(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    invitationId: string,
    parameters: {} = {},
    body: {},
  ): Promise<{
    message: string;
  }> {
    let path =
      '/admin/{appSpace}/tournaments/{tournamentId}/invitation/{invitationId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    path = path.replace('{invitationId}', invitationId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#processTournamentInvitation
   */
  processTournamentInvitation(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    invitationId: string,
    parameters: {} = {},
  ): Promise<{
    message?: string;
  }> {
    let path =
      '/admin/{appSpace}/tournaments/{tournamentId}/invitation/{invitationId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    path = path.replace('{invitationId}', invitationId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#createTournamentTeamByInvitation
   */
  createTournamentTeamByInvitation(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    invitationId: string,
    parameters: {} = {},
    body: {
      name: string;
    },
  ): Promise<{
    _id: string;

    name: string;

    players?: Array<{
      _id: string;

      name: string;

      surname: string;
    }>;
  }> {
    let path =
      '/admin/{appSpace}/tournaments/{tournamentId}/invitations/{invitationId}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    path = path.replace('{invitationId}', invitationId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#removeEventPlayer
   */
  removeEventPlayer(
    appSpace: 'dajmespolugol',
    eventId: string,
    sportnetId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path = '/admin/{appSpace}/events/{eventId}/player/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{eventId}', eventId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getTournamentEvents
   */
  getTournamentEvents(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number | null;

    items: Array<{
      _id: string;

      startDate: string;

      sportGround: {
        _id: string;

        name: string;

        sportGroundType: string;

        additionalData?: {};

        geo: {
          coordinates: Array<number>;
        };
      };

      rules?: {
        gender?: string;

        sport_sector?: string;
      };

      resultsTable?: {
        results: Array<{
          team: {
            _id: string;

            name: string;
          };

          stats: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };
        }>;

        players: Array<{
          _id: string;

          teamId: string;

          name: string;

          stats: {
            match_starts: number;

            match_appearances: number;

            goals: number;

            assists: number;

            yellow_cards: number;

            red_cards: number;

            substitutions: number;

            own_goals: number;

            minutes: number;
          };
        }>;
      };

      invited?: boolean;

      played?: boolean;

      closed?: boolean;

      invitations?: Array<string>;

      teams?: Array<{
        _id: string;

        name: string;

        additionalProperties: {
          homeaway: 'home' | 'away';
        };
      }>;

      players?: Array<{
        _id: string;
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}/events';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 20;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#createTournamentMatch
   */
  createTournamentMatch(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    parameters: {} = {},
    body: {
      startDate: string;

      sportGround: {};

      teams?: Array<{
        _id: string;

        additionalProperties: {
          homeaway: 'home' | 'away';
        };
      }>;
    },
  ): Promise<{
    _id: string;

    teams: Array<{
      _id: string;

      name: string;

      displayName: string;

      gender?: string;

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    startDate: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    nominations: Array<{
      teamId: string;

      athletes: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;

      crew: {};

      closed?: boolean;
    }>;

    protocol?: {
      events?: Array<{
        type?: string;

        eventType: string;

        team: string;

        eventTime?: string;

        reason?: string;

        player?: {
          _id: string;

          name: string;
        };

        replacement?: {
          _id: string;

          name: string;
        };

        assist?: {
          _id: string;

          name: string;
        };
      }>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;
    };

    closed?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };
      }>;

      players: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts: number;

          match_appearances: number;

          goals: number;

          assists: number;

          yellow_cards: number;

          red_cards: number;

          substitutions: number;

          own_goals: number;

          minutes: number;
        };
      }>;
    };
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}/events';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getTournamentEvent
   */
  getTournamentEvent(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    eventId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    tournamentId: string | null;

    surface: string;

    public?: boolean;

    matchId: string;

    season: {
      _id: string;

      name: string;
    };

    level: string;

    capacity: number;

    description: {
      widgets: Array<{}>;
    };

    registrationDueDate: string;

    players?: Array<{
      _id: string;

      name: string;

      surname: string;
    }>;

    teams: Array<{
      _id: string;

      name: string;

      displayName: string;

      gender?: string;

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    startDate: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    nominations: Array<{
      teamId: string;

      athletes: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;

      crew: {};

      closed?: boolean;
    }>;

    protocol?: {
      events?: Array<{
        type?: string;

        eventType: string;

        team: string;

        eventTime?: string;

        reason?: string;

        player?: {
          _id: string;

          name: string;
        };

        replacement?: {
          _id: string;

          name: string;
        };

        assist?: {
          _id: string;

          name: string;
        };
      }>;
    };

    rules: {
      gender?: string;

      sport_sector: string;
    };

    closed?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };
      }>;

      players: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts: number;

          match_appearances: number;

          goals: number;

          assists: number;

          yellow_cards: number;

          red_cards: number;

          substitutions: number;

          own_goals: number;

          minutes: number;
        };
      }>;
    };
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}/events/{eventId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#updateTournamentEvent
   */
  updateTournamentEvent(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    eventId: string,
    parameters: {} = {},
    body: {
      startDate: string;

      sportGround: {
        _id: string;
      };

      protocol?: {
        events?: Array<{
          type?: string;

          eventType: string;

          team: string;

          eventTime?: string;

          reason?: string;

          player?: {
            _id: string;

            name: string;
          };

          replacement?: {
            _id: string;

            name: string;
          };

          assist?: {
            _id: string;

            name: string;
          };
        }>;
      };

      closed?: boolean;

      teams?: Array<{
        _id: string;

        additionalProperties: {
          homeaway: 'home' | 'away';
        };
      }>;
    },
  ): Promise<{
    _id: string;

    teams: Array<{
      _id: string;

      name: string;

      displayName: string;

      gender?: string;

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    startDate: string;

    sportGround: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};

      geo: {
        coordinates: Array<number>;
      };
    };

    nominations: Array<{
      teamId: string;

      athletes: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;

      crew: {};

      closed?: boolean;
    }>;

    protocol?: {
      events?: Array<{
        type?: string;

        eventType: string;

        team: string;

        eventTime?: string;

        reason?: string;

        player?: {
          _id: string;

          name: string;
        };

        replacement?: {
          _id: string;

          name: string;
        };

        assist?: {
          _id: string;

          name: string;
        };
      }>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;
    };

    closed?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };
      }>;

      players: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts: number;

          match_appearances: number;

          goals: number;

          assists: number;

          yellow_cards: number;

          red_cards: number;

          substitutions: number;

          own_goals: number;

          minutes: number;
        };
      }>;
    };
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}/events/{eventId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#deleteTournamentEvent
   */
  deleteTournamentEvent(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    eventId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path = '/admin/{appSpace}/tournaments/{tournamentId}/events/{eventId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getTournamentEventTeamNomination
   */
  getTournamentEventTeamNomination(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    eventId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    teamId: string;

    name?: string;

    closed?: boolean;

    athletes: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };
    }>;
  }> {
    let path =
      '/admin/{appSpace}/tournaments/{tournamentId}/events/{eventId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    path = path.replace('{eventId}', eventId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#updateTournamentEventTeamNomination
   */
  updateTournamentEventTeamNomination(
    appSpace: 'dajmespolugol',
    tournamentId: string,
    eventId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      teamId: string;

      name?: string;

      closed?: boolean;

      athletes: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;
    },
  ): Promise<{
    teamId: string;

    name?: string;

    closed?: boolean;

    athletes: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };
    }>;
  }> {
    let path =
      '/admin/{appSpace}/tournaments/{tournamentId}/events/{eventId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{tournamentId}', tournamentId.toString());

    path = path.replace('{eventId}', eventId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#getMeFriendsList
   */
  getMeFriendsList(
    parameters: {} = {},
  ): Promise<{
    friends: Array<{
      sportnetId: string;

      name: string;

      surname: string;

      email: string;
    }>;
  }> {
    let path = '/me/friends-list';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name HobbyApi#openapiJson
   */
  openapiJson(parameters: {} = {}): Promise<{}> {
    let path = '/openapi.json';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new HobbyApi();

