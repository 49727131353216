import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { format } from 'date-fns';
import { rem } from 'polished';
import withQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import config from '../../config';
import { Event } from '../../library/Event';
import { Team } from '../../library/Tournament';
import useWindowSize from '../../utilities/resizeHook';
import __ from '../../utilities/__';
import BasicTable from '../BasicTable';
import CustomButton from '../CustomButton';

interface OwnProps {
  events: Event[];
  total: number;
  isFetching: boolean;
  isCreator: boolean;
}

const TournamentEventsList: React.FC<OwnProps &
  QueryHocInterface &
  RouteComponentProps<{ tournamentId: string }, {}>> = ({
  events,
  router: { push },
  params: { tournamentId },
  setParameter,
  query: { offset },
  total,
  isFetching,
  isCreator,
}) => {
  const resizeHook = useWindowSize();

  const [mobileLayout, setMobileLayout] = React.useState(
    window.innerWidth < 600,
  );

  React.useEffect(() => {
    if (resizeHook.innerWidth < 600) {
      setMobileLayout(true);
    } else {
      setMobileLayout(false);
    }
  }, [resizeHook.innerWidth]);

  if (!isCreator && events.length === 0) {
    return null;
  }

  return (
    <Segment
      raised
      loading={isFetching}
      header={
        <SegmentHeader size="xs" withSeparator>
          {__('Zoznam zápasov')}
        </SegmentHeader>
      }
    >
      {events.length > 0 && (
        <>
          <BasicTable
            rows={events}
            columns={[
              { header: __('Dátum a čas') },
              { header: __('Miesto konania') },
              { header: __('Domáci tím') },
              { header: __('Hosťujúci tím') },
              { header: __('Stav') },
            ]}
            onClickRow={e => {
              push(`/admin/tournament/${tournamentId}/event/${e._id}`);
            }}
            colsCount={5}
            onChangeOffset={value => {
              setParameter({ offset: value });
            }}
            withPaginator
            offset={Number(offset)}
            total={total}
            limit={config.DEFAULT_LIST_LIMIT}
            renderRow={e => {
              const homeTeam = (e.teams || []).find(
                (t: Team) => t.additionalProperties.homeaway === 'home',
              );
              const awayTeam = (e.teams || []).find(
                (t: Team) => t.additionalProperties.homeaway === 'away',
              );
              return [
                format(new Date(e.startDate), 'DD.MM.YYYY o HH:mm'),
                e.sportGround.name,
                homeTeam && homeTeam.name,
                awayTeam && awayTeam.name,
                e.closed ? __('Uzatvorené') : __('Neuzatvorené'),
              ];
            }}
            rowKey="_id"
          />
          <div style={{ height: rem(10) }} />
        </>
      )}
      {isCreator && (
        <CustomButton
          onClick={() => {
            push(`/admin/tournament/${tournamentId}/event`);
          }}
          block={mobileLayout}
        >
          {__('Vytvoriť zápas')}
        </CustomButton>
      )}
    </Segment>
  );
};

export default compose<React.FC<OwnProps>>(
  withRouter,
  withQueryHoc({
    parameters: { offset: { type: QueryHocTypes.Number, defaultValue: 0 } },
  }),
)(TournamentEventsList);
