import SportnetIcon from '@sportnet/ui/lib/Icon';
import { Loader } from '@sportnet/ui/lib/Loader';
import Modal, { ModalContent } from '@sportnet/ui/lib/Modal';
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  isSameMonth,
  isSameYear,
  startOfMonth,
} from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import { withTheme } from 'styled-components';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import HobbyApi from '../../api/HobbyApi';
import Icon from '../../components/Icons';
import config from '../../config';
import { RootState } from '../../configureStore';
import { initializeOrSetListParams } from '../../containers/App/actions';
import Calendar from '../../containers/Calendar';
import EventsMap from '../../containers/Map';
import {
  loadEventsList,
  loadTournamentsList,
} from '../../containers/Map/actions';
import {
  publicEventsSelector,
  publicTournamentsSelector,
} from '../../containers/Map/selectors';
import { ITheme } from '../../theme/theme';
import __ from '../../utilities/__';
import {
  Box,
  LeftBottom,
  Legend,
  LegendLabel,
  Radio,
  RightBottom,
  RightTop,
  Wrapper,
} from './styledComponents';

const mapDispatchToProps = {
  loadEvents: thunkToAction(loadEventsList),
  loadTournaments: thunkToAction(loadTournamentsList),
  initializeList: thunkToAction(initializeOrSetListParams.action),
};

const mapStateToProps = (state: RootState) => ({
  events: publicEventsSelector(config.PUBLIC_EVENTS_LIST_NAME)(state),
  tournaments: publicTournamentsSelector(config.PUBLIC_TOURNAMENTS_LIST_NAME)(
    state,
  ),
});

const Home: React.FC<{ theme: ITheme } & typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>> = ({
  theme,
  initializeList,
  events,
  tournaments,
  loadEvents,
  loadTournaments,
}) => {
  const [modalOpened, setModalOpened] = React.useState(false);
  const [activeDate, setActiveDate] = React.useState(new Date());
  const [isFetching, setIsFetching] = React.useState(false);
  const [resources, setResources] = React.useState(['events', 'tournaments']);
  const [countByMonthAndYear, setCountByMonthAndYear] = React.useState<{
    [key: string]: number;
  }>({});

  useAsyncData(async () => {
    setIsFetching(true);
    await initializeList({
      listName: config.PUBLIC_EVENTS_LIST_NAME,
      params: {
        offset: 0,
        dateFrom: format(startOfMonth(activeDate), 'YYYY-MM-DD'),
        dateTo: format(endOfMonth(activeDate), 'YYYY-MM-DD'),
      },
    });
    await initializeList({
      listName: config.PUBLIC_TOURNAMENTS_LIST_NAME,
      params: {
        offset: 0,
        dateFrom: format(startOfMonth(activeDate), 'YYYY-MM-DD'),
        dateTo: format(endOfMonth(activeDate), 'YYYY-MM-DD'),
      },
    });

    if (Object.keys(countByMonthAndYear).length === 0) {
      const { events: eventsCount } = await HobbyApi.getTotalEventsCount(
        config.APP_SPACE,
      );
      const {
        tournaments: tournamentsCount,
      } = await HobbyApi.getTotalTournamentsCount(config.APP_SPACE);

      const byMonthAndYear: { [key: string]: number } = eventsCount.reduce(
        (acc, i) => {
          const date = format(
            new Date(i._id.year, i._id.month - 1, 1),
            'YYYY-MM',
          );
          return { ...acc, [date]: i.count };
        },
        {},
      );

      tournamentsCount.forEach(i => {
        const dateFrom = new Date(i._id.startYear, i._id.startMonth - 1, 1);
        const dateTo = new Date(i._id.endYear, i._id.endMonth - 1, 1);

        const difference = differenceInMonths(dateTo, dateFrom);

        for (let index = 0; index <= difference; index++) {
          const date = format(addMonths(dateFrom, index), 'YYYY-MM');
          byMonthAndYear[date] = byMonthAndYear[date]
            ? byMonthAndYear[date] + i.count
            : i.count;
        }
      });
      setCountByMonthAndYear(byMonthAndYear);
    }

    await loadEvents();
    await loadTournaments();
    setIsFetching(false);
  }, [activeDate]);

  const toggleResource = (resourceName: string) => {
    const currentResources = [...resources];
    const index = currentResources.indexOf(resourceName);
    if (index > -1) {
      delete currentResources[index];
    } else {
      currentResources.push(resourceName);
    }
    setResources(currentResources.filter(i => i));
  };

  return (
    <>
      <Wrapper>
        <RightTop>
          <Link to="/admin/events/me">
            <Box primary clickable>
              <SportnetIcon size={24} color="#fff" name="person-outline" />
            </Box>
          </Link>
        </RightTop>
        <EventsMap activeDate={activeDate} resources={resources} />
        <LeftBottom>
          <Box>
            <Legend
              onClick={() => {
                toggleResource('tournaments');
              }}
            >
              <Radio
                color={theme.secondaryColor}
                checked={resources.includes('tournaments')}
              />
              <LegendLabel>{__('Turnaje')}</LegendLabel>
            </Legend>
            <div style={{ width: rem(10) }} />
            <Legend
              onClick={() => {
                toggleResource('events');
              }}
            >
              <Radio
                color={theme.color.primary}
                checked={resources.includes('events')}
              />
              <LegendLabel>{__('Zápasy')}</LegendLabel>
            </Legend>
          </Box>
        </LeftBottom>
        <RightBottom>
          <Box
            clickable
            onClick={() => {
              setModalOpened(true);
            }}
          >
            {isFetching ? (
              <Loader size={'l'} theme={theme} />
            ) : (
              <Icon size={24} color={theme.color.primary} name="calendar" />
            )}
          </Box>
        </RightBottom>
      </Wrapper>
      {modalOpened && (
        <Modal
          size="xs"
          centered
          isOpen={modalOpened}
          handleClose={() => {
            setModalOpened(false);
          }}
        >
          <ModalContent>
            <Calendar
              isFetching={isFetching}
              closeAction={() => {
                setModalOpened(false);
              }}
              countByMonthAndYear={countByMonthAndYear}
              changeDateAction={(month: number, year: number) => {
                const newDate = new Date(year, month);
                if (
                  !isSameYear(newDate, activeDate) ||
                  !isSameMonth(newDate, activeDate)
                ) {
                  setActiveDate(newDate);
                }
              }}
              activeDate={activeDate}
            />
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
)(Home);
