import { ACCESS_TOKEN_COOKIE_NAME } from '@sportnet/ui/lib/Authorization';
import Button from '@sportnet/ui/lib/Button';
import { ContentLoader } from '@sportnet/ui/lib/Loader';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import Editor from 'content/lib/editor';
import defaultWidgets from 'content/lib/editor/defaultWidgets';
import * as Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { change, formValueSelector, submit } from 'redux-form';
import { useAsyncData } from 'ssr-service';
import { withTheme } from 'styled-components';
import HobbyApi from '../../api/HobbyApi';
import AdminLayoutContext from '../../components/AdminLayout/context';
import { Content } from '../../components/Layout';
import config from '../../config';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import { Event } from '../../library/Event';
import { SportGround } from '../../library/SportGround';
import { ITheme } from '../../theme/theme';
import __ from '../../utilities/__';
import { Actions } from '../Events/styledComponents';
import Form from './form';

const FORM_NAME = 'EVENT_FORM';

const availableWidgets = defaultWidgets.filter((i: any) =>
  ['text', 'table', 'hr', 'photo', 'video', 'photogallery'].includes(i.type),
);

const mapStateToProps = (state: RootState) => {
  const selector = formValueSelector(FORM_NAME);
  return {
    // sportGroundQuery: selector(state, 'sportGroundQuery'),
    isPublic: selector(state, 'public') || false,
    widgets: selector(state, 'description.widgets'),
  };
};

const EventEdit: React.FC<{ dispatch: CustomThunkDispatch } & ReturnType<
  typeof mapStateToProps
> &
  RouteComponentProps<{ eventId: string }, {} & { theme: ITheme }>> = (
  { dispatch, widgets, isPublic, router: { push }, params: { eventId } },
  theme,
) => {
  const [sportGrounds, setSportGrounds] = React.useState<SportGround[]>([]);
  const [sportGroundQuery, setSportGroundQuery] = React.useState('');
  const [sportGroundsAreFetching, setSportGroundFetchingState] = React.useState(
    false,
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState<Event | null>(null);

  useAsyncData(async () => {
    try {
      const res = await HobbyApi.getEvent(config.APP_SPACE, eventId);
      setInitialValues(res);
    } catch (e) {
      alert(__('Nepodarilo sa získať podrobnosti zápasu.'));
    }
  }, [eventId]);

  useAsyncData(async () => {
    loadSportGrounds();
  }, [sportGroundQuery]);

  const loadSportGrounds = async () => {
    const params: { q?: string } = {};
    if (sportGroundQuery) {
      params.q = sportGroundQuery;
    }
    setSportGroundFetchingState(true);
    try {
      const { items = [] } = await HobbyApi.getSportGrounds(
        config.APP_SPACE,
        params,
      );
      setSportGrounds(items);
    } catch (e) {
      //
    } finally {
      setSportGroundFetchingState(false);
    }
  };

  const submitForm = async (values: {
    startDate: string;
    surface: { value: string };
    level: { value: string };
    description: {
      widgets: any[];
    };
    registrationDueDate?: string;
    sportGround: { value: string };
    public?: boolean;
    rules?: {
      gender?: { value: string };
    };
    capacity: number;
  }) => {
    setIsSubmitting(true);
    try {
      const rules: {
        sport_sector: string;
        gender?: string;
      } = { sport_sector: 'futbal' };
      if (values.rules && values.rules.gender) {
        rules.gender = values.rules.gender.value;
      }
      const data: {
        startDate: string;
        surface: string;
        level: string;
        description: {
          widgets: any[];
        };
        registrationDueDate?: string;
        sportGround: {
          _id: string;
        };
        public?: boolean;
        rules: {
          sport_sector: string;
          gender?: string;
        };
        capacity: number;
      } = {
        startDate: values.startDate,
        public: values.public,
        capacity: values.capacity,
        description: values.description,
        ...(values.registrationDueDate && {
          registrationDueDate: values.registrationDueDate,
        }),
        sportGround: { _id: values.sportGround.value },
        rules,
        surface: values.surface.value,
        level: values.level.value,
      };
      await HobbyApi.updateEvent(config.APP_SPACE, eventId, {}, data);
      push('/admin/events/admin');
    } catch (e) {
      alert(__('Zápas sa nepodarilo uložiť'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const transformValues = (values: Event) => {
    return {
      ...values,
      level: { label: values.level, value: values.level },
      surface: { label: values.surface, value: values.surface },
      sportGround: {
        label: values.sportGround.name,
        value: values.sportGround._id,
      },
      rules: {
        ...(values.rules || {}),
        ...(values.rules &&
          values.rules.gender &&
          values.rules.gender === 'M' && {
            gender: {
              label: __('Muži'),
              value: 'M',
            },
          }),
        ...(values.rules &&
          values.rules.gender &&
          values.rules.gender === 'F' && {
            gender: {
              label: __('Ženy'),
              value: 'F',
            },
          }),
      },
    };
  };

  const { setHeaderComponents, headerComponents } = React.useContext(
    AdminLayoutContext,
  );
  const componentHeader = __('Zápasy');
  const componentSubHeader = __('Úprava zápasu');

  if (
    headerComponents.header !== componentHeader ||
    headerComponents.subHeader !== componentSubHeader
  ) {
    setHeaderComponents(componentHeader, componentSubHeader);
  }

  if (!initialValues) {
    return <ContentLoader theme={theme} />;
  }

  return (
    <Content>
      <Form
        form={FORM_NAME}
        onSubmit={submitForm}
        isSubmitting={isSubmitting}
        sportGrounds={sportGrounds}
        setSportGroundQuery={setSportGroundQuery}
        sportGroundsAreFetching={sportGroundsAreFetching}
        loadSportGrounds={loadSportGrounds}
        isPublic={isPublic}
        initialValues={transformValues(initialValues)}
        isTournamentEvent={false}
      />
      <Segment raised header={<SegmentHeader>{__('Popis')}</SegmentHeader>}>
        {widgets && (
          <Editor
            onChange={(e: any) =>
              dispatch(change(FORM_NAME, 'description.widgets', e))
            }
            availableWidgets={availableWidgets}
            initialValue={widgets || []}
            contentDivider="default"
            appId="hobby"
            token={Cookies.get(ACCESS_TOKEN_COOKIE_NAME) || ''}
          />
        )}
      </Segment>
      <Actions>
        <Button
          primary
          type="button"
          loading={isSubmitting}
          onClick={() => {
            dispatch(submit(FORM_NAME));
          }}
        >
          {__('Uložiť')}
        </Button>
      </Actions>
    </Content>
  );
};

export default compose(connect(mapStateToProps), withTheme)(EventEdit);
