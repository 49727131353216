import Button from '@sportnet/ui/lib/Button';
import { format } from 'date-fns';
import connecToQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getListTotal, isCommiting } from 'redux-list';
import { useAsyncData } from 'ssr-service';
import { withTheme } from 'styled-components';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import BasicTable from '../../../components/BasicTable';
import NoItemsFround from '../../../components/NoItemsFound';
import config from '../../../config';
import { RootState } from '../../../configureStore';
import { initializeOrSetListParams } from '../../../containers/App/actions';
import Loading from '../../../containers/Loading';
import { loadAdminTournamentsList } from '../../../containers/Tournaments/actions';
import { meTournamentsSelector } from '../../../containers/Tournaments/selectors';
import { Tournament } from '../../../library/Tournament';
import { ITheme } from '../../../theme/theme';
import __ from '../../../utilities/__';
import { Actions } from '../../Events/styledComponents';

const mapDispatchToProps = {
  loadTournaments: thunkToAction(loadAdminTournamentsList),
  initializeList: thunkToAction(initializeOrSetListParams.action),
};

const mapStateToProps = (state: RootState) => ({
  tournaments: meTournamentsSelector(config.ADMIN_TOURNAMENTS_LIST_NAME)(state),
  isFetching: isCommiting(config.ADMIN_TOURNAMENTS_LIST_NAME)(state),
  total: getListTotal(config.ADMIN_TOURNAMENTS_LIST_NAME)(state),
});

const MeEvents: React.FC<
  typeof mapDispatchToProps &
    QueryHocInterface &
    RouteComponentProps<{}, {}> &
    ReturnType<typeof mapStateToProps> & { theme: ITheme }
> = ({
  theme,
  loadTournaments,
  initializeList,
  query,
  tournaments,
  isFetching,
  total,
  setParameter,
  router: { push },
}) => {
  useAsyncData(async () => {
    await initializeList({
      listName: config.ADMIN_TOURNAMENTS_LIST_NAME,
      params: query,
    });
    await loadTournaments();
  }, [query]);

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <>
          {tournaments.length > 0 ? (
            <>
              <Actions>
                <Button
                  onClick={() => {
                    push('/admin/tournament');
                  }}
                  block={window.innerWidth <= 600}
                  primary
                >
                  {__('Vytvoriť nový turnaj')}
                </Button>
              </Actions>
              <BasicTable
                rowKey="_id"
                rows={tournaments}
                renderRow={i => [
                  i.name,
                  `${format(new Date(i.dateFrom), 'DD.MM.YYYY')} - ${format(
                    new Date(i.dateTo),
                    'DD.MM.YYYY',
                  )}`,
                ]}
                columns={[
                  { header: __('Názov') },
                  { header: __('Predpokladané trvanie') },
                ]}
                onClickRow={(i: Tournament) => {
                  push(`/admin/tournament/${i._id}`);
                }}
                colsCount={2}
                withPaginator
                offset={Number(query.offset)}
                limit={config.DEFAULT_LIST_LIMIT}
                total={total || 0}
                onChangeOffset={newOffset => {
                  setParameter({ offset: newOffset });
                }}
              />
            </>
          ) : (
            <NoItemsFround
              label={__('Nenašli sa žiadne turnaje')}
              action={{
                label: __('Vytvoriť nový turnaj'),
                onClick: () => {
                  push('/admin/tournament');
                },
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default compose(
  connecToQueryHoc({
    parameters: { offset: { type: QueryHocTypes.Number, defaultValue: 0 } },
  }),
  withTheme,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(MeEvents);
