import { AnyAction } from 'redux';
import { getProp } from 'sportnet-utilities';
import { AsyncActionCreators } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Codelist, IFriend, IUser, Writeable } from '../../library/App';
import { Event } from '../../library/Event';
import { PO } from '../../library/PO';
import { PPO } from '../../library/PPO';
import { Tournament } from '../../library/Tournament';
import serializeParameters from '../../utilities/serializeParameters';
import { getFriendsList, setAccessToken, setAuthUser } from './actions';

export type EntitiesState = Readonly<{
  PPO: Readonly<{
    [key: string]: Readonly<PPO>;
  }>;
  PO: Readonly<{
    [key: string]: Readonly<PO>;
  }>;
  codelists: Readonly<{
    [key: string]: Readonly<Codelist>;
  }>;
  events: Readonly<{
    [key: string]: Event;
  }>;
  tournaments: Readonly<{
    [key: string]: Tournament;
  }>;
}>;

export const entitiesReducer = (
  state: EntitiesState = {
    PPO: {},
    PO: {},
    codelists: {},
    events: {},
    tournaments: {},
  },
  action: AnyAction,
): EntitiesState => {
  if (getProp(action.payload, ['result', 'entities'])) {
    return Object.keys(action.payload.result.entities).reduce(
      (acc: any, entity: keyof EntitiesState) => {
        acc[entity] = Object.keys(
          action.payload.result.entities[entity],
        ).reduce(
          (innerAcc: Writeable<EntitiesState[typeof entity]>, id) => {
            innerAcc[id] = action.payload.result.entities[entity][id];
            return innerAcc;
          },
          { ...getProp(state, [entity], {}) },
        );
        return acc;
      },
      { ...state },
    );
  }
  return state;
};

export interface IDetailInitialState<
  R extends any | undefined = undefined,
  E extends any = any
> {
  [key: string]: {
    isFetching: boolean;
    error: E;
    data?: R;
  };
}

export const detailReducerFromAction = <D, E>(
  asyncActionCreators: Array<{
    async: AsyncActionCreators<
      { [key: string]: any },
      { data?: D; [key: string]: any },
      E
    >;
  }>,
  ...parameterKeys: string[]
) =>
  reducerWithInitialState<IDetailInitialState<D>>({})
    .cases(asyncActionCreators.map(a => a.async.started), (state, params) => {
      const key = serializeParameters(params, parameterKeys);
      return {
        ...state,
        [key]: {
          ...state[key],
          isFetching: true,
        },
      };
    })
    .cases(
      asyncActionCreators.map(a => a.async.done),
      (state, { params, result: { data } }) => {
        const key = serializeParameters(params, parameterKeys);
        return {
          ...state,
          [key]: {
            ...state[key],
            data,
            isFetching: false,
            error: null,
          },
        };
      },
    )
    .cases(
      asyncActionCreators.map(a => a.async.failed),
      (state, { params, error }) => {
        const key = serializeParameters(params, parameterKeys);
        return {
          ...state,
          [key]: {
            ...state[key],
            isFetching: false,
            error,
          },
        };
      },
    );

export interface IAuthUserState {
  accessToken: string | null;
  profile: null | IUser;
}

export interface IUserPreferencesState {
  friends: IFriend[];
}

export const authUserReducer = reducerWithInitialState<IAuthUserState>({
  accessToken: null,
  profile: null,
})
  .case(setAccessToken, (state, accessToken) => ({
    ...state,
    accessToken,
  }))
  .case(setAuthUser, (state, { accessToken, user }) => ({
    ...state,
    accessToken,
    profile: user,
  }));

export const userPreferencesReducer = reducerWithInitialState<
  IUserPreferencesState
>({
  friends: [],
}).case(getFriendsList.async.done, (state, { result: { friends } }) => ({
  ...state,
  friends,
}));
