import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { format } from 'date-fns';
import { em, rem } from 'polished';
import * as React from 'react';
import { getProp } from 'sportnet-utilities';
import styled from 'styled-components';
import { Event } from '../../library/Event';
import __ from '../../utilities/__';
import { MOBILE_MENU_BREAKPOINT } from '../AdminLayout/styledComponents';
import { createInfoBlock, InfoBlocks } from '../InfoBlock';
import TimeLine from './TimeLine';

const ScoreBoard = styled.div`
  border-radius: ${rem(5)};
  margin-bottom: ${rem(10)};
  box-shadow: 0 ${rem(1)} ${rem(5)} 0 rgba(0, 0, 0, 0.1);
`;

const ScoreBoardTeam = styled.div`
  padding: 0 ${rem(10)};
  font-size: ${rem(15)};
  font-weight: 200;
`;

const ScoreBoardGoals = styled.div`
  width: ${rem(40)};
  height: ${rem(40)};
  text-align: center;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScoreBoardResult = styled.div<{ reverse?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${rem(5)};
  width: 100%;
  justify-content: space-between;
  background: ${({ reverse, theme }) =>
    reverse ? '#fff' : theme.color.primary};
  color: ${({ reverse, theme }) => (reverse ? theme.color.primary : '#fff')};
  @media screen and (min-width: ${MOBILE_MENU_BREAKPOINT}px) {
    width: 50%;
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
    justify-content: flex-end;
  }
`;

const ScoreBoardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${em(18)};
  color: #fff;
  @media screen and (max-width: ${MOBILE_MENU_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

interface OwnProps {
  event: Event;
  isPlayer?: boolean;
  isCreator?: boolean;
}

const EventHeader: React.FC<OwnProps> = ({ event, isPlayer, isCreator }) => {
  const getGoalsByTeams = () => {
    const teams = event.teams || [];
    let homeTeamGoals = 0;
    let awayTeamGoals = 0;
    if (event.protocol) {
      homeTeamGoals = (event.protocol.events || []).reduce((acc, e) => {
        if (
          (teams[0] &&
            e.team === teams[0]._id &&
            e.eventType === 'goal' &&
            e.phase !== 'shootout' &&
            e.type !== 'dropped') ||
          (teams[1] &&
            e.team === teams[1]._id &&
            e.eventType === 'goal' &&
            e.type === 'dropped')
        ) {
          return acc + 1;
        }
        return acc;
      }, homeTeamGoals);
      awayTeamGoals = (event.protocol.events || []).reduce((acc, e) => {
        if (
          (teams[1] &&
            e.team === teams[1]._id &&
            e.eventType === 'goal' &&
            e.phase !== 'shootout' &&
            e.type !== 'dropped') ||
          (teams[0] &&
            e.team === teams[0]._id &&
            e.eventType === 'goal' &&
            e.type === 'dropped')
        ) {
          return acc + 1;
        }
        return acc;
      }, awayTeamGoals);
    }
    return { homeTeamGoals, awayTeamGoals };
  };

  const {
    homeTeamGoals: teamHomeTeamGoals,
    awayTeamGoals: teamAwayTeamGoals,
  } = getGoalsByTeams();

  return (
    <>
      {event.closed && (event.teams || []).length > 0 && (
        <ScoreBoard>
          <ScoreBoardContent>
            <ScoreBoardResult>
              <ScoreBoardTeam>{(event.teams || [])[0].name}</ScoreBoardTeam>
              <ScoreBoardGoals>{teamHomeTeamGoals}</ScoreBoardGoals>
            </ScoreBoardResult>
            <div />
            <ScoreBoardResult reverse>
              <ScoreBoardTeam>{(event.teams || [])[1].name}</ScoreBoardTeam>
              <ScoreBoardGoals>{teamAwayTeamGoals}</ScoreBoardGoals>
            </ScoreBoardResult>
          </ScoreBoardContent>
        </ScoreBoard>
      )}
      <Segment
        raised
        header={
          <SegmentHeader withSeparator size="xs">
            {__('Informácie')}
          </SegmentHeader>
        }
      >
        <InfoBlocks>
          {createInfoBlock(
            'calendar',
            format(new Date(event.startDate), 'DD.MM.YYYY o HH:mm'),
            __('Dátum a čas stretnutia'),
          )}
          {createInfoBlock(
            'place',
            event.sportGround.name,
            __('Miesto stretnutia'),
          )}
        </InfoBlocks>
      </Segment>
      {event.closed && getProp(event, ['protocol', 'events'], []).length > 0 && (
        <Segment
          raised
          header={
            <SegmentHeader size="xs" withSeparator>
              {__('Priebeh zápasu')}
            </SegmentHeader>
          }
        >
          <TimeLine
            teams={event.teams || []}
            protocol={{
              events:
                event.protocol && event.protocol.events
                  ? event.protocol.events
                  : [],
            }}
            eventTypes={[
              {
                _id: 'goal_game',
                label: 'Gól z hry',
                eventType: 'goal',
              },
              {
                _id: 'goal_standard',
                label: 'Gól zo štandardnej situácie',
                eventType: 'goal',
              },
              {
                _id: 'goal_penalty',
                label: 'Pokutový kop',
                eventType: 'goal',
              },
              {
                _id: 'failed_goal_penalty',
                label: 'Nepremenený pokutový kop',
                eventType: 'failed_goal',
              },
              {
                _id: 'dropped',
                label: 'Vlastný gól',
                eventType: 'goal',
              },
              {
                _id: 'goal_contumation',
                label: 'Kontumačný gól',
                eventType: 'goal',
              },
              {
                _id: 'goal_shootout',
                label: 'Pokutový kop - penáltový rozstrel',
                eventType: 'goal',
              },
              {
                _id: 'substitution',
                label: 'Striedanie',
                eventType: 'substitution',
              },
              {
                _id: 'yellow_card',
                label: 'Žltá karta',
                eventType: 'yellow_card',
              },
              {
                _id: 'second_yellow_card',
                label: 'Druhá žltá karta',
                eventType: 'second_yellow_card',
              },
              {
                _id: 'red_card',
                label: 'Červená karta',
                eventType: 'red_card',
              },
            ]}
          />
        </Segment>
      )}
      {!event.closed &&
        (!event.public || isPlayer || isCreator) &&
        event.players &&
        event.players.length > 0 && (
          <Segment
            raised
            header={
              <SegmentHeader withSeparator size="xs">
                {__('Potvrdená účasť')}
              </SegmentHeader>
            }
          >
            <InfoBlocks>
              {event.players.map(player =>
                createInfoBlock('player', `${player.name} ${player.surname}`),
              )}
            </InfoBlocks>
          </Segment>
        )}
    </>
  );
};

export default EventHeader;
