import { getListResults } from 'redux-list';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import { entitiesSelector } from '../../containers/App/selectors';

export const meTournamentsSelector = (listName: string) =>
  createSelector(
    entitiesSelector,
    getListResults(listName),
    (entities, ids) => {
      return at(entities.tournaments, ids || []);
    },
  );

export const tournamentByIdSelector = (tournamentId: string) =>
  createSelector(
    entitiesSelector,
    entities => entities.tournaments[tournamentId] || null,
  );
