import { ContentLoader } from '@sportnet/ui/lib/Loader';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';
import { useAsyncData } from 'ssr-service';
import { withTheme } from 'styled-components';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import HobbyApi from '../../api/HobbyApi';
import AdminLayoutContext from '../../components/AdminLayout/context';
import { Content } from '../../components/Layout';
import config from '../../config';
import { RootState } from '../../configureStore';
import Loading from '../../containers/Loading';
import { loadTournamentById } from '../../containers/Tournaments/actions';
import { tournamentByIdSelector } from '../../containers/Tournaments/selectors';
import { Event } from '../../library/Event';
import { SportGround } from '../../library/SportGround';
import { Team } from '../../library/Tournament';
import { ITheme } from '../../theme/theme';
import __ from '../../utilities/__';
import Form from './form';

const FORM_NAME = 'TOURNAMENT_EVENT_FORM';

type RouteProps = RouteComponentProps<
  { tournamentId: string; eventId: string },
  {}
>;

const mapStateToProps = (state: RootState, props: RouteProps) => {
  const selector = formValueSelector(FORM_NAME);
  return {
    homeTeam: selector(state, 'homeTeam') || null,
    awayTeam: selector(state, 'awayTeam') || null,
    tournament: tournamentByIdSelector(props.params.tournamentId)(state),
  };
};

const mapDispatchToProps = {
  loadTournament: thunkToAction(loadTournamentById.action),
};

const TournamentEventEdit: React.FC<
  ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    RouteProps & { theme: ITheme }
> = ({
  router: { push },
  params: { tournamentId, eventId },
  loadTournament,
  tournament,
  homeTeam,
  awayTeam,
  theme,
}) => {
  const [sportGrounds, setSportGrounds] = React.useState<SportGround[]>([]);
  const [sportGroundQuery, setSportGroundQuery] = React.useState('');
  const [sportGroundsAreFetching, setSportGroundFetchingState] = React.useState(
    false,
  );
  const [isFetching, setIsFetching] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [initialValues, setInitialValues] = React.useState<Event | null>(null);

  useAsyncData(async () => {
    loadSportGrounds();
  }, [sportGroundQuery]);

  useAsyncData(async () => {
    await loadTournamentData();
  }, [tournamentId]);

  const loadTournamentData = async () => {
    setIsFetching(true);
    try {
      await loadTournament({ tournamentId });
      const res = await HobbyApi.getTournamentEvent(
        config.APP_SPACE,
        tournamentId,
        eventId,
      );
      setInitialValues(res);
    } catch (e) {
      //
    } finally {
      setIsFetching(false);
    }
  };

  const loadSportGrounds = async () => {
    const params: { q?: string } = {};
    if (sportGroundQuery) {
      params.q = sportGroundQuery;
    }
    setSportGroundFetchingState(true);
    try {
      const { items = [] } = await HobbyApi.getSportGrounds(
        config.APP_SPACE,
        params,
      );
      setSportGrounds(items);
    } catch (e) {
      //
    } finally {
      setSportGroundFetchingState(false);
    }
  };

  const submit = async (values: {
    startDate: string;
    sportGround: { value: string };
    homeTeam: { value: string };
    awayTeam: { value: string };
  }) => {
    setIsSubmitting(true);
    try {
      const data: {
        startDate: string;
        sportGround: {
          _id: string;
        };
        teams: Team[];
      } = {
        startDate: values.startDate,
        sportGround: { _id: values.sportGround.value },
        teams: [],
      };
      const hTeam = (tournament.teams || []).find(
        t => t._id === values.homeTeam.value,
      );
      const aTeam = (tournament.teams || []).find(
        t => t._id === values.awayTeam.value,
      );
      if (hTeam) {
        data.teams.push({
          ...hTeam,
          additionalProperties: { homeaway: 'home' },
        });
      }
      if (aTeam) {
        data.teams.push({
          ...aTeam,
          additionalProperties: { homeaway: 'away' },
        });
      }
      await HobbyApi.updateTournamentEvent(
        config.APP_SPACE,
        tournamentId,
        eventId,
        {},
        data,
      );
      push(`/admin/tournament/${tournamentId}/event/${eventId}`);
    } catch (e) {
      console.error(e);
      alert(__('Zápas sa nepodarilo uložiť'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const transformValues = (values: Event) => {
    const hTeam = (values.teams || []).find(
      t => t.additionalProperties!.homeaway === 'home',
    );
    const aTeam = (values.teams || []).find(
      t => t.additionalProperties!.homeaway === 'away',
    );
    return {
      ...values,
      sportGround: {
        label: values.sportGround.name,
        value: values.sportGround._id,
      },
      ...(hTeam && { homeTeam: { value: hTeam._id, label: hTeam.name } }),
      ...(aTeam && { awayTeam: { value: aTeam._id, label: aTeam.name } }),
    };
  };

  const { setHeaderComponents, headerComponents } = React.useContext(
    AdminLayoutContext,
  );
  const componentHeader = __('Turnaje');
  const componentSubHeader = __('Úprava zápasu');

  if (
    headerComponents.header !== componentHeader ||
    headerComponents.subHeader !== componentSubHeader
  ) {
    setHeaderComponents(componentHeader, componentSubHeader);
  }

  if (isFetching || !tournament) {
    return <Loading />;
  }

  if (!initialValues) {
    return <ContentLoader theme={theme} />;
  }

  return (
    <Content>
      <Form
        form={FORM_NAME}
        onSubmit={submit}
        isSubmitting={isSubmitting}
        sportGrounds={sportGrounds}
        setSportGroundQuery={setSportGroundQuery}
        sportGroundsAreFetching={sportGroundsAreFetching}
        loadSportGrounds={loadSportGrounds}
        teams={tournament.teams || []}
        initialValues={transformValues(initialValues)}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
      />
    </Content>
  );
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTheme,
)(TournamentEventEdit);
