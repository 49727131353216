import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { listReducer } from 'redux-list/lib/ducks';
import {
  authUserReducer,
  entitiesReducer,
  userPreferencesReducer,
} from './containers/App/reducer';

const rootReducer = combineReducers({
  form: formReducer,
  application: combineReducers({
    authUser: authUserReducer,
    userPreferences: userPreferencesReducer,
  }),
  entities: entitiesReducer,
  list: listReducer,
});

export default rootReducer;
