import { rem } from 'polished';
import styled from 'styled-components';

export const DotLabel = styled.div`
  display: flex;
  align-items: center;
  min-width: ${rem(150)};
`;
export const Dot = styled.div<{ color: string }>`
  width: ${rem(10)};
  height: ${rem(10)};
  margin-right: ${rem(10)};
  border-radius: 50%;
  background: ${({ color }) => color};
`;
export const Actions = styled.div`
  padding: 0 0 ${rem(10)} 0;
  display: flex;
  justify-content: flex-end;
`;
