import Button from '@sportnet/ui/lib/Button';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { submit } from 'redux-form';
import { formatUserName } from 'sportnet-utilities';
import { useAsyncData } from 'ssr-service';
import HobbyApi from '../../api/HobbyApi';
import AdminLayoutContext from '../../components/AdminLayout/context';
import BasicTable from '../../components/BasicTable';
import CustomButton from '../../components/CustomButton';
import { Content } from '../../components/Layout';
import config from '../../config';
import { CustomThunkDispatch } from '../../configureStore';
import Loading from '../../containers/Loading';
import { Player } from '../../library/Tournament';
import useWindowSize from '../../utilities/resizeHook';
import __ from '../../utilities/__';
import BasicTournamentTeamForm from './BasicForm';

const BASIC_TOURNAMENT_TEAM_FORM_NAME = 'BASIC_TOURNAMENT_TEAM_FORM';

const TournamentTeam: React.FC<RouteComponentProps<
  { tournamentId: string; teamId: string },
  {}
> & {
  dispatch: CustomThunkDispatch;
}> = ({ params: { tournamentId, teamId }, dispatch, router: { push } }) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isRemovingId, setIsRemovingId] = React.useState<string | null>(null);
  const [isDeletingTeam, setIsDeletingTeam] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState<{
    name: string;
    players: Player[];
  }>({ name: '', players: [] });

  const resizeHook = useWindowSize();

  const [mobileLayout, setMobileLayout] = React.useState(
    window.innerWidth < 600,
  );

  React.useEffect(() => {
    if (resizeHook.innerWidth < 600) {
      setMobileLayout(true);
    } else {
      setMobileLayout(false);
    }
  }, [resizeHook.innerWidth]);

  useAsyncData(async () => {
    await getTournamentTeam();
  }, [tournamentId, teamId]);

  const { setHeaderComponents, headerComponents } = React.useContext(
    AdminLayoutContext,
  );
  const componentHeader = __('Turnaje');
  const componentSubHeader = __('Správa tímu');

  if (
    headerComponents.header !== componentHeader ||
    headerComponents.subHeader !== componentSubHeader
  ) {
    setHeaderComponents(componentHeader, componentSubHeader);
  }

  const deleteTeam = async () => {
    if (window.confirm(__('Skutočne si želáte zmazať tím?'))) {
      setIsDeletingTeam(true);
      try {
        await HobbyApi.removeTeamFromTournament(
          config.APP_SPACE,
          tournamentId,
          teamId,
        );
      } catch (e) {
        alert(__('Tím sa nepodarilo odstrániť.'));
      } finally {
        setIsDeletingTeam(false);
        push(`/admin/tournament/${tournamentId}`);
      }
    }
  };

  const getTournamentTeam = async () => {
    setIsFetching(true);
    try {
      const res = await HobbyApi.getTournamentTeam(
        config.APP_SPACE,
        tournamentId,
        teamId,
      );
      setInitialValues({ name: res.name, players: res.players || [] });
    } catch (e) {
      //
    } finally {
      setIsFetching(false);
    }
  };

  const onBasicFormSubmit = async (values: { name: string }) => {
    setIsSubmitting(true);
    try {
      await HobbyApi.updateTournamentTeam(
        config.APP_SPACE,
        tournamentId,
        teamId,
        {},
        { name: values.name },
      );
    } catch (e) {
      alert(__('Zmeny sa nepodarilo uložiť.'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const removeFromTeam = async (userId: string) => {
    if (window.confirm(__('Skutočne si želáte odstrániť hráča?'))) {
      setIsRemovingId(userId);
      try {
        await HobbyApi.removePlayerFromTournamentTeam(
          config.APP_SPACE,
          tournamentId,
          teamId,
          userId,
        );
        getTournamentTeam();
      } catch (e) {
        alert(__('Hráča sa nepodarilo z tímu odstrániť.'));
      } finally {
        setIsRemovingId(null);
      }
    }
  };

  if (isFetching) {
    return <Loading />;
  }

  return (
    <Content>
      <Segment
        raised
        header={
          <SegmentHeader size="xs" withSeparator>
            {__('Základné údaje')}
          </SegmentHeader>
        }
      >
        <BasicTournamentTeamForm
          form={BASIC_TOURNAMENT_TEAM_FORM_NAME}
          onSubmit={onBasicFormSubmit}
          initialValues={initialValues}
        />
        <div>
          <Button
            primary
            loading={isSubmitting}
            block={mobileLayout}
            onClick={() => {
              dispatch(submit(BASIC_TOURNAMENT_TEAM_FORM_NAME));
            }}
          >
            {__('Uložiť')}
          </Button>
          {mobileLayout ? (
            <div style={{ height: rem(5), display: 'block' }} />
          ) : (
            <div style={{ width: rem(5), display: 'inline-block' }} />
          )}
          <Button
            danger
            loading={isDeletingTeam}
            block={mobileLayout}
            onClick={deleteTeam}
          >
            {__('Zmazať tím')}
          </Button>
        </div>
      </Segment>
      {initialValues.players.length > 0 && (
        <Segment
          raised
          header={
            <SegmentHeader size="xs" withSeparator>
              {__('Správa hráčov')}
            </SegmentHeader>
          }
        >
          <BasicTable
            rows={initialValues.players}
            compact
            renderRow={i => [
              formatUserName(i),
              <div key={i._id} style={{ textAlign: 'right' }}>
                <CustomButton
                  danger
                  loading={isRemovingId === i._id}
                  onClick={() => {
                    removeFromTeam(i._id);
                  }}
                >
                  {__('Odstrániť z tímu')}
                </CustomButton>
              </div>,
            ]}
            rowKey="_id"
          />
        </Segment>
      )}
    </Content>
  );
};

export default compose(withRouter, connect())(TournamentTeam);
