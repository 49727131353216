import { ContentLoader } from '@sportnet/ui/lib/Loader';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { Theme } from '@sportnet/ui/lib/Themes/styled-components';
import * as React from 'react';
import styled, { withTheme } from 'styled-components';
import { Team } from '../../library/Event';
import { IResultsTableItem } from '../../library/Tournament';
import __ from '../../utilities/__';
import BasicTable from '../BasicTable';

interface OwnProps {
  teams: Team[];
  resultsTable: IResultsTableItem[];
  isFetching?: boolean | null;
}

type Props = OwnProps & Theme;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Header = styled.div``;

class CompetitionPartTable extends React.PureComponent<Props> {
  render() {
    const results = (this.props.resultsTable || []).map(result => ({
      ...result,
      _id: result.team._id,
    }));

    return (
      <Segment
        raised
        header={
          <SegmentHeader withSeparator size="xs">
            <HeaderWrapper>
              <Header>{__('Aktuálne poradie')}</Header>
            </HeaderWrapper>
          </SegmentHeader>
        }
      >
        {!!this.props.isFetching ? (
          <ContentLoader theme={this.props.theme} />
        ) : (
          <BasicTable
            columns={[
              {
                header: '',
                width: 30,
              },
              {
                header: __('Tím'),
              },
              {
                header: __('Z'),
                width: 30,
              },
              {
                header: __('V'),
                width: 30,
              },
              {
                header: __('R'),
                width: 30,
              },
              {
                header: __('P'),
                width: 30,
              },
              {
                header: __('Skóre'),
                width: 60,
              },
              {
                header: __('B'),
                width: 30,
              },
            ]}
            rows={results}
            rowKey="_id"
            renderRow={(result, idx) => [
              idx + 1,
              result.team.name,
              result.stats.matches.played,
              result.stats.matches.won,
              result.stats.matches.draw,
              result.stats.matches.lost,
              `${result.stats.goals.given}:${result.stats.goals.received}`,
              result.stats.points,
            ]}
          />
        )}
      </Segment>
    );
  }
}

export default withTheme(CompetitionPartTable);
