import Button from '@sportnet/ui/lib/Button';
import Icon from '@sportnet/ui/lib/Icon';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Text = styled.div`
  padding-bottom: ${rem(10)};
  font-weight: 200;
  font-size: ${rem(26)};
  color: #666;
`;

const Actions = styled.div`
  padding: 0 0 ${rem(10)} 0;
  display: flex;
  justify-content: flex-end;
`;

interface OwnProps {
  label: string;
  action?: {
    label: string;
    onClick: () => void;
  };
}

const NoItemsFround: React.FC<OwnProps> = ({ label, action }) => {
  return (
    <Wrapper>
      <Icon color="#dedede" size="xxl" name="search" />
      <Text>{label}</Text>
      {action && (
        <Actions>
          <Button onClick={action.onClick} primary>
            {action.label}
          </Button>
        </Actions>
      )}
    </Wrapper>
  );
};

export default NoItemsFround;
