import { commit, CommitError, getListParameters } from 'redux-list';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import {
  CustomThunkAction,
  ExtraArgumentType,
  RootState,
} from '../../configureStore';
import { NormalizedEntities, Pager } from '../../library/App';
import normalizeEntities from '../../utilities/normalizeEntities';
import { replaceEntities } from '../App/actions';

const create = actionCreatorFactory('APP');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

interface EventsParameters {
  dateFrom: string;
  dateTo: string;
}
interface TournamentsParameters {
  dateFrom: string;
  dateTo: string;
}

export const loadPublicEvents = createAsync<
  EventsParameters,
  Pick<NormalizedEntities<'events'>, 'results'> & Pager
>(
  'GET_PUBLIC_EVENTS',
  async ({ dateFrom, dateTo }, dispatch, getState, { HobbyApi }) => {
    const response = await HobbyApi.getPublicEvents(config.APP_SPACE, {
      dateFrom,
      dateTo,
    });
    const { entities, results } = normalizeEntities(
      'events',
      response.items || [],
    );
    dispatch(replaceEntities(entities));
    return {
      results,
      offset: 0,
      total: (response.items || []).length,
      limit: 0,
    };
  },
);

export const loadEventsList = (): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(config.PUBLIC_EVENTS_LIST_NAME)(
      getState(),
    );
    return dispatch(
      commit.action({
        listName: config.PUBLIC_EVENTS_LIST_NAME,
        load: async () => {
          try {
            const { results, offset, total } = await dispatch(
              loadPublicEvents.action(parameters as EventsParameters),
            );
            return {
              total,
              offset,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadPublicTournaments = createAsync<
  TournamentsParameters,
  Pick<NormalizedEntities<'tournaments'>, 'results'> & Pager
>(
  'GET_PUBLIC_TOURNAMENTS',
  async ({ dateFrom, dateTo }, dispatch, getState, { HobbyApi }) => {
    const response = await HobbyApi.getPublicTournaments(config.APP_SPACE, {
      dateFrom,
      dateTo,
    });
    const { entities, results } = normalizeEntities(
      'tournaments',
      response.items || [],
    );
    dispatch(replaceEntities(entities));
    return {
      results,
      offset: 0,
      total: (response.items || []).length,
      limit: 0,
    };
  },
);

export const loadTournamentsList = (): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(config.PUBLIC_TOURNAMENTS_LIST_NAME)(
      getState(),
    );
    return dispatch(
      commit.action({
        listName: config.PUBLIC_TOURNAMENTS_LIST_NAME,
        load: async () => {
          try {
            const { results, offset, total } = await dispatch(
              loadPublicTournaments.action(parameters as TournamentsParameters),
            );
            return {
              total,
              offset,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};
