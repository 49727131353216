import Button from '@sportnet/ui/lib/Button';
import Icon from '@sportnet/ui/lib/Icon';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/lib/Modal';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { submit } from 'redux-form';
import { formatUserName } from 'sportnet-utilities';
import styled, { withTheme } from 'styled-components';
import BasicTable from '../../components/BasicTable';
import CustomButton from '../../components/CustomButton';
import CustomIcon from '../../components/Icons';
import { CustomThunkDispatch } from '../../configureStore';
import { Team } from '../../library/Tournament';
import { ITheme } from '../../theme/theme';
import __ from '../../utilities/__';
import TeamCreationForm from './teamCreationForm';

const TEAM_CREATION_FORM_NAME = 'TEAM_CREATION_FORM';

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface OwnProps {
  onTeamCreationSubmit: (values: { name: string }) => void;
  createdTeam: null | { name: string };
  teams: Team[];
  selectedTeamId: string | null;
  setSelectedTeamId: (id: string) => void;
  isCreator: boolean;
  isSubmitting: boolean;
  teamIdProcessing: string | null;
  mobileLayout: boolean;
  toggleInvitationModal: () => void;
}

type Props = OwnProps & { dispatch: CustomThunkDispatch } & {
  theme: ITheme;
} & RouteComponentProps<{ tournamentId: string }, {}>;

const TournamentTeams: React.FC<Props> = ({
  dispatch,
  onTeamCreationSubmit,
  createdTeam,
  teams,
  selectedTeamId,
  setSelectedTeamId,
  theme,
  isSubmitting,
  teamIdProcessing,
  isCreator,
  mobileLayout,
  toggleInvitationModal,
  router: { push },
  params: { tournamentId },
}) => {
  const [teamCreationModalOpened, setTeamCreationModalOpened] = React.useState(
    false,
  );
  const [collapsedRows, setCollapsedRows] = React.useState<string[]>([]);

  const toggleTeamCreationModal = () => {
    setTeamCreationModalOpened(!teamCreationModalOpened);
  };

  const toggleCollapse = (teamId: string) => {
    const index = collapsedRows.indexOf(teamId);

    if (index > -1) {
      setCollapsedRows(
        collapsedRows.reduce((acc, r, idx) => {
          if (idx === index) {
            return acc;
          }
          return [...acc, r];
        }, []),
      );
    } else {
      const arr = [...collapsedRows];
      arr.push(teamId);
      setCollapsedRows(arr);
    }
  };

  const teamsList: Array<{ name: string }> = [
    ...teams.reduce(
      (acc, t) => [
        ...acc,
        t,
        ...(t.players || []).map(p => ({ ...p, teamId: t._id })),
      ],
      [],
    ),
  ];
  if (createdTeam) {
    teamsList.push(createdTeam);
  }

  return (
    <>
      <Segment
        raised
        header={
          <SegmentHeader withSeparator size="xs">
            {__('Tímy')}
          </SegmentHeader>
        }
      >
        {teamsList.length > 0 && (
          <>
            <BasicTable
              rows={teamsList}
              compact
              onClickRow={i => {
                if ((i.players || []).length > 0) {
                  toggleCollapse(i._id);
                }
              }}
              renderRow={i => {
                const active = i._id === selectedTeamId;
                if (i.surname && i.teamId && collapsedRows.includes(i.teamId)) {
                  return [
                    <div
                      key={i._id}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: rem(29),
                      }}
                    >
                      <CustomIcon size={20} color="#DDDDDD" name="player" />
                      <div style={{ width: rem(10) }} />
                      <span>{formatUserName(i)}</span>
                    </div>,
                    <div key={i._id} />,
                  ];
                } else if (i.surname) {
                  return [];
                }
                return [
                  <div
                    key={i._id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    {i.name}&nbsp;
                    <span style={{ color: '#AAAAAA' }}>
                      ({(i.players || []).length})
                    </span>
                    &nbsp;
                    {(i.players || []).length > 0 ? (
                      <Icon
                        name={
                          collapsedRows.includes(i._id)
                            ? 'arrow-top'
                            : 'arrow-down'
                        }
                      />
                    ) : (
                      <div />
                    )}
                  </div>,
                  <div style={{ textAlign: 'right' }} key={i}>
                    {selectedTeamId || active ? null : (
                      <CustomButton
                        {...(!mobileLayout && { minWidth: 110 })}
                        danger={active}
                        loading={teamIdProcessing === i._id}
                        onClick={(
                          e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                        ) => {
                          e.stopPropagation();
                          setSelectedTeamId(i._id);
                        }}
                      >
                        {active ? (
                          <ButtonContent>
                            <Icon name="close" color={theme.color.danger} />{' '}
                            {!mobileLayout && <span>{__('Opustiť tím')}</span>}
                          </ButtonContent>
                        ) : (
                          <ButtonContent>
                            <Icon name="plus" color={theme.color.primary} />{' '}
                            {!mobileLayout && <span>{__('Pridať sa')}</span>}
                          </ButtonContent>
                        )}
                      </CustomButton>
                    )}

                    {isCreator && (
                      <>
                        <CustomButton
                          {...(!mobileLayout && { minWidth: 130 })}
                          onClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                          ) => {
                            e.stopPropagation();
                            push(
                              `/admin/tournament/${tournamentId}/team/${i._id}`,
                            );
                          }}
                        >
                          <ButtonContent>
                            <Icon name="pencil" color={theme.color.primary} />{' '}
                            {!mobileLayout && (
                              <span>{__('Spravovať tím')}</span>
                            )}
                          </ButtonContent>
                        </CustomButton>
                      </>
                    )}
                  </div>,
                ];
              }}
              rowKey="_id"
              columns={[{ header: __('Názov tímu') }, { header: '' }]}
            />
            <div style={{ height: rem(10) }} />
          </>
        )}
        {mobileLayout ? (
          <div>
            <CustomButton onClick={toggleTeamCreationModal} block>
              {__('Vytvoriť nový tím')}
            </CustomButton>
            {isCreator && (
              <CustomButton block onClick={toggleInvitationModal}>
                {__('Pozvať hráča')}
              </CustomButton>
            )}
          </div>
        ) : (
          <div>
            <CustomButton onClick={toggleTeamCreationModal}>
              {__('Vytvoriť nový tím')}
            </CustomButton>
            {isCreator && (
              <>
                &nbsp;
                <CustomButton onClick={toggleInvitationModal}>
                  {__('Pozvať hráča')}
                </CustomButton>
              </>
            )}
          </div>
        )}
      </Segment>
      <Modal
        isOpen={teamCreationModalOpened}
        handleClose={toggleTeamCreationModal}
      >
        <ModalContent>
          <TeamCreationForm
            onSubmit={onTeamCreationSubmit}
            form={TEAM_CREATION_FORM_NAME}
          />
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button onClick={toggleTeamCreationModal}>{__('Zavrieť')}</Button>
            &nbsp;
            <Button
              primary
              loading={isSubmitting}
              onClick={() => {
                dispatch(submit(TEAM_CREATION_FORM_NAME));
                if (!isSubmitting) {
                  toggleTeamCreationModal();
                }
              }}
            >
              {__('Vytvoriť tím')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
    </>
  );
};

export default compose<React.FC<OwnProps>>(
  withTheme,
  withRouter,
  connect(),
)(TournamentTeams);
