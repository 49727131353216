import Button from '@sportnet/ui/lib/Button';
import SportnetIcon from '@sportnet/ui/lib/Icon';
import { Loader } from '@sportnet/ui/lib/Loader';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/lib/Modal';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { format } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, destroy, formValueSelector, submit } from 'redux-form';
import { formatUserName, getProp } from 'sportnet-utilities';
import styled, { css, withTheme } from 'styled-components';
import Icons from '../../components/Icons';
import { createInfoBlock } from '../../components/InfoBlock';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import { Event, ProtocolEvent } from '../../library/Event';
import { ITheme } from '../../theme/theme';
import normalizeEntities from '../../utilities/normalizeEntities';
import useWindowSize from '../../utilities/resizeHook';
import __ from '../../utilities/__';
import { replaceEntities } from '../App/actions';
import CardActionForm from './ActionForms/Card';
import GoalActionForm from './ActionForms/Goal';
import SubstitutionActionForm from './ActionForms/Substitution';
import Timer from './timer';

const ACTION_FORM_NAME = 'ACTION_FORM';

const tuple = <T extends string[]>(...args: T) => args;
export const EVENTS = tuple(
  'goal',
  'yellow_card',
  'second_yellow_card',
  'red_card',
  'substitution',
);

type EventTypes = typeof EVENTS[number];

const getEventLabel = (protocolEvent: ProtocolEvent) => {
  switch (protocolEvent.eventType) {
    case 'goal':
      if (protocolEvent.type === 'dropped') {
        return __('Vlastný gól');
      }
      return (
        <>
          {__('Gól')}
          {protocolEvent.assist && (
            <>
              <br />
              <i>{`${__('Asistencia')}: ${protocolEvent.assist.name}`}</i>
            </>
          )}
        </>
      );
    case 'yellow_card':
      return (
        <>
          {__('Žltá karta')}
          <br />
          <i>{`${__('Dôvod')}: ${protocolEvent.reason}`}</i>
        </>
      );
    case 'second_yellow_card':
      return (
        <>
          {__('Druhá žltá karta')}
          <br />
          <i>{`${__('Dôvod')}: ${protocolEvent.reason}`}</i>
        </>
      );
    case 'red_card':
      return (
        <>
          {__('Červená karta')}
          <br />
          <i>{`${__('Dôvod')}: ${protocolEvent.reason}`}</i>
        </>
      );
    case 'substitution':
      return (
        <>
          {__('Striedanie')}
          <br />
          <i>{`${__('Striedajúci hráč')}: ${
            protocolEvent.replacement!.name
          }`}</i>
        </>
      );
    default:
      return null;
  }
};

const PlayerWrapper = styled.div<{ onClick?: () => void }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${rem(46)};
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;
const PlayerName = styled.div`
  display: flex;
  align-items: center;
`;
const PlayerActions = styled.div`
  display: flex;
`;
const Action = styled.div`
  padding: ${rem(5)};
  margin: 0 ${rem(1)};
  border: 1px solid #eee;
  border-radius: ${rem(7)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f9f9f9;
  }
`;
const PlayerEvents = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  margin: ${rem(10)} ${rem(-10)};
  width: calc(100% + ${rem(20)});
  padding: 0 ${rem(10)};
  background: #f9f9f9;
`;
const PlayerEvent = styled.div`
  padding: ${rem(10)} 0;
  display: flex;
  justify-content: space-between;
`;
const EventInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const EventComponent = styled.div`
  padding-right: ${rem(10)};
`;
const EventIcon = styled(EventComponent)``;
const EventTime = styled(EventComponent)`
  width: ${rem(50)};
`;
const EventLabel = styled(EventComponent)`
  & > i {
    font-size: ${rem(12)};
  }
`;

interface OwnProps {
  event: Event;
  updateEvent: (data: any) => Promise<any>;
}

const mapStateToProps = (state: RootState) => {
  const selector = formValueSelector(ACTION_FORM_NAME);
  return {
    activePlayer: selector(state, 'player'),
    team: selector(state, 'team'),
  };
};

const Protocol: React.FC<OwnProps & {
  dispatch: CustomThunkDispatch;
} & ReturnType<typeof mapStateToProps> & { theme: ITheme }> = ({
  event,
  dispatch,
  activePlayer,
  team,
  theme,
  updateEvent,
}) => {
  const resizeHook = useWindowSize();

  const [mobileLayout, setMobileLayout] = React.useState(
    window.innerWidth < 600,
  );

  React.useEffect(() => {
    if (resizeHook.innerWidth < 600) {
      setMobileLayout(true);
    } else {
      setMobileLayout(false);
    }
  }, [resizeHook.innerWidth]);

  const [playerEventsVisible, setPlayerEventsVisible] = React.useState<
    string[]
  >([]);

  const [actionIsSubmitting, setActionIsSubmitting] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [trashingEvent, setTrashingEvent] = React.useState<null | {
    playerId: string;
    eventIdx: number;
  }>(null);

  const [currentTime, setCurrentTime] = React.useState(0);

  const [
    activeActionType,
    setActiveActionType,
  ] = React.useState<null | EventTypes>(null);

  const togglePlayerEvents = (userId: string) => {
    if (playerEventsVisible.includes(userId)) {
      setPlayerEventsVisible(
        playerEventsVisible.reduce(
          (acc, i) => (i === userId ? acc : [...acc, i]),
          [],
        ),
      );
    } else {
      setPlayerEventsVisible([...playerEventsVisible, userId]);
    }
  };

  const toggleActionModal = (
    eventType: null | EventTypes,
    player?: null | { _id: string; name: string },
    actionTeam?: string,
  ) => {
    if (eventType && player && actionTeam) {
      if (currentTime) {
        dispatch(change(ACTION_FORM_NAME, 'eventTime', currentTime));
      }
      dispatch(change(ACTION_FORM_NAME, 'player', player));
      dispatch(change(ACTION_FORM_NAME, 'team', actionTeam));
    } else {
      dispatch(destroy(ACTION_FORM_NAME));
    }
    setActiveActionType(eventType);
  };

  const onSubmitActionForm = async (values: any) => {
    if (activeActionType) {
      setActionIsSubmitting(true);
      const data = { ...values };
      if (data.assist) {
        const assist = (event.players || []).find(p => p._id === values.assist);
        if (assist) {
          data.assist = { _id: assist._id, name: formatUserName(assist) };
        }
      }
      if (data.replacement) {
        const replacement = (event.players || []).find(
          p => p._id === values.replacement,
        );
        if (replacement) {
          data.replacement = {
            _id: replacement._id,
            name: formatUserName(replacement),
          };
        }
      }
      try {
        const {
          _id,
          appSpace,
          competition,
          competitionPart,
          createdBy,
          nominations,
          players,
          teams,
          season,
          matchId,
          settings,
          tournamentId,
          resultsTable,
          level,
          surface,
          rules,
          description,
          public: eventIsPublic,
          registrationDueDate,
          invitations,
          ...eventData
        } = event;
        const minutes = String(Math.floor((data.eventTime || 0) / 60)).padStart(
          2,
          '0',
        );
        const seconds = String((data.eventTime || 0) % 60).padStart(2, '0');
        const eventTime = `${minutes}:${seconds}`;
        const modifiedEvent = await updateEvent({
          ...eventData,
          protocol: {
            events: [
              ...getProp(event, ['protocol', 'events'], []),
              { ...data, eventType: activeActionType, eventTime },
            ],
          },
        });
        const { entities } = normalizeEntities('events', [modifiedEvent]);
        dispatch(replaceEntities(entities));
        toggleActionModal(null);
      } catch (e) {
        alert(__('Priebeh zápasu sa nepodarilo uložiť'));
      } finally {
        setActionIsSubmitting(false);
      }
    }
  };

  const changeClosedStatus = async (closedStatus: boolean) => {
    try {
      setIsClosing(true);
      const {
        _id,
        appSpace,
        competition,
        competitionPart,
        createdBy,
        nominations,
        players,
        teams,
        season,
        matchId,
        settings,
        tournamentId,
        resultsTable,
        surface,
        level,
        description,
        registrationDueDate,
        rules,
        public: eventIsPublic,
        invitations,
        ...eventData
      } = event;
      const modifiedEvent = await updateEvent({
        ...eventData,
        closed: closedStatus,
      });
      const { entities } = normalizeEntities('events', [modifiedEvent]);
      dispatch(replaceEntities(entities));
    } catch (e) {
      alert(__('Stav zápasu sa nepodarilo zmeniť.'));
    } finally {
      setIsClosing(false);
    }
  };

  const removeProtocolEvent = async (playerId: string, idx: number) => {
    const playerEvents = getProp(event, ['protocol', 'events'], []).filter(
      (e: any) => e.player._id === playerId,
    );
    const protocolEvents = getProp(event, ['protocol', 'events'], []).filter(
      (e: any) => e.player._id !== playerId,
    );
    delete playerEvents[idx];
    const newEvents = [...protocolEvents, ...playerEvents].filter(i => i);
    try {
      setTrashingEvent({ playerId, eventIdx: idx });
      const {
        _id,
        appSpace,
        competition,
        competitionPart,
        createdBy,
        nominations,
        players,
        teams,
        season,
        matchId,
        settings,
        tournamentId,
        resultsTable,
        surface,
        level,
        description,
        registrationDueDate,
        rules,
        public: eventIsPublic,
        invitations,
        ...eventData
      } = event;
      const modifiedEvent = await updateEvent({
        ...eventData,
        protocol: {
          events: newEvents,
        },
      });
      const { entities } = normalizeEntities('events', [modifiedEvent]);
      dispatch(replaceEntities(entities));
    } catch (e) {
      alert(__('Udalosť sa nepodarilo odstrániť'));
    } finally {
      setTrashingEvent(null);
    }
  };

  const getActionForm = (actionType: EventTypes | null) => {
    const nomination = (event.nominations || []).find(n => n.teamId === team);
    const commonProps = {
      form: ACTION_FORM_NAME,
      onSubmit: onSubmitActionForm,
      players: nomination
        ? (nomination.athletes || []).filter(
            a => activePlayer && a.sportnetUser._id !== activePlayer._id,
          )
        : [],
    };
    if (actionType === 'goal') {
      return <GoalActionForm {...commonProps} />;
    } else if (actionType === 'yellow_card') {
      return <CardActionForm {...commonProps} />;
    } else if (actionType === 'second_yellow_card') {
      return <CardActionForm {...commonProps} />;
    } else if (actionType === 'red_card') {
      return <CardActionForm {...commonProps} />;
    } else if (actionType === 'substitution') {
      return <SubstitutionActionForm {...commonProps} />;
    }
    return null;
  };

  const actionForm = getActionForm(activeActionType);

  return (
    <>
      {!event.closed && (
        <Timer
          onToggle={(state, seconds) => {
            setCurrentTime(seconds);
          }}
          initialData={{
            ongoing: false,
            startDate: format(new Date()),
            seconds: 0,
          }}
        />
      )}
      {(event.nominations || []).map(n => {
        const nominationTeam = (event.teams || []).find(
          t => t._id === n.teamId,
        );
        return (
          <Segment
            key={n.teamId}
            raised
            header={
              <SegmentHeader size="xs" withSeparator>
                {nominationTeam && nominationTeam.name}
              </SegmentHeader>
            }
          >
            {n.athletes.map(a => {
              const collapsed = playerEventsVisible.includes(
                a.sportnetUser._id,
              );
              const playerEvents = getProp(
                event,
                ['protocol', 'events'],
                [],
              ).filter(
                (e: ProtocolEvent) =>
                  e.player &&
                  e.player._id === a.sportnetUser._id &&
                  e.team === n.teamId,
              );
              return createInfoBlock(
                null,
                <div>
                  <PlayerWrapper
                    {...(playerEvents.length > 0 && {
                      onClick: () => {
                        togglePlayerEvents(a.sportnetUser._id);
                      },
                    })}
                  >
                    <PlayerName>
                      {a.sportnetUser.name}&nbsp;
                      {playerEvents.length > 0 && (
                        <SportnetIcon
                          name={!collapsed ? 'arrow-top' : 'arrow-down'}
                        />
                      )}
                    </PlayerName>
                    {!event.closed && (
                      <PlayerActions>
                        {EVENTS.map((e: EventTypes) => (
                          <Action
                            key={e}
                            onClick={(ev: React.MouseEvent<HTMLDivElement>) => {
                              ev.stopPropagation();
                              toggleActionModal(e, a.sportnetUser, n.teamId);
                            }}
                          >
                            <Icons name={e} />
                          </Action>
                        ))}
                      </PlayerActions>
                    )}
                  </PlayerWrapper>
                  <PlayerEvents visible={!collapsed}>
                    {playerEvents.map((e: any, idx: number) => {
                      return (
                        <PlayerEvent key={e.eventTime}>
                          <EventInfo>
                            <EventIcon>
                              <Icons name={e.eventType} />
                            </EventIcon>
                            <EventTime>{e.eventTime}</EventTime>
                            <EventLabel>{getEventLabel(e)}</EventLabel>
                          </EventInfo>
                          {!event.closed && (
                            <div
                              {...(!trashingEvent && {
                                style: { cursor: 'pointer' },
                                onClick: () => {
                                  removeProtocolEvent(a.sportnetUser._id, idx);
                                },
                              })}
                            >
                              {trashingEvent &&
                              trashingEvent.eventIdx === idx &&
                              trashingEvent.playerId === a.sportnetUser._id ? (
                                <Loader theme={theme} />
                              ) : (
                                <SportnetIcon
                                  size={16}
                                  color="#aaa"
                                  name="trash"
                                />
                              )}
                            </div>
                          )}
                        </PlayerEvent>
                      );
                    })}
                  </PlayerEvents>
                </div>,
                '',
                true,
              );
            })}
          </Segment>
        );
      })}
      <div style={{ textAlign: 'right' }}>
        {!event.closed ? (
          <Button
            loading={isClosing}
            primary
            block={mobileLayout}
            onClick={() => {
              changeClosedStatus(true);
            }}
          >
            {__('Uzavrieť stretnutie')}
          </Button>
        ) : (
          <Button
            loading={isClosing}
            primary
            block={mobileLayout}
            onClick={() => {
              changeClosedStatus(false);
            }}
          >
            {__('Otvoriť stretnutie')}
          </Button>
        )}
      </div>
      <Modal
        isOpen={!!activeActionType}
        handleClose={() => {
          toggleActionModal(null);
        }}
      >
        <ModalContent>{actionForm}</ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button
              onClick={() => {
                toggleActionModal(null);
              }}
            >
              {__('Zavrieť')}
            </Button>
            &nbsp;
            <Button
              loading={actionIsSubmitting}
              onClick={() => {
                dispatch(submit(ACTION_FORM_NAME));
              }}
              primary
            >
              {__('Potvrdiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
    </>
  );
};

export default compose<React.FC<OwnProps>>(
  withTheme,
  connect(mapStateToProps),
)(Protocol);
