import * as React from 'react';

export default {
  trophy: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g transform="translate(-301 -255)">
        <rect
          fill="none"
          width={size}
          height={size}
          transform="translate(301 255)"
        />
        <path
          fill={color}
          d="M635.04,534.4v-.777a2.226,2.226,0,0,0-2.224-2.223H631.04v-2.721a7.094,7.094,0,0,0,3.464-3.674.921.921,0,0,0,.118-.059A12.986,12.986,0,0,0,640.04,514.4a1,1,0,0,0-1-1h-3.019a.965.965,0,0,0-.981-1h-14a1,1,0,0,0-.751.34.989.989,0,0,0-.23.66H617.04a1,1,0,0,0-1,1,12.934,12.934,0,0,0,5.547,10.623,7.1,7.1,0,0,0,3.453,3.656V531.4h-1.777a2.224,2.224,0,0,0-2.223,2.225v.775a1,1,0,0,0,0,2h14a1,1,0,0,0,0-2Zm.039-12.515c.132-1.657.479-4.571.717-6.485h2.2A10.991,10.991,0,0,1,635.079,521.885ZM618.085,515.4h2.2c.239,1.929.59,4.872.719,6.5A10.938,10.938,0,0,1,618.085,515.4Zm4.954,6.945c-.1-1.727-.608-5.917-.863-7.945H633.9c-.255,2.03-.767,6.226-.864,7.98a5,5,0,1,1-10-.035Zm4,6.961a7.349,7.349,0,0,0,.98.094h.04a7.348,7.348,0,0,0,.98-.094V531.4h-2Zm-4,4.319a.224.224,0,0,1,.225-.225h9.551a.224.224,0,0,1,.224.225v.775h-10Z"
          transform="translate(-315.04 -257.4)"
        />
      </g>
    </svg>
  ),
  calendar: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g transform="translate(-61 -255)">
        <rect
          fill="none"
          width="24"
          height="24"
          transform="translate(61 255)"
        />
        <path
          fill={color}
          d="M637.04,515.4h-4v-1a1,1,0,0,0-2,0v1h-6v-1a1,1,0,0,0-2,0v1h-4a1,1,0,0,0-1,1v18a1,1,0,0,0,1,1h18a1,1,0,0,0,1-1v-18A1,1,0,0,0,637.04,515.4Zm-14,2v1a1,1,0,0,0,2,0v-1h6v1a1,1,0,0,0,2,0v-1h3v4h-16v-4Zm-3,16v-10h16v10Z"
          transform="translate(-555.04 -257.4)"
        />
      </g>
    </svg>
  ),
  clock: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g transform="translate(-141 -255)">
        <rect
          fill="none"
          width={size}
          height={size}
          transform="translate(141 255)"
        />
        <path
          fill={color}
          d="M2102,11a11,11,0,1,1,11,11A11.013,11.013,0,0,1,2102,11Zm2,0a9,9,0,1,0,9-9A9.01,9.01,0,0,0,2104,11Zm12.552,2.894-4-2A1,1,0,0,1,2112,11V5a1,1,0,1,1,2,0v5.382l3.447,1.723a1,1,0,1,1-.895,1.789Z"
          transform="translate(-1960 256)"
        />
      </g>
    </svg>
  ),
  users: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 20"
    >
      <g transform="translate(0 -2)">
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          d="M17,21V19a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4v2"
        />
        <circle
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          cx="4"
          cy="4"
          r="4"
          transform="translate(5 3)"
        />
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          d="M23,21V19a4,4,0,0,0-3-3.87"
        />
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2px"
          d="M16,3.13a4,4,0,0,1,0,7.75"
        />
      </g>
    </svg>
  ),
  surface: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g transform="translate(-272 -265)">
        <rect
          fill="none"
          width={size}
          height={size}
          transform="translate(272 265)"
        />
        <path
          fill={color}
          d="M638.04,513.4h-20a1,1,0,0,0-1,1v20a1,1,0,0,0,1,1h20a1,1,0,0,0,1-1v-20A1,1,0,0,0,638.04,513.4Zm-1,16.293-3.707,3.707h-3.586l7.293-7.293Zm0-5-8.707,8.707h-3.586l12.293-12.293Zm-18-5.586,3.707-3.707h3.586l-7.293,7.293Zm0,5,8.707-8.707h3.586L619.04,527.693Zm13.707-8.707h3.586L619.04,532.693v-3.586Zm4.293.707v3.586L623.333,533.4h-3.586Zm-15.707-.707-2.293,2.293V515.4Zm13.414,18,2.293-2.293V533.4Z"
          transform="translate(-344.04 -247.4)"
        />
      </g>
    </svg>
  ),
  place: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 25"
    >
      <g transform="translate(-21 -254.5)">
        <rect
          fill="none"
          width={size}
          height={size}
          transform="translate(21 255)"
        />
        <path
          fill={color}
          d="M2111.445,23.832C2111.06,23.575,2102,17.45,2102,10a10,10,0,0,1,20,0c0,7.45-9.06,13.575-9.446,13.832a1,1,0,0,1-1.11,0ZM2104,10c0,5.414,6.1,10.364,8,11.77,1.9-1.407,8-6.357,8-11.77a8,8,0,1,0-16,0Zm4,0a4,4,0,1,1,4,4A4,4,0,0,1,2108,10Zm2,0a2,2,0,1,0,2-2A2,2,0,0,0,2110,10Z"
          transform="translate(-2079 255)"
        />
      </g>
    </svg>
  ),
  player: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g transform="translate(-40 -10)">
        <rect
          fill="none"
          width={size}
          height={size}
          transform="translate(40 10)"
        />
        <path
          fill={color}
          d="M16.023,5.028a2.994,2.994,0,0,1,1.136.4l2.6,1.562L19.36,9H17V19H7V9H4.64l-.4-2.01,2.6-1.562a3.011,3.011,0,0,1,1.136-.4A5.316,5.316,0,0,0,12,7a5.32,5.32,0,0,0,4.023-1.972M15.615,3H15a3.7,3.7,0,0,1-3,2A3.7,3.7,0,0,1,9,3H8.385a5,5,0,0,0-2.573.713L2,6l1,5H5V21H19V11h2l1-5L18.188,3.713A5,5,0,0,0,15.615,3Z"
          transform="translate(40 10)"
        />
      </g>
    </svg>
  ),
  goal: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
    >
      <path
        fill={color}
        d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm0 2.5c.7 0 1.4.1 2.2.2L16 4.2l-2.2-1.5c.7-.2 1.5-.2 2.2-.2zm-5.2 1l4.4 3.2.8.6.7-.5 4.4-3.2c2 .8 3.7 2.1 5.1 3.7l-1.7 5.3-.3.8.7.5 4.5 3.3c-.2 2.2-.8 4.2-1.9 6h-6.4l-.3.8-1.7 5.3c-1 .2-2 .3-3.1.3s-2.2-.1-3.2-.4l-1.7-5.3-.3-.9H4.4c-1.1-1.8-1.7-3.8-1.9-6L7 13.8l.7-.5-.3-.8-1.7-5.3c1.4-1.6 3.1-2.9 5.1-3.7zM16 8.7l-.7.5-5.6 4.1-.7.6.3.8 2.2 6.6.3.8H20.5l.3-.8 2.2-6.6.3-.8-.7-.5L17 9.3l-1-.6zm12 1c.7 1.3 1.1 2.7 1.3 4.2l-2.2-1.6.9-2.6zM4 9.8l.8 2.5-2.2 1.6c.3-1.4.7-2.8 1.4-4.1zm12 2l4.2 3-1.6 4.9h-5.2l-1.6-4.9 4.2-3zm6.9 13.7h2.8c-1 1.1-2.3 2-3.6 2.7l.8-2.7zm-16.6 0H9l.8 2.6c-1.2-.7-2.4-1.6-3.5-2.6z"
      />
    </svg>
  ),
  failed_goal: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={color}
        d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
      />
    </svg>
  ),
  yellow_card: (size: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      id="card"
      width={size}
      height={size}
    >
      <path
        fill="#ffc600"
        d="M385.2 512H126.8c-27.5 0-50-22.5-50-50V50c0-27.5 22.5-50 50-50h258.4c27.5 0 50 22.5 50 50v412c0 27.5-22.5 50-50 50z"
      />
    </svg>
  ),
  second_yellow_card: (size: string) => (
    <svg version="1.1" viewBox="0 0 512 512" width={size} height={size}>
      <g>
        <path
          fill="#FF0C00"
          d="M367.6,512c37.6,0,67.8-30.5,67.8-68.2V68.4c0-16.3-5.7-31.5-15.2-43.3L92.3,487.5   c12.5,15.1,31.1,24.5,52.2,24.5H367.6z"
        />
        <path
          fill="#FFC700"
          d="M76.7,68.4v375.4c0,16.6,5.9,31.8,15.6,43.7L420.1,25.2C407.7,9.9,388.9,0,367.6,0H144.4   C106.8,0,76.7,30.8,76.7,68.4z"
        />
      </g>
    </svg>
  ),
  red_card: (size: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      id="card"
      width={size}
      height={size}
    >
      <path
        fill="#FF0C00"
        d="M385.2 512H126.8c-27.5 0-50-22.5-50-50V50c0-27.5 22.5-50 50-50h258.4c27.5 0 50 22.5 50 50v412c0 27.5-22.5 50-50 50z"
      />
    </svg>
  ),
  substitution: (size: string) => (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      width={size}
      height={size}
    >
      <path
        fill="#FF0C00"
        d="M303.1,133.3L151,379.2L0,132.8h303.4L303.1,133.3z"
      />
      <path
        fill="#63C509"
        d="M208.9,378.7l152.1-246l151,246.5H208.6L208.9,378.7z"
      />
    </svg>
  ),
  level: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path fill={color} d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  ),
  gender: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path
        fill={color}
        d="M5.5 22v-7.5H4V9c0-1.1.9-2 2-2h3c1.1 0 2 .9 2 2v5.5H9.5V22h-4zM18 22v-6h3l-2.54-7.63C18.18 7.55 17.42 7 16.56 7h-.12c-.86 0-1.63.55-1.9 1.37L12 16h3v6h3zM7.5 6c1.11 0 2-.89 2-2s-.89-2-2-2-2 .89-2 2 .89 2 2 2zm9 0c1.11 0 2-.89 2-2s-.89-2-2-2-2 .89-2 2 .89 2 2 2z"
      />
    </svg>
  ),
};
