import Button from '@sportnet/ui/lib/Button';
import Header from '@sportnet/ui/lib/Header';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/lib/Modal';
import { isAfter } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { submit } from 'redux-form';
import HobbyApi from '../../api/HobbyApi';
import config from '../../config';
import { CustomThunkDispatch } from '../../configureStore';
import { Event } from '../../library/Event';
import useWindowSize from '../../utilities/resizeHook';
import __ from '../../utilities/__';
import RejectionForm from './rejectForm';

const REJECTION_FORM_NAME = 'REJECTION_FORM';

interface OwnProps {
  reloadEvent: () => void;
  invitationId: string;
}

const EventInvitationStatusChange: React.FC<
  OwnProps & { event: Event; dispatch: CustomThunkDispatch }
> = ({ dispatch, invitationId, event, reloadEvent }) => {
  const resizeHook = useWindowSize();

  const [mobileLayout, setMobileLayout] = React.useState(
    window.innerWidth < 600,
  );
  const [isAccepting, setIsAccepting] = React.useState(false);
  const [isRejecting, setIsRejecting] = React.useState(false);
  const [isRejectionModalOpened, setRejectionModalOpened] = React.useState(
    false,
  );

  React.useEffect(() => {
    if (resizeHook.innerWidth < 600) {
      setMobileLayout(true);
    } else {
      setMobileLayout(false);
    }
  }, [resizeHook.innerWidth]);

  const rejectInvitation = (values: { note: string }) => {
    changeStatus('REJECTED', values.note);
  };

  const changeStatus = async (
    status: 'ACCEPTED' | 'REJECTED',
    note?: string,
  ) => {
    const body: { status: 'ACCEPTED' | 'REJECTED'; note?: string } = { status };
    if (status === 'ACCEPTED') {
      setIsAccepting(true);
    } else if (status === 'REJECTED') {
      setIsRejecting(true);
      body.note = note;
    }
    try {
      await HobbyApi.updateEventInvitationStatus(
        config.APP_SPACE,
        event._id,
        invitationId,
        {},
        body,
      );
      reloadEvent();
    } catch (e) {
      if (e.details.name === 'USER_NOT_INVITED') {
        alert(__('Do udalosti ste neboli pozvaný'));
      } else if (e.details.name === 'EVENT_CAPACITY_REACHED') {
        alert(__('Kapacita udalosti bola naplnená'));
      } else if (e.details.name === 'EVENT_GENDER_RESTRICTION_NOT_MET') {
        alert(__('Nespĺňate obmedzenie pre pohlavie.'));
      }
      console.error(e);
    } finally {
      setIsAccepting(false);
      setIsRejecting(false);
    }
  };

  const toggleRejectionModal = () => {
    setRejectionModalOpened(!isRejectionModalOpened);
  };

  const buttonsDisabled = isAccepting || isRejecting;
  const freeCapacity = event.capacity - (event.players || []).length;
  const startDateIsDue = isAfter(new Date(), new Date(event.startDate));
  const registrationDateIsDue =
    event.registrationDueDate &&
    isAfter(new Date(), new Date(event.registrationDueDate));

  return (
    <>
      {freeCapacity > 0 && !startDateIsDue && !registrationDateIsDue && (
        <>
          {mobileLayout ? (
            <>
              <div>
                <Button
                  loading={isAccepting}
                  disabled={buttonsDisabled}
                  onClick={() => {
                    changeStatus('ACCEPTED');
                  }}
                  block
                  primary
                >
                  {__('Potvrdiť účasť')}
                </Button>
                <div style={{ height: rem(10) }} />
                <Button
                  loading={isRejecting}
                  disabled={buttonsDisabled}
                  onClick={toggleRejectionModal}
                  block
                  danger
                >
                  {__('Odmietnuť pozvánku')}
                </Button>
              </div>
            </>
          ) : (
            <div style={{ textAlign: 'right' }}>
              <Button
                loading={isRejecting}
                disabled={buttonsDisabled}
                onClick={toggleRejectionModal}
                danger
              >
                {__('Odmietnuť pozvánku')}
              </Button>
              &nbsp;
              <Button
                loading={isAccepting}
                disabled={buttonsDisabled}
                onClick={() => {
                  changeStatus('ACCEPTED');
                }}
                primary
              >
                {__('Potvrdiť účasť')}
              </Button>
            </div>
          )}
        </>
      )}
      <Modal
        size="s"
        centered
        handleClose={toggleRejectionModal}
        isOpen={isRejectionModalOpened}
      >
        <ModalContent>
          <Header size="xs" withSeparator>
            {__('Odmietnutie pozvánky')}
          </Header>
          <RejectionForm
            onSubmit={rejectInvitation}
            form={REJECTION_FORM_NAME}
          />
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button onClick={toggleRejectionModal}>{__('Zrušiť')}</Button>
            &nbsp;
            <Button
              loading={isRejecting}
              onClick={() => {
                dispatch(submit(REJECTION_FORM_NAME));
              }}
              primary
            >
              {__('Potvrdiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
    </>
  );
};

export default compose(connect())(EventInvitationStatusChange);
