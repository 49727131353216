import FormField from '@sportnet/ui/lib/FormField/redux-form';
import * as React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import required from '../../utilities/required';
import __ from '../../utilities/__';

const BasicTournamentTeamForm: React.FC<{}> = () => {
  return (
    <>
      <Field
        name="name"
        component={FormField}
        label={__('Názov tímu')}
        validate={[required]}
        required
      />
    </>
  );
};

export default compose(reduxForm({ enableReinitialize: true }))(
  BasicTournamentTeamForm,
);
