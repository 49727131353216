import Button from '@sportnet/ui/lib/Button';
import Header from '@sportnet/ui/lib/Header';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/lib/Modal';
import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import Widgets from 'content/lib/view';
import { format } from 'date-fns';
import { em, rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { change, destroy, submit } from 'redux-form';
import styled from 'styled-components';
import HobbyApi from '../../api/HobbyApi';
import CompetitionPartTable from '../../components/CompetitionPartTable';
import Icons, { IIconName } from '../../components/Icons';
import { Content } from '../../components/Layout';
import TournamentEventsList from '../../components/TournamentEventsList';
import config from '../../config';
import { CustomThunkDispatch } from '../../configureStore';
import Loading from '../../containers/Loading';
import { Event } from '../../library/Event';
import { Tournament } from '../../library/Tournament';
import useWindowSize from '../../utilities/resizeHook';
import __ from '../../utilities/__';
import FriendsList from '../EventDetail/FriendsList';
import InvitationForm from '../EventDetail/invitationForm';
import TournamentTeams from './tournamentTeams';

const INVITATION_FORM_NAME = 'INVITATION_FORM';

const Text = styled.div`
  font-size: ${em(13)};
  line-height: ${em(22)};
  text-align: justify;
  white-space: pre-wrap;
`;

const InfoBlocks = styled.div`
  flex-wrap: wrap;
  display: flex;
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 600px) {
    width: 50%;
  }
`;
const IconWrapper = styled.div`
  padding: ${rem(10)};
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${em(14)};
  justify-content: center;
  margin-top: ${rem(-3)};
`;
const InfoWrapperHeader = styled.div`
  color: ${({ theme }) => theme.color.fadedText};
  font-size: ${em(14)};
`;

interface OwnProps {
  reloadTournament: () => void;
  isCreator: boolean;
  tournament: Tournament;
  events: Event[];
  tournamentEventsTotal: number;
  tournamentEventsIsFetching: boolean;
  currentPlayerAssignment?: {
    _id: string;
    teamId: string;
    name: string;
    surname: string;
  };
}

const TournamentInvitation: React.FC<OwnProps & {
  dispatch: CustomThunkDispatch;
} & RouteComponentProps<{}, {}>> = ({
  tournament,
  reloadTournament,
  isCreator,
  router: { push },
  events,
  dispatch,
  tournamentEventsTotal,
  tournamentEventsIsFetching,
  currentPlayerAssignment,
}) => {
  const resizeHook = useWindowSize();

  const [mobileLayout, setMobileLayout] = React.useState(
    window.innerWidth < 600,
  );

  const [isSubmittingTeam, setIsSubmittingTeam] = React.useState(false);

  const [createdTeam] = React.useState<null | {
    _id: null | string;
    name: string;
  }>(null);

  const [selectedTeamId] = React.useState<string | null>(
    currentPlayerAssignment ? currentPlayerAssignment.teamId : null,
  );

  const [teamIdProcessing, setTeamIdProcessing] = React.useState<string | null>(
    null,
  );

  const [invitationModalOpened, setInvitationModalOpened] = React.useState(
    false,
  );
  const [isInviting, setIsInviting] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  React.useEffect(() => {
    if (resizeHook.innerWidth < 600) {
      setMobileLayout(true);
    } else {
      setMobileLayout(false);
    }
  }, [resizeHook.innerWidth]);

  const toggleInvitationModal = () => {
    setInvitationModalOpened(!invitationModalOpened);
  };

  const submitInvitationForm = async (values: {
    email: string;
    teamId: string;
  }) => {
    setIsInviting(true);
    try {
      await HobbyApi.createTournamentInvitation(
        config.APP_SPACE,
        tournament._id,
        {},
        {
          email: values.email,
          teamId: values.teamId,
        },
      );
      toggleInvitationModal();
      dispatch(destroy(INVITATION_FORM_NAME));
    } catch (e) {
      if (
        e.details &&
        e.details.name === 'PLAYER_ALREADY_INVITED_OR_NOMINATED'
      ) {
        alert(__('Hráč už bol do zápasu pozvaný'));
      } else {
        alert(__('Nastala neznáma chyba. Pozvánka nemohla byť odoslaná'));
      }
    } finally {
      setIsInviting(false);
    }
  };

  const onSubmitInvitationForm = (values: {
    email: string;
    teamId: string;
  }) => {
    submitInvitationForm(values);
  };

  const createTournamentTeam = async (data: { name: string }) => {
    setIsSubmittingTeam(true);
    try {
      await HobbyApi.createTournamentTeam(
        config.APP_SPACE,
        tournament._id,
        {},
        data,
      );
      reloadTournament();
    } catch (e) {
      alert(__('Tím sa nepodarilo uložiť'));
    } finally {
      setIsSubmittingTeam(false);
    }
  };

  const createInfoBlock = (
    iconName: IIconName,
    value: string,
    header?: string,
  ) => (
    <InfoBlock>
      <IconWrapper>
        <Icons size={20} name={iconName} color="#ccc" />
      </IconWrapper>
      <InfoWrapper>
        {header && <InfoWrapperHeader>{header}</InfoWrapperHeader>}
        <div>{value}</div>
      </InfoWrapper>
    </InfoBlock>
  );

  const changeTeamAssignment = async (id: string) => {
    try {
      setTeamIdProcessing(id);
      const selectedTeam = (tournament.teams || []).find(t => t._id === id);
      if (selectedTeam) {
        const body: {
          status: 'ACCEPTED';
          team: { _id?: string; name: string };
        } = {
          status: 'ACCEPTED',
          team: {
            _id: selectedTeam._id,
            name: selectedTeam.name,
          },
        };

        await HobbyApi.joinPublicTournament(
          config.APP_SPACE,
          tournament._id,
          {},
          body,
        );
        reloadTournament();
      }
    } catch (e) {
      if (e.details.name === 'TOURNAMENT_GENDER_RESTRICTION_NOT_MET') {
        alert(__('Nespĺňate obmedzenie pre pohlavie.'));
      } else {
        alert(__('Vyskytla sa neočakávaná chyba'));
      }
    } finally {
      setTeamIdProcessing(null);
    }
  };

  const deleteTournament = async () => {
    if (window.confirm(__('Skutočne si želáte odstrániť turnaj?'))) {
      setIsDeleting(true);
      try {
        await HobbyApi.deleteTournament(config.APP_SPACE, tournament._id);
        push(`/admin/tournaments/admin`);
      } catch (e) {
        alert(__('Turnaj sa nepodarilo odstrániť'));
      } finally {
        setIsDeleting(false);
      }
    }
  };

  if (isSubmittingTeam) {
    return <Loading />;
  }

  return (
    <Content>
      <Segment
        raised
        header={
          <SegmentHeader withSeparator size="xs">
            {__('Popis')}
          </SegmentHeader>
        }
      >
        {typeof tournament.description === 'string' ? (
          <Text>{tournament.description}</Text>
        ) : (
          <Widgets
            items={((tournament.description || {}) as any).widgets || []}
          />
        )}
      </Segment>
      <Segment
        raised
        header={
          <SegmentHeader withSeparator size="xs">
            {__('Informácie')}
          </SegmentHeader>
        }
      >
        <InfoBlocks>
          {createInfoBlock(
            'calendar',
            `${format(new Date(tournament.dateFrom), 'DD.MM.YYYY')} - ${format(
              new Date(tournament.dateTo),
              'DD.MM.YYYY',
            )}`,
            __('Predpokladané trvanie'),
          )}
          {createInfoBlock(
            'place',
            tournament.sportGround.name,
            __('Primárne miesto konania'),
          )}
        </InfoBlocks>
      </Segment>
      <TournamentTeams
        createdTeam={createdTeam}
        teams={tournament.teams || []}
        isCreator={isCreator}
        isSubmitting={isSubmittingTeam}
        teamIdProcessing={teamIdProcessing}
        mobileLayout={mobileLayout}
        onTeamCreationSubmit={createTournamentTeam}
        selectedTeamId={selectedTeamId || null}
        setSelectedTeamId={changeTeamAssignment}
        toggleInvitationModal={toggleInvitationModal}
      />
      <TournamentEventsList
        events={events}
        total={tournamentEventsTotal}
        isFetching={tournamentEventsIsFetching}
        isCreator={isCreator}
      />
      {tournament.resultsTable &&
        tournament.resultsTable.results &&
        tournament.resultsTable.results.length > 0 && (
          <CompetitionPartTable
            teams={tournament.teams || []}
            resultsTable={tournament.resultsTable.results}
          />
        )}
      {isCreator && !mobileLayout && (
        <div style={{ textAlign: 'right' }}>
          <Button
            warning
            onClick={() => {
              push(`/admin/tournament/${tournament._id}/edit`);
            }}
          >
            {__('Upraviť turnaj')}
          </Button>
          {events.length === 0 && (
            <>
              &nbsp;
              <Button danger onClick={deleteTournament} loading={isDeleting}>
                {__('Zmazať turnaj')}
              </Button>
            </>
          )}
        </div>
      )}
      {isCreator && mobileLayout && (
        <div>
          <Button
            warning
            block
            onClick={() => {
              push(`/admin/tournament/${tournament._id}/edit`);
            }}
          >
            {__('Upraviť turnaj')}
          </Button>
          {events.length === 0 && (
            <>
              <div style={{ height: rem(10) }} />
              <Button
                danger
                block
                onClick={deleteTournament}
                loading={isDeleting}
              >
                {__('Zmazať turnaj')}
              </Button>
            </>
          )}
        </div>
      )}
      <Modal
        size="xs"
        isOpen={invitationModalOpened}
        handleClose={toggleInvitationModal}
      >
        <Segment loading={isInviting}>
          <ModalContent>
            <Header size="xs">{__('Pozvať hráča')}</Header>
            <InvitationForm
              form={INVITATION_FORM_NAME}
              onSubmit={onSubmitInvitationForm}
              teams={tournament.teams || []}
            />
            <FriendsList
              onInvite={i => {
                dispatch(change(INVITATION_FORM_NAME, 'email', i.email));
              }}
            />
          </ModalContent>
          <ModalActions>
            <div>&nbsp;</div>
            <div>
              <Button onClick={toggleInvitationModal}>{__('Zavrieť')}</Button>
              &nbsp;
              <Button
                primary
                onClick={() => {
                  dispatch(submit(INVITATION_FORM_NAME));
                }}
              >
                {__('Odoslať pozvánku')}
              </Button>
            </div>
          </ModalActions>
        </Segment>
      </Modal>
    </Content>
  );
};

export default compose<React.FC<OwnProps>>(
  withRouter,
  connect(),
)(TournamentInvitation);
