import { ACCESS_TOKEN_COOKIE_NAME } from '@sportnet/ui/lib/Authorization';
import Cookies from 'js-cookie';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { ExtraArgumentType, RootState } from '../../configureStore';
import { IAuthorizationResponse, IUser } from '../../library/App';
import { setAuthUser } from '../App/actions';

const create = actionCreatorFactory('AUTH_LOADER');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadAuthUser = createAsync<void, IAuthorizationResponse | null>(
  'GET_AUTH_USER',
  async (parameters, dispatch, getState, { CoreApi, HobbyApi }) => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME) || '';
    if (accessToken) {
      try {
        CoreApi.setToken(accessToken);
        const user = await CoreApi.me();
        HobbyApi.setToken(accessToken);
        const authResponse = {
          accessToken,
          user: { ...user, messages: 0 } as IUser,
        };
        dispatch(setAuthUser(authResponse));
        return authResponse;
      } catch (e) {
        Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
        CoreApi.setToken('');
        HobbyApi.setToken('');
      }
    }
    return null;
  },
);
