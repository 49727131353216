import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import * as React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import required from '../../utilities/required';
import __ from '../../utilities/__';

const RejectForm: React.FC<{}> = () => {
  return (
    <Row>
      <Col xs={12}>
        <Field
          component={FormField}
          name="note"
          label={__('Dôvod odmietnutia')}
          required
          validate={[required]}
        />
      </Col>
    </Row>
  );
};

export default compose(reduxForm({}))(RejectForm);
