import withQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getListTotal, isCommiting } from 'redux-list';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import AdminLayoutContext from '../../components/AdminLayout/context';
import config from '../../config';
import { RootState } from '../../configureStore';
import { initializeOrSetListParams } from '../../containers/App/actions';
import { authUserProfileSelector } from '../../containers/App/selectors';
import { loadTournamentEventsList } from '../../containers/Events/actions';
import { eventsListSelector } from '../../containers/Events/selectors';
import Loading from '../../containers/Loading';
import { loadTournamentById } from '../../containers/Tournaments/actions';
import { tournamentByIdSelector } from '../../containers/Tournaments/selectors';
import { ITheme } from '../../theme/theme';
import __ from '../../utilities/__';
import TournamentDetail from './detail';

const mapDispatchToProps = {
  loadTournament: thunkToAction(loadTournamentById.action),
  loadTournamentEvents: thunkToAction(loadTournamentEventsList),
  initializeList: thunkToAction(initializeOrSetListParams.action),
};

type Props = { theme: ITheme } & typeof mapDispatchToProps &
  RouteComponentProps<{ tournamentId: string }, {}>;

const mapStateToProps = (state: RootState, props: Props) => ({
  tournament: tournamentByIdSelector(props.params.tournamentId)(state),
  tournamentEvents: eventsListSelector(config.TOURNAMENT_EVENTS_LIST_NAME)(
    state,
  ),
  tournamentEventsTotal: getListTotal(config.TOURNAMENT_EVENTS_LIST_NAME)(
    state,
  ),
  tournamentEventsIsFetching: isCommiting(config.TOURNAMENT_EVENTS_LIST_NAME)(
    state,
  ),
  authUser: authUserProfileSelector(state),
});

const Tournament: React.FC<
  ReturnType<typeof mapStateToProps> & Props & QueryHocInterface
> = ({
  router: { push },
  authUser,
  params: { tournamentId },
  loadTournament,
  loadTournamentEvents,
  initializeList,
  tournament,
  query,
  tournamentEvents,
  tournamentEventsTotal,
  tournamentEventsIsFetching,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);

  useAsyncData(async () => {
    await initializeList({
      listName: config.TOURNAMENT_EVENTS_LIST_NAME,
      params: {
        offset: query.offset,
        tournamentId,
      },
    });
    await loadTournamentData();
  }, [tournamentId]);

  useAsyncData(async () => {
    await initializeList({
      listName: config.TOURNAMENT_EVENTS_LIST_NAME,
      params: {
        offset: query.offset,
        tournamentId,
      },
    });
    await loadTournamentEvents();
  }, [query]);

  const loadTournamentData = async () => {
    setIsFetching(true);
    try {
      await loadTournament({ tournamentId });
      await loadTournamentEvents();
    } catch (e) {
      if (authUser) {
        push('/admin/tournaments/me');
      } else {
        push('/');
      }
    } finally {
      setIsFetching(false);
    }
  };

  const { setHeaderComponents, headerComponents } = React.useContext(
    AdminLayoutContext,
  );
  const componentHeader = __('Turnaje');
  const componentSubHeader = __('Detail turnaja');

  if (
    headerComponents.header !== componentHeader ||
    headerComponents.subHeader !== componentSubHeader
  ) {
    setHeaderComponents(componentHeader, componentSubHeader);
  }

  if (!tournament || isFetching || !authUser) {
    return <Loading />;
  }

  const players = (tournament.teams || []).reduce(
    (acc, t) => [
      ...acc,
      ...(t.players || []).map(p => ({ ...p, teamId: t._id })),
    ],
    [],
  );
  const currentPlayerAssignment = players.find(p => p._id === authUser._id);
  const isCreator =
    tournament.createdBy && tournament.createdBy._id === authUser._id;

  if (tournament.public || currentPlayerAssignment || isCreator) {
    return (
      <>
        <TournamentDetail
          isCreator={!!isCreator}
          reloadTournament={loadTournamentData}
          tournament={tournament}
          events={tournamentEvents}
          tournamentEventsTotal={tournamentEventsTotal || 0}
          tournamentEventsIsFetching={!!tournamentEventsIsFetching}
          currentPlayerAssignment={currentPlayerAssignment}
        />
      </>
    );
  }
  return null;
};

export default compose(
  withRouter,
  withQueryHoc({
    parameters: {
      offset: {
        type: QueryHocTypes.Number,
        defaultValue: 0,
      },
    },
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Tournament);
