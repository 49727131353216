import Button from '@sportnet/ui/lib/Button';
import { format } from 'date-fns';
import connecToQueryHoc, { QueryHocInterface, QueryHocTypes } from 'query-hoc';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getListTotal, isCommiting } from 'redux-list';
import { useAsyncData } from 'ssr-service';
import { withTheme } from 'styled-components';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import BasicTable from '../../../components/BasicTable';
import NoItemsFound from '../../../components/NoItemsFound';
import config from '../../../config';
import { RootState } from '../../../configureStore';
import { initializeOrSetListParams } from '../../../containers/App/actions';
import { loadAdminEventsList } from '../../../containers/Events/actions';
import { eventsListSelector } from '../../../containers/Events/selectors';
import Loading from '../../../containers/Loading';
import { Event } from '../../../library/Event';
import { ITheme } from '../../../theme/theme';
import __ from '../../../utilities/__';
import { Actions, Dot, DotLabel } from '../styledComponents';

const mapDispatchToProps = {
  loadEvents: thunkToAction(loadAdminEventsList),
  initializeList: thunkToAction(initializeOrSetListParams.action),
};

const mapStateToProps = (state: RootState) => ({
  events: eventsListSelector(config.ADMIN_EVENTS_LIST_NAME)(state),
  isFetching: isCommiting(config.ADMIN_EVENTS_LIST_NAME)(state),
  total: getListTotal(config.ADMIN_EVENTS_LIST_NAME)(state),
});

type Props = typeof mapDispatchToProps &
  QueryHocInterface &
  ReturnType<typeof mapStateToProps> & {
    theme: ITheme;
  } & RouteComponentProps<{}, {}>;

const AdminEvents: React.FC<Props> = ({
  theme,
  loadEvents,
  initializeList,
  query,
  events,
  isFetching,
  total,
  setParameter,
  router: { push },
}) => {
  useAsyncData(async () => {
    await initializeList({
      listName: config.ADMIN_EVENTS_LIST_NAME,
      params: query,
    });
    await loadEvents();
  }, [query]);

  const getStatus = (i: Event) => {
    let label = '';
    let color = '';
    if (!i.closed) {
      label = __('Zatiaľ neodohrané');
      color = theme.color.fadedText;
    } else if (i.closed) {
      label = __('Odohrané');
      color = theme.color.success;
    }
    return (
      <DotLabel>
        <Dot color={color} />
        <div style={{ fontWeight: 200, color }}>{label}</div>
      </DotLabel>
    );
  };

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <>
          {events.length > 0 ? (
            <>
              <Actions>
                <Button
                  onClick={() => {
                    push('/admin/event');
                  }}
                  block={window.innerWidth <= 600}
                  primary
                >
                  {__('Vytvoriť nový zápas')}
                </Button>
              </Actions>
              <BasicTable
                renderRow={i => [
                  getStatus(i),
                  format(i.startDate, 'DD.MM.YYYY'),
                  i.sportGround.name,
                ]}
                colsCount={3}
                onClickRow={i => {
                  push(`/admin/event/${i._id}`);
                }}
                rowKey="_id"
                columns={[
                  { header: __('Stav') },
                  { header: __('Dátum') },
                  { header: __('Miesto') },
                ]}
                rows={events}
                withPaginator
                offset={Number(query.offset)}
                limit={config.DEFAULT_LIST_LIMIT}
                total={total || 0}
                onChangeOffset={newOffset => {
                  setParameter({ offset: newOffset });
                }}
              />
            </>
          ) : (
            <NoItemsFound
              label={__('Nenašli sa žiadne zápasy')}
              action={{
                label: __('Vytvoriť nový zápas'),
                onClick: () => {
                  push('/admin/event');
                },
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default compose(
  connecToQueryHoc({
    parameters: { offset: { type: QueryHocTypes.Number, defaultValue: 0 } },
  }),
  withTheme,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AdminEvents);
