import Button from '@sportnet/ui/lib/Button';
import Message from '@sportnet/ui/lib/Message';
import { isAfter } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import HobbyApi from '../../api/HobbyApi';
import AdminLayoutContext from '../../components/AdminLayout/context';
import EventHeader from '../../components/EventHeader';
import { Content } from '../../components/Layout';
import config from '../../config';
import { RootState } from '../../configureStore';
import { authUserProfileSelector } from '../../containers/App/selectors';
import { loadEventById } from '../../containers/Events/actions';
import { eventByIdSelector } from '../../containers/Events/selectors';
import Loading from '../../containers/Loading';
import { ITheme } from '../../theme/theme';
import useWindowSize from '../../utilities/resizeHook';
import __ from '../../utilities/__';
import Detail from './detail';

const mapDispatchToProps = {
  loadEvent: thunkToAction(loadEventById.action),
};

type Props = { theme: ITheme } & typeof mapDispatchToProps &
  RouteComponentProps<{ eventId: string }, {}>;

const mapStateToProps = (state: RootState, props: Props) => ({
  event: eventByIdSelector(props.params.eventId)(state),
  authUser: authUserProfileSelector(state),
});

const EventDetail: React.FC<ReturnType<typeof mapStateToProps> & Props> = ({
  router: { push },
  authUser,
  params: { eventId },
  loadEvent,
  event,
  theme,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [isJoining, setIsJoining] = React.useState(false);

  const resizeHook = useWindowSize();

  const [mobileLayout, setMobileLayout] = React.useState(
    window.innerWidth < 600,
  );

  React.useEffect(() => {
    if (resizeHook.innerWidth < 600) {
      setMobileLayout(true);
    } else {
      setMobileLayout(false);
    }
  }, [resizeHook.innerWidth]);

  useAsyncData(async () => {
    await loadEventData();
  }, [eventId]);

  const loadEventData = async () => {
    setIsFetching(true);
    try {
      await loadEvent({ eventId });
    } catch (e) {
      if (authUser) {
        push('/admin/events/me');
      } else {
        push('/');
      }
    } finally {
      setIsFetching(false);
    }
  };

  const [isDeleting, setIsDeleting] = React.useState(false);

  const deleteEvent = async () => {
    if (window.confirm(__('Skutočne si želáte zmazať zápas?'))) {
      setIsDeleting(true);
      try {
        await HobbyApi.deleteEvent(config.APP_SPACE, event._id);
        push(`/admin/events/admin`);
      } catch (e) {
        alert(__('Zápas sa nepodarilo odstrániť'));
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const joinPublicEvent = async () => {
    setIsJoining(true);
    try {
      await HobbyApi.joinPublicEvent(config.APP_SPACE, eventId);
      loadEventData();
    } catch (e) {
      if (e.details && e.details.name === 'EVENT_CAPACITY_REACHED') {
        alert(__('Kapacita zápasu bola naplnená'));
      } else {
        alert(__('Vyskytla sa neočakávaná chyba.'));
      }
    } finally {
      setIsJoining(false);
    }
  };

  const { setHeaderComponents, headerComponents } = React.useContext(
    AdminLayoutContext,
  );
  const componentHeader = __('Zápasy');
  const componentSubHeader = __('Detail zápasu');

  if (
    headerComponents.header !== componentHeader ||
    headerComponents.subHeader !== componentSubHeader
  ) {
    setHeaderComponents(componentHeader, componentSubHeader);
  }

  if (!event || isFetching || !authUser) {
    return <Loading />;
  }
  const isPlayer = (event.players || []).find(p => p._id === authUser._id);
  const isCreator = event.createdBy && event.createdBy._id === authUser._id;

  const freeCapacity = event.capacity - (event.players || []).length;
  const startDateIsDue = isAfter(new Date(), new Date(event.startDate));
  const registrationDateIsDue =
    event.registrationDueDate &&
    isAfter(new Date(), new Date(event.registrationDueDate));

  return (
    <Content>
      {!isPlayer && !freeCapacity && (
        <Message danger>
          {__('Ľutujeme, všetky voľné miesta sú už obsadené')}
        </Message>
      )}
      {!isPlayer &&
        freeCapacity > 0 &&
        (startDateIsDue || registrationDateIsDue) && (
          <Message danger>
            {__('Ľutujeme, prihlasovanie sa do zápasu už skončilo')}
          </Message>
        )}
      <EventHeader
        event={event}
        isDeleting={isDeleting}
        deleteEvent={deleteEvent}
        isPlayer={!!isPlayer}
        isCreator={!!isCreator}
      />
      {(isPlayer || isCreator) && (
        <Detail event={event} isCreator={!!isCreator} />
      )}
      {event.public && !isPlayer && (
        <div style={{ textAlign: 'right' }}>
          <Button
            loading={isJoining}
            onClick={joinPublicEvent}
            block={mobileLayout}
            primary
          >
            {__('Prihlásiť sa')}
          </Button>
        </div>
      )}
    </Content>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(EventDetail);
